body.factfinder-result-index {
    @include breakpoint(869px) {
        #left {
            position: fixed;
            top: 49px;
            bottom: 0;
            right: 0;
            display: block;
            overflow: scroll;
            z-index: 22;
            transform: translateX(300px);
            transition: 0.2s;

            & > .content {
                min-height: 100%;

                &:before {
                    content: 'Filter';
                    display: block;
                    height: 44px;
                    font-size: 20px;
                    text-align: center;
                    line-height: 44px;
                    white-space: nowrap;
                    font-weight: 400;
                    border-bottom: 1px solid #efefef;
                    border-top: 1px solid #efefef;
                    margin: 0 -24px;
                }
            }
        }

        #wrapper {
            &:after {
                position: absolute;
                left: 0;
                top: 0;
                bottom: 0;
                right: 0;
                background-color: rgba(0,0,0,.3);
                content: '';
                display: block;
                opacity: 0;
                visibility: hidden;
                transition: .2s;
                z-index: 21;
            }
        }

        &.show-filter {
            #left {
                transform: none;
            }

            #wrapper:after {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.ff-filter-button {
    border: 0;
    border-radius: 0;
    margin: 0;
    background: none;
    padding: 0;
    color: inherit;
    margin-left: auto;
    display: none;
    align-items: center;
    margin-right: 10px;

    @include breakpoint(869px) {
        display: flex;
    }

    svg {
        margin-right: 7px;
    }

    &:hover {
        color: $kastner-red;
    }
}
