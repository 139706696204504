/*!
 *  The Black Tie Font is commercial software. Please do not distribute.
 */
/* FONT PATH
 * -------------------------- */
/*@font-face {
  font-family: 'Black Tie';
  src: url('../fonts/solid/BlackTie-Solid-webfont.eot?v=1.0.0');
  src: url('../fonts/solid/BlackTie-Solid-webfont.eot?#iefix&v=1.0.0') format('embedded-opentype'), url('../fonts/solid/BlackTie-Solid-webfont.woff2?v=1.0.0') format('woff2'), url('../fonts/solid/BlackTie-Solid-webfont.woff?v=1.0.0') format('woff'), url('../fonts/solid/BlackTie-Solid-webfont.ttf?v=1.0.0') format('truetype'), url('../fonts/solid/BlackTie-Solid-webfont.svg?v=1.0.0#black_tiesolid') format('svg');
  font-weight: 900;
  font-style: normal;
}*/
/*@font-face {
  font-family: 'Black Tie';
  src: url('../fonts/bold/BlackTie-Bold-webfont.eot?v=1.0.0');
  src: url('../fonts/bold/BlackTie-Bold-webfont.eot?#iefix&v=1.0.0') format('embedded-opentype'), url('../fonts/bold/BlackTie-Bold-webfont.woff2?v=1.0.0') format('woff2'), url('../fonts/bold/BlackTie-Bold-webfont.woff?v=1.0.0') format('woff'), url('../fonts/bold/BlackTie-Bold-webfont.ttf?v=1.0.0') format('truetype'), url('../fonts/bold/BlackTie-Bold-webfont.svg?v=1.0.0#black_tiebold') format('svg');
  font-weight: 700;
  font-style: normal;
}*/
@font-face {
  font-family: 'Black Tie';
  src: url('../fonts/regular/BlackTie-Regular-webfont.eot?v=1.0.0');
  src: url('../fonts/regular/BlackTie-Regular-webfont.eot?#iefix&v=1.0.0') format('embedded-opentype'), url('../fonts/regular/BlackTie-Regular-webfont.woff2?v=1.0.0') format('woff2'), url('../fonts/regular/BlackTie-Regular-webfont.woff?v=1.0.0') format('woff'), url('../fonts/regular/BlackTie-Regular-webfont.ttf?v=1.0.0') format('truetype'), url('../fonts/regular/BlackTie-Regular-webfont.svg?v=1.0.0#black_tieregular') format('svg');
  font-weight: 400;
  font-style: normal;
}
/*@font-face {
  font-family: 'Black Tie';
  src: url('../fonts/light/BlackTie-Light-webfont.eot?v=1.0.0');
  src: url('../fonts/light/BlackTie-Light-webfont.eot?#iefix&v=1.0.0') format('embedded-opentype'), url('../fonts/light/BlackTie-Light-webfont.woff2?v=1.0.0') format('woff2'), url('../fonts/light/BlackTie-Light-webfont.woff?v=1.0.0') format('woff'), url('../fonts/light/BlackTie-Light-webfont.ttf?v=1.0.0') format('truetype'), url('../fonts/light/BlackTie-Light-webfont.svg?v=1.0.0#black_tielight') format('svg');
  font-weight: 200;
  font-style: normal;
}*/
@font-face {
  font-family: 'Font Awesome Brands';
  src: url('../fonts/brands/FontAwesomeBrands-Regular-webfont.eot?v=1.0.0');
  src: url('../fonts/brands/FontAwesomeBrands-Regular-webfont.eot?#iefix&v=1.0.0') format('embedded-opentype'), url('../fonts/brands/FontAwesomeBrands-Regular-webfont.woff2?v=1.0.0') format('woff2'), url('../fonts/brands/FontAwesomeBrands-Regular-webfont.woff?v=1.0.0') format('woff'), url('../fonts/brands/FontAwesomeBrands-Regular-webfont.ttf?v=1.0.0') format('truetype'), url('../fonts/brands/FontAwesomeBrands-Regular-webfont.svg?v=1.0.0#font_awesome_brandsregular') format('svg');
  font-weight: normal;
  font-style: normal;
}
.bts,
.btb,
.btr,
.btl,
.fab {
  display: inline-block;
  font: normal normal normal 14px/1 "Black Tie";
  font-size: inherit;
  vertical-align: -14.28571429%;
  text-rendering: auto;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  transform: translate(0, 0);
}
.bts {
  font-weight: 900;
}
.btb {
  font-weight: 700;
}
.btl {
  font-weight: 200;
}
.fab {
  font-family: "Font Awesome Brands";
}
/* makes the font 25% smaller relative to the icon container */
.bt-sm {
  font-size: .7em;
  vertical-align: baseline;
}
/* makes the font 33% larger relative to the icon container */
.bt-lg {
  font-size: 1.33333333em;
  line-height: 0.75em;
}
.bt-2x {
  font-size: 2em;
}
.bt-3x {
  font-size: 3em;
}
.bt-4x {
  font-size: 4em;
}
.bt-5x {
  font-size: 5em;
}
.bt-lg,
.bt-2x,
.bt-3x,
.bt-4x,
.bt-5x {
  vertical-align: -30%;
}
.bt-fw {
  width: 1.28571429em;
  text-align: center;
}
.bt-ul {
  padding-left: 0;
  margin-left: 2.14285714em;
  list-style-type: none;
}
.bt-ul > li {
  position: relative;
}
.bt-li {
  position: absolute;
  left: -2.14285714em;
  width: 2.14285714em;
  top: 0.14285714em;
  text-align: center;
}
.bt-li.bt-lg {
  left: -2em;
}
.bt-border {
  padding: .2em;
  border: solid 0.08em #eeeeee;
  border-radius: .1em;
}
.pull-right {
  float: right;
}
.pull-left {
  float: left;
}
.bts.pull-left,
.btb.pull-left,
.btr.pull-left,
.btl.pull-left,
.fab.pull-left {
  margin-right: .3em;
}
.bts.pull-right,
.btb.pull-right,
.btr.pull-right,
.btl.pull-right,
.fab.pull-right {
  margin-left: .3em;
}
.bt-spin {
  -webkit-animation: bt-spin 2s infinite linear;
  animation: bt-spin 2s infinite linear;
}
.bt-pulse {
  -webkit-animation: bt-spin 1s infinite steps(8);
  animation: bt-spin 1s infinite steps(8);
}
@-webkit-keyframes bt-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
@keyframes bt-spin {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(359deg);
    transform: rotate(359deg);
  }
}
.bt-rotate-90 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=1);
  -webkit-transform: rotate(90deg);
  -ms-transform: rotate(90deg);
  transform: rotate(90deg);
}
.bt-rotate-180 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2);
  -webkit-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  transform: rotate(180deg);
}
.bt-rotate-270 {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=3);
  -webkit-transform: rotate(270deg);
  -ms-transform: rotate(270deg);
  transform: rotate(270deg);
}
.bt-flip-horizontal {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=0, mirror=1);
  -webkit-transform: scale(-1, 1);
  -ms-transform: scale(-1, 1);
  transform: scale(-1, 1);
}
.bt-flip-vertical {
  filter: progid:DXImageTransform.Microsoft.BasicImage(rotation=2, mirror=1);
  -webkit-transform: scale(1, -1);
  -ms-transform: scale(1, -1);
  transform: scale(1, -1);
}
:root .bt-rotate-90,
:root .bt-rotate-180,
:root .bt-rotate-270,
:root .bt-flip-horizontal,
:root .bt-flip-vertical {
  filter: none;
}
.bt-stack {
  position: relative;
  display: inline-block;
  width: 1.28571429em;
  height: 1em;
  line-height: 1em;
  vertical-align: baseline;
}
.bt-stack-sm {
  position: absolute;
  top: 0;
  left: 0;
  line-height: inherit;
  font-size: .5em;
}
.bt-stack-1x,
.bt-stack-sm {
  display: inline-block;
  width: 100%;
  text-align: center;
}
.bt-inverse {
  color: #ffffff;
}
/* Black Tie uses the Unicode Private Use Area (PUA) to ensure screen
   readers do not read off random characters that represent icons */
.bt-bars:before {
  content: "\f000";
}
.bt-envelope:before {
  content: "\f001";
}
.bt-search:before {
  content: "\f002";
}
.bt-search-plus:before {
  content: "\f003";
}
.bt-search-minus:before {
  content: "\f004";
}
.bt-phone:before {
  content: "\f005";
}
.bt-comment:before {
  content: "\f007";
}
.bt-commenting:before {
  content: "\f008";
}
.bt-comments:before {
  content: "\f009";
}
.bt-rss:before {
  content: "\f00a";
}
.bt-times:before {
  content: "\f00c";
}
.bt-times-circle:before {
  content: "\f00d";
}
.bt-clock:before {
  content: "\f00e";
}
.bt-star:before {
  content: "\f010";
}
.bt-star-half:before {
  content: "\f011";
}
.bt-check:before {
  content: "\f012";
}
.bt-check-circle:before {
  content: "\f013";
}
.bt-check-square:before {
  content: "\f014";
}
.bt-th:before {
  content: "\f015";
}
.bt-th-large:before {
  content: "\f016";
}
.bt-heart:before {
  content: "\f017";
}
.bt-heart-half:before {
  content: "\f018";
}
.bt-calendar:before {
  content: "\f019";
}
.bt-shopping-cart:before {
  content: "\f01a";
}
.bt-plus:before {
  content: "\f01b";
}
.bt-plus-circle:before {
  content: "\f01c";
}
.bt-plus-square:before {
  content: "\f01d";
}
.bt-pen:before {
  content: "\f01e";
}
.bt-minus:before {
  content: "\f021";
}
.bt-minus-circle:before {
  content: "\f022";
}
.bt-minus-square:before {
  content: "\f023";
}
.bt-pencil:before {
  content: "\f024";
}
.bt-edit:before {
  content: "\f025";
}
.bt-thumbs-up:before {
  content: "\f026";
}
.bt-thumbs-down:before {
  content: "\f027";
}
.bt-gear:before {
  content: "\f028";
}
.bt-trash:before {
  content: "\f029";
}
.bt-file:before {
  content: "\f02a";
}
.bt-info-circle:before {
  content: "\f02b";
}
.bt-label:before {
  content: "\f02c";
}
.bt-rocket:before {
  content: "\f02d";
}
.bt-book:before {
  content: "\f02e";
}
.bt-book-open:before {
  content: "\f02f";
}
.bt-notebook:before {
  content: "\f030";
}
.bt-camera:before {
  content: "\f031";
}
.bt-folder:before {
  content: "\f032";
}
.bt-quote-left:before {
  content: "\f036";
}
.bt-quote-right:before {
  content: "\f037";
}
.bt-eye:before {
  content: "\f038";
}
.bt-lock:before {
  content: "\f039";
}
.bt-lock-open:before {
  content: "\f03a";
}
.bt-gift:before {
  content: "\f03b";
}
.bt-spinner-clock:before {
  content: "\f03c";
}
.bt-spinner:before {
  content: "\f03d";
}
.bt-wrench:before {
  content: "\f03e";
}
.bt-cloud:before {
  content: "\f040";
}
.bt-cloud-upload:before {
  content: "\f041";
}
.bt-cloud-download:before {
  content: "\f042";
}
.bt-sync:before {
  content: "\f043";
}
.bt-question-circle:before {
  content: "\f044";
}
.bt-share:before {
  content: "\f045";
}
.bt-briefcase:before {
  content: "\f046";
}
.bt-money:before {
  content: "\f047";
}
.bt-megaphone:before {
  content: "\f048";
}
.bt-sign-in:before {
  content: "\f049";
}
.bt-sign-out:before {
  content: "\f04a";
}
.bt-film:before {
  content: "\f04b";
}
.bt-trophy:before {
  content: "\f04c";
}
.bt-code:before {
  content: "\f04d";
}
.bt-light-bulb:before {
  content: "\f04e";
}
.bt-print:before {
  content: "\f050";
}
.bt-fax:before {
  content: "\f051";
}
.bt-video:before {
  content: "\f052";
}
.bt-signal:before {
  content: "\f053";
}
.bt-sitemap:before {
  content: "\f054";
}
.bt-upload:before {
  content: "\f055";
}
.bt-download:before {
  content: "\f056";
}
.bt-key:before {
  content: "\f057";
}
.bt-mug:before {
  content: "\f058";
}
.bt-bookmark:before {
  content: "\f059";
}
.bt-flag:before {
  content: "\f05a";
}
.bt-external-link:before {
  content: "\f05b";
}
.bt-smile:before {
  content: "\f05c";
}
.bt-frown:before {
  content: "\f05d";
}
.bt-meh:before {
  content: "\f05e";
}
.bt-magic:before {
  content: "\f060";
}
.bt-bolt:before {
  content: "\f061";
}
.bt-exclamation-triangle:before {
  content: "\f062";
}
.bt-exclamation-circle:before {
  content: "\f063";
}
.bt-flask:before {
  content: "\f064";
}
.bt-music:before {
  content: "\f065";
}
.bt-push-pin:before {
  content: "\f066";
}
.bt-shield:before {
  content: "\f067";
}
.bt-sort:before {
  content: "\f068";
}
.bt-reply:before {
  content: "\f069";
}
.bt-forward:before {
  content: "\f06a";
}
.bt-reply-all:before {
  content: "\f06b";
}
.bt-forward-all:before {
  content: "\f06c";
}
.bt-bell:before {
  content: "\f06d";
}
.bt-bell-off:before {
  content: "\f06e";
}
.bt-ban:before {
  content: "\f070";
}
.bt-database:before {
  content: "\f071";
}
.bt-hard-drive:before {
  content: "\f072";
}
.bt-merge:before {
  content: "\f073";
}
.bt-fork:before {
  content: "\f074";
}
.bt-wifi:before {
  content: "\f075";
}
.bt-paper-plane:before {
  content: "\f076";
}
.bt-inbox:before {
  content: "\f077";
}
.bt-fire:before {
  content: "\f078";
}
.bt-play:before {
  content: "\f079";
}
.bt-pause:before {
  content: "\f07a";
}
.bt-stop:before {
  content: "\f08b";
}
.bt-play-circle:before {
  content: "\f07b";
}
.bt-next:before {
  content: "\f07c";
}
.bt-previous:before {
  content: "\f07d";
}
.bt-repeat:before {
  content: "\f07e";
}
.bt-fast-forward:before {
  content: "\f080";
}
.bt-fast-reverse:before {
  content: "\f081";
}
.bt-volume:before {
  content: "\f082";
}
.bt-volume-off:before {
  content: "\f083";
}
.bt-volume-up:before {
  content: "\f084";
}
.bt-volume-down:before {
  content: "\f085";
}
.bt-maximize:before {
  content: "\f086";
}
.bt-minimize:before {
  content: "\f087";
}
.bt-closed-captions:before {
  content: "\f088";
}
.bt-shuffle:before {
  content: "\f089";
}
.bt-triangle:before {
  content: "\f08a";
}
.bt-square:before {
  content: "\f08b";
}
.bt-circle:before {
  content: "\f08c";
}
.bt-hexagon:before {
  content: "\f08d";
}
.bt-octagon:before {
  content: "\f08e";
}
.bt-angle-up:before {
  content: "\f090";
}
.bt-angle-down:before {
  content: "\f091";
}
.bt-angle-left:before {
  content: "\f092";
}
.bt-angle-right:before {
  content: "\f093";
}
.bt-angles-up:before {
  content: "\f094";
}
.bt-angles-down:before {
  content: "\f095";
}
.bt-angles-left:before {
  content: "\f096";
}
.bt-angles-right:before {
  content: "\f097";
}
.bt-arrow-up:before {
  content: "\f098";
}
.bt-arrow-down:before {
  content: "\f099";
}
.bt-arrow-left:before {
  content: "\f09a";
}
.bt-arrow-right:before {
  content: "\f09b";
}
.bt-bar-chart:before {
  content: "\f09c";
}
.bt-pie-chart:before {
  content: "\f09d";
}
.bt-circle-arrow-up:before {
  content: "\f0a0";
}
.bt-circle-arrow-down:before {
  content: "\f0a1";
}
.bt-circle-arrow-left:before {
  content: "\f0a2";
}
.bt-circle-arrow-right:before {
  content: "\f0a3";
}
.bt-caret-up:before {
  content: "\f0a4";
}
.bt-caret-down:before {
  content: "\f0a5";
}
.bt-caret-left:before {
  content: "\f0a6";
}
.bt-caret-right:before {
  content: "\f0a7";
}
.bt-long-arrow-up:before {
  content: "\f0a8";
}
.bt-long-arrow-down:before {
  content: "\f0a9";
}
.bt-long-arrow-left:before {
  content: "\f0aa";
}
.bt-long-arrow-right:before {
  content: "\f0ab";
}
.bt-Bold:before {
  content: "\f0ac";
}
.bt-italic:before {
  content: "\f0ad";
}
.bt-underline:before {
  content: "\f0ae";
}
.bt-link:before {
  content: "\f0b0";
}
.bt-paper-clip:before {
  content: "\f0b1";
}
.bt-align-left:before {
  content: "\f0b2";
}
.bt-align-center:before {
  content: "\f0b3";
}
.bt-align-right:before {
  content: "\f0b4";
}
.bt-align-justify:before {
  content: "\f0b5";
}
.bt-cut:before {
  content: "\f0b6";
}
.bt-copy:before {
  content: "\f0b7";
}
.bt-paste:before {
  content: "\f0b8";
}
.bt-photo:before {
  content: "\f0b9";
}
.bt-table:before {
  content: "\f0ba";
}
.bt-ulist:before {
  content: "\f0bb";
}
.bt-olist:before {
  content: "\f0bc";
}
.bt-indent:before {
  content: "\f0bd";
}
.bt-outdent:before {
  content: "\f0be";
}
.bt-undo:before {
  content: "\f0c0";
}
.bt-redo:before {
  content: "\f0c1";
}
.bt-sup:before {
  content: "\f0c2";
}
.bt-sub:before {
  content: "\f0c3";
}
.bt-text-size:before {
  content: "\f0c4";
}
.bt-text-color:before {
  content: "\f0c5";
}
.bt-remove-formatting:before {
  content: "\f0c6";
}
.bt-blockquote:before {
  content: "\f036";
}
.bt-globe:before {
  content: "\f0c7";
}
.bt-map:before {
  content: "\f0c8";
}
.bt-map-arrow:before {
  content: "\f0c9";
}
.bt-map-marker:before {
  content: "\f0ca";
}
.bt-map-pin:before {
  content: "\f0cb";
}
.bt-home:before {
  content: "\f0cc";
}
.bt-building:before {
  content: "\f0cd";
}
.bt-industry:before {
  content: "\f0ce";
}
.bt-desktop:before {
  content: "\f0d0";
}
.bt-laptop:before {
  content: "\f0d1";
}
.bt-tablet:before {
  content: "\f0d2";
}
.bt-mobile:before {
  content: "\f0d3";
}
.bt-tv:before {
  content: "\f0d4";
}
.bt-radio-checked:before {
  content: "\f0d5";
}
.bt-radio-unchecked:before {
  content: "\f08c";
}
.bt-checkbox-checked:before {
  content: "\f014";
}
.bt-checkbox-unchecked:before {
  content: "\f08b";
}
.bt-checkbox-intermediate:before {
  content: "\f023";
}
.bt-user:before {
  content: "\f0d6";
}
.bt-user-male:before {
  content: "\f0d6";
}
.bt-user-female:before {
  content: "\f0d7";
}
.bt-crown:before {
  content: "\f0d8";
}
.bt-credit-card:before {
  content: "\f0d9";
}
.bt-strikethrough:before {
  content: "\f0da";
}
.bt-eject:before {
  content: "\f0db";
}
.bt-ellipsis-h:before {
  content: "\f0dc";
}
.bt-ellipsis-v:before {
  content: "\f0dd";
}
.fab-facebook:before {
  content: "\f000";
}
.fab-facebook-alt:before {
  content: "\f001";
}
.fab-twitter:before {
  content: "\f002";
}
.fab-linkedin:before {
  content: "\f003";
}
.fab-linkedin-alt:before {
  content: "\f004";
}
.fab-instagram:before {
  content: "\f005";
}
.fab-github:before {
  content: "\f006";
}
.fab-github-alt:before {
  content: "\f007";
}
.fab-googleplus:before {
  content: "\f008";
}
.fab-googleplus-alt:before {
  content: "\f009";
}
.fab-pinterest:before {
  content: "\f00a";
}
.fab-pinterest-alt:before {
  content: "\f00b";
}
.fab-tumblr:before {
  content: "\f00c";
}
.fab-tumblr-alt:before {
  content: "\f00d";
}
.fab-bitcoin:before {
  content: "\f010";
}
.fab-bitcoin-alt:before {
  content: "\f011";
}
.fab-dropbox:before {
  content: "\f012";
}
.fab-stackexchange:before {
  content: "\f013";
}
.fab-stackoverflow:before {
  content: "\f014";
}
.fab-flickr:before {
  content: "\f015";
}
.fab-flickr-alt:before {
  content: "\f016";
}
.fab-bitbucket:before {
  content: "\f017";
}
.fab-html5:before {
  content: "\f018";
}
.fab-css3:before {
  content: "\f019";
}
.fab-apple:before {
  content: "\f01a";
}
.fab-windows:before {
  content: "\f01b";
}
.fab-android:before {
  content: "\f01c";
}
.fab-linux:before {
  content: "\f01d";
}
.fab-dribbble:before {
  content: "\f01e";
}
.fab-youtube:before {
  content: "\f021";
}
.fab-skype:before {
  content: "\f022";
}
.fab-foursquare:before {
  content: "\f023";
}
.fab-trello:before {
  content: "\f024";
}
.fab-maxcdn:before {
  content: "\f025";
}
.fab-gittip:before,
.fab-gratipay:before {
  content: "\f026";
}
.fab-vimeo:before {
  content: "\f027";
}
.fab-vimeo-alt:before {
  content: "\f028";
}
.fab-slack:before {
  content: "\f029";
}
.fab-wordpress:before {
  content: "\f02a";
}
.fab-wordpress-alt:before {
  content: "\f02b";
}
.fab-openid:before {
  content: "\f02c";
}
.fab-yahoo:before {
  content: "\f02d";
}
.fab-yahoo-alt:before {
  content: "\f02e";
}
.fab-reddit:before {
  content: "\f02f";
}
.fab-google:before {
  content: "\f030";
}
.fab-google-alt:before {
  content: "\f031";
}
.fab-stumbleupon:before {
  content: "\f032";
}
.fab-stumbleupon-alt:before {
  content: "\f033";
}
.fab-delicious:before {
  content: "\f034";
}
.fab-digg:before {
  content: "\f035";
}
.fab-piedpiper:before {
  content: "\f036";
}
.fab-piedpiper-alt:before {
  content: "\f037";
}
.fab-drupal:before {
  content: "\f038";
}
.fab-joomla:before {
  content: "\f039";
}
.fab-behance:before {
  content: "\f03a";
}
.fab-steam:before {
  content: "\f03b";
}
.fab-steam-alt:before {
  content: "\f03c";
}
.fab-spotify:before {
  content: "\f03d";
}
.fab-deviantart:before {
  content: "\f03e";
}
.fab-soundcloud:before {
  content: "\f040";
}
.fab-vine:before {
  content: "\f041";
}
.fab-codepen:before {
  content: "\f042";
}
.fab-jsfiddle:before {
  content: "\f043";
}
.fab-rebel:before {
  content: "\f044";
}
.fab-empire:before {
  content: "\f045";
}
.fab-git:before {
  content: "\f046";
}
.fab-hackernews:before {
  content: "\f047";
}
.fab-hackernews-alt:before {
  content: "\f048";
}
.fab-slideshare:before {
  content: "\f049";
}
.fab-twitch:before {
  content: "\f04a";
}
.fab-yelp:before {
  content: "\f04b";
}
.fab-paypal:before {
  content: "\f04c";
}
.fab-google-wallet:before {
  content: "\f04d";
}
.fab-angellist:before {
  content: "\f04e";
}
.fab-cc-visa:before {
  content: "\f050";
}
.fab-cc-mastercard:before {
  content: "\f051";
}
.fab-cc-discover:before {
  content: "\f052";
}
.fab-cc-amex:before {
  content: "\f053";
}
.fab-cc-paypal:before {
  content: "\f054";
}
.fab-cc-stripe:before {
  content: "\f055";
}
.fab-lastfm:before {
  content: "\f056";
}
.fab-whatsapp:before {
  content: "\f057";
}
.fab-medium:before {
  content: "\f058";
}
.fab-meanpath:before {
  content: "\f059";
}
.fab-meanpath-alt:before {
  content: "\f05a";
}
.fab-pagelines:before {
  content: "\f05b";
}
.fab-ioxhost:before {
  content: "\f060";
}
.fab-buysellads:before {
  content: "\f061";
}
.fab-buysellads-alt:before {
  content: "\f062";
}
.fab-connectdevelop:before {
  content: "\f063";
}
.fab-dashcube:before {
  content: "\f064";
}
.fab-forumbee:before {
  content: "\f065";
}
.fab-leanpub:before {
  content: "\f066";
}
.fab-sellsy:before {
  content: "\f067";
}
.fab-shirtsinbulk:before {
  content: "\f068";
}
.fab-simplybuilt:before {
  content: "\f069";
}
.fab-skyatlas:before {
  content: "\f06a";
}
.fab-viacoin:before {
  content: "\f06b";
}
.fab-codiepie:before {
  content: "\f06c";
}
.fab-queue:before {
  content: "\f06d";
}
.fab-queue-alt:before {
  content: "\f06e";
}
.fab-fonticons:before {
  content: "\f070";
}
.fab-fonticons-alt:before {
  content: "\f071";
}
.fab-blacktie:before {
  content: "\f072";
}
.fab-blacktie-alt:before {
  content: "\f073";
}
.fab-xing:before {
  content: "\f090";
}
.fab-vk:before {
  content: "\f091";
}
.fab-weibo:before {
  content: "\f092";
}
.fab-renren:before {
  content: "\f093";
}
.fab-tencent-weibo:before {
  content: "\f094";
}
.fab-qq:before {
  content: "\f095";
}
.fab-wechat:before,
.fab-weixin:before {
  content: "\f096";
}
