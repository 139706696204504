.offer-comment__container {
    display: none;
    position: relative;

    &.show {
        display: flex;
        align-items: center;
        justify-content: space-between;

        @include tablet() {
            display: block;
        }
    }
}

.offer-comment__label {
    font-size: 60px;
    color: #b1b1b1;
    font-weight: 400;
}

.offer-comment__textarea {
    background-color: white;
    border: 0;
    outline: 0;
    box-shadow: 0 1px 2px 0 rgba(0,0,0,0.15);
    padding: 18px 20px;
    flex-grow: 1;
    height: 136px;
    box-sizing: border-box;
    margin-right: 50px;
    margin-left: 16%;
    max-width: 830px;

    @include tablet() {
        width: 100%;
        margin-left: 0;
    }
}

.offer-comment__close {
    position: absolute;
    right: 0;
    top: 1px;
    color: black;
    font-size: 22px;
    cursor: pointer;
}
