[unresolved] {
    opacity: 0;
    visibility: hidden;
    pointer-events: none;
}

ff-record-list {
	overflow-anchor: none;
}

.ff-next-button-container {
    overflow: hidden;
    height: 0;
}

.ff-previous-button-container {
    display: flex;
    align-items: center;
}

#ff-previous-button {
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 1em;
    display: block;

    &[hidden] {
        display: none !important;
    }
}
