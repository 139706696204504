.messe-bonus-container {
    @include tablet() {
        display: none;
    }
}

.messe-bonus {
    color: $bright-red;
    width: 30px;
    height: 30px;
    position: relative;
    font-size: 0.8888em;
    overflow: visible;
    transition: 0.4s;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    &.hide {
        opacity: 0;
        visibility: hidden;
    }

    .messe-bonus__circle-background {
        position: absolute;
        left: 0; top: 0;
        width: 26px;
        height: 26px;
        border-radius: 26px;
        border: 2px solid #EAEAEA;
    }

    .messe-bonus__circle-canvas {
        position: absolute;
        left: 0; top: 0;
        transition: 0.4s;
        width: 30px;
        height: auto;
    }

    .messe-bonus__text {
        text-align: center;
        position: relative;
        top: -1px;
    }

    @include bp870() {
        display: none !important;
    }
}

.messe-bonus-overlay {
	font-size:1.05em;
	padding:0.6em 0.8em;
	padding-bottom:0.5em;
	overflow:hidden;
	position:absolute;
	margin:0;
	right:0;
	top:44px;
	background-color:white;
	box-shadow:$box-shadow;
	z-index:33;
	height:auto;
	border:1px solid #e8e8e8;
	box-sizing:border-box;
	min-width:283px;
    visibility: hidden;
    opacity: 0;
    transition: 0.2s;
    transform: translateX(50px);

    th {
        text-align: left;
    }

    .messe-bonus-container:hover &, &:hover {
        opacity: 1;
        visibility: visible;
        transform: none;
    }
}
