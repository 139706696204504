.widget {
	height: 35px;

	select, input {
		height: 35px;
		border: 0;
		outline: 0;
		padding-left: 0.55em;
		padding-right: 0.55em;
		padding-top: 0;
		padding-bottom: 0;
		width: 100%;
		display: block;
	}
}

.widget--text {
	border: 2px solid white;
	border-radius: 5px;	
	background-color: white;
	overflow: hidden;

	input {
		background-color: white;
	}
}

.widget--select {
	position: relative;
	border: 2px solid $medium-blue;
	border-radius: 5px;
	overflow: hidden;
	color: $medium-blue;

	select {
		padding-right: 44px;
	    -webkit-appearance: none;
	    -moz-appearance: none;
	    appearance: none;
	    text-indent: 0;
	    text-overflow: '';
	    padding-left: 10px;
	    background-color: transparent;
	    font-weight: bold;
	    font-size: 18px;
	    color: $medium-blue;
	}

	select::-ms-expand {
		display: none;
	}

	option {
		color: $font-color;
	}

	&:after {
		@include btr();
		width: 35px;
		height: 35px;
		content: '\F091';
		color: $medium-blue;
		display: block;
		line-height: 35px;
		text-align: center;
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		background-color: $background-gray;
		pointer-events: none;
		font-size: 21px;
	}
}
