ff-asn {
	width: auto;
}

.ff-asn {
	margin: 0;
}

ff-asn-group {
	padding: 0;
	margin: 1em 0;

	& + & {
		margin-top: 28px;
	}

	[data-container="groupCaption"] {
		display: block;
		color: inherit;
		user-select: none;
		font-weight: 500;
		border: 0;
		padding: 0;
		margin: 1em 0;
		margin-bottom: 0.75em;

		&:after, &:before {
			display: none;
		}

		&:before {
			@include btr();
			display: block;
			float:right;
			width: 1em;
			height: 1.4em;
			text-align: center;
			line-height: 1.4em;
			font-size: 0.8em;
			content: '\F091';
		}
	}

	&[opened] {

		[data-container="groupCaption"] {

			&:before {
				content: '\F090';
			}
		}
	}

	[for-group="Warengruppen"] {

		[data-container="groupCaption"] {
			display: none;
		}
	}

	[data-container="detailedLinks"],
	[data-container="hiddenLinks"] {
		padding-left: 0;
	}

	[data-container="showMore"],
	[data-container="showLess"] {
		font-weight: 500;

		span {
			padding: 0;
			display: block;
			cursor: pointer;

			&:after {
				@include btr();
				display: inline-block;
				font-size: 0.8em;
				width: 1em;
				height: 1em;
				text-align: center;
				line-height: 1em;
				content: '\00a0\F01B';
				margin-left: 0.5em;
			}
		}
	}

	[data-container="showLess"] span:after {
		content: '\F021';
	}

	[slot="filterSearch"] {
		input {
			border: 1px solid $gray-border;
			margin-bottom: 0.75em;
			font-size: 14px;
			padding: 0.2em;
			width: 100%;
			box-sizing: border-box;
		}
	}

	.ffw-query {
		color: black;
		background-color: yellow;
	}

	// Prime
	&[filter-style="DEFAULT"][for-group^="p"]:not([for-group="product_properties"]) {
		display: none;

		[data-container="groupCaption"] {
			.groupCaption {
				display: none;
			}

			&:after {
				display: block;
				content: 'Availability';

				html[lang="de"] & {
					content: 'Verfügbarkeit';
				}
			}
		}

		ff-asn-group-element:first-child .ffw-asn-unselected {
			display: none;
		}

		ff-asn-group-element:last-child .ffw-asn-unselected,
		ff-asn-group-element:last-child .ffw-asn-selected {
			display: block;

			[slot]:after {
				content: 'KASTNER Prime';
			}

			.filterName {
				display: none;
			}
		}
	}

	@for $i from 0 through 50 {
		&[filter-style="DEFAULT"][for-group="p#{$i}"] {
			body.kastner-store--#{$i}.enable-prime & {
				display: block;
			}
		}
	}
}

ff-asn-group-element [data-unselected],
ff-asn-group-element [data-selected],
ff-asn-group-element [slot="unselected"],
ff-asn-group-element [slot="selected"],
#left .category-nav a,
#left .block.filter ol a {
	display: flex;

	&:before {
		@include btr();
		display: block;
		width: 1.25em;
		height: 1.25em;
		line-height: 1.25em;
		text-align: center;
		margin-right: 7px;
		flex-shrink: 0;
		font-size: 0.8em;
	}
}

ff-asn-group-element [data-selected],
ff-asn-group-element [slot="selected"] {
	color: inherit;
	font-weight: inherit;
}

#left .category-nav a:before,
#left .block.filter ol a:before,
ff-asn-group-element [data-unselected]:before,
ff-asn-group-element [slot="unselected"]:before {
	content: '\F08B';
	color: #b4b4b4;
}

#left .category-nav a.active:before,
#left .category-nav a.has-active:before,
#left .block.filter .filter-current a:before,
ff-asn-group-element [data-selected]::before,
ff-asn-group-element [slot="selected"]::before,
#left .block.filter ol a.state--active:before {
	content: '\F014';
	color: inherit;
}

ff-asn-group[filter-style="TREE"] ff-asn-group-element {
	padding: 0;

	@for $i from 1 through 10
	{
		&.clusterLevel#{$i} {
			padding-left: $i * 0.5em;
		}
	}
}

ff-asn-group-element {
	margin-bottom: 8px;
}

[data-container="removeFilter"] {
	display: none;
}
