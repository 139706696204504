#header .top-section {
    @include nomargincollapse();
    min-width: 320px;
    height: $header-height;
    background-color:white;
    box-shadow:0 1px 4px 0 rgba(0,0,0,0.25);
    border-top: 5px solid $kastner-red;
    position: fixed;
    left:0; top:0; right:0;
    z-index: 22;
    color: black;

    body.group-nahundfrisch & {
        border-color: $nahfrisch-green;
    }

    .wishlist-button,
    .cart-button {
        font-size: 26px;
        color: #505050;
        width: 44px;
        height: $header-height;
        position: relative;
        line-height: $header-height;
        text-align: center;
        display: block;
    }

    .cart-button {
        &:before {
            @include sprite('../images/cart.svg');
            width: 26px;
            height: 26px;
            content: '';
            display: block;
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            right: 0;
            margin: auto;
        }
    }

    .wishlist-icon {
        position: relative;
        top: -2px;
    }

    .wishlist-count,
    .cart-count {
        display:block;
        background-color:$bright-red;
        border-radius:10px;
        height:20px;
        min-width:20px;
        line-height:20px;
        color:white;
        text-align:center;
        padding:0 6px;
        font-size:14px;
        font-size:0.7777rem;
        position:absolute;
        top:4px;
        right: 0px;
        box-sizing:border-box;
    }

    .wishlist-container {

        @include tablet()
        {
            display: none;
        }
    }

    .total-container {
        height: $header-height;
        position: relative;
        margin-left: 6px;
        padding-left: 12px;
        font-size: 20px;
        font-weight: 500;
        line-height: $header-height;
        margin-right: 8px;
        min-width: 100px;
        text-align: right;

        @include tablet()
        {
            display: none;
        }

        &:before {
            height: 20px;
            top:0;
            bottom:0;
            left:0;
            margin:auto;
            position: absolute;
            content: '';
            width: 1px;
            background-color: #505050;
            display: block;
        }
    }

    .user {
        display: block;
        width: 44px;
        height: $header-height;
        position: relative;

        &.user--logged-in {

            @include tablet()
            {
                display: none;
            }
        }

        &.user--has-outstanding-items {
            position: relative;

            &:after {
                display: block;
                background-color: #ff003c;
                border-radius: 10px;
                height: 20px;
                min-width: 20px;
                line-height: 20px;
                color: #fff;
                text-align: center;
                padding: 0 6px;
                font-size: 14px;
                font-size: .7777rem;
                position: absolute;
                top: 4px;
                right: 1px;
                box-sizing: border-box;
                content: '!';
            }
        }

        a.link-login {
            display: block;
            overflow: hidden;
            transition: opacity 0.2s, visibility 0.2s;

            span {
                visibility: hidden;
            }

            &:before {
                color: #dadada;
            }
        }

        a.link-login:before,
        .customer-menu:before {
            //@include btr();
            display: block;
            text-align: center;
            content: '';
            //content: "\f0d6";
            font-size: 26px;
            @include sprite('../images/login_icon_active.svg');
            width: 30px;
            height: 30px;
            left: 7px;
            top: 7px;
            position: relative;

            @include tablet() {
                width: 24px;
                height: 24px;
                left: 10px;
                top: 10px;
                background-size: 24px auto; 
            }
        }

        a.link-login:hover:before {
            background-image: url('../images/login_icon_red.svg');
        }

        .customer-menu {
            width: 44px;
            height: $header-height;
            position: relative;
            font-size: 17px;

            &:before {
                color: #4a4a4a;	
                cursor: pointer;
            }

            &:hover:before {
                background-image: url('../images/login_icon_red.svg');
            }

            body.enable-incognito & {
                &:before {
                    background-image: url('../images/incognito.svg');
                }

                &:hover:before {
                    background-image: url('../images/incognito_red.svg');
                }
            }

            ul {
                @include listreset();
            }

            & > ul {
                position: absolute;
                right: 0;
                top: $header-height;
                opacity: 0;
                visibility: hidden;
                transition: 0.2s;
                background-color: white;
                box-sizing:border-box;
                z-index: 16;
                white-space: nowrap;
                overflow: auto;
                max-height: calc(100vh - 60px);
                box-shadow: -1px 10px 13px 0 rgba(0,0,0,0.15);
                min-width: 310px;

                & > li {
                    padding: 6px 18px;

                    a {
                        color: #9f9f9f;

                        &:hover {
                            color: $kastner-red;
                        }
                    }

                    &.customer-name {
                        color: #414141;
                        padding-top: 14px;
                        padding-bottom: (14px - 6px);
                        text-transform: uppercase;
                    }

                    &.customer-id {
                        font-size: 14px;
                        padding-top: 0;
                        margin-top: -(14px - 6px);
                        color: #9f9f9f;
                    }

                    &.customer-logout {
                        border-top: 1px solid #dadada;
                    }

                    &.customer-multilogin {

                        & > span {
                            display: block;
                            padding-bottom: 6px;
                            cursor: pointer;
                            user-select: none;

                            & > .bt-angle-down {
                                transition: transform 0.2s;
                                margin-left: 5px;
                            }
                        }

                        & > ul {
                            @include listreset();
                            line-height:normal;
                            font-size:0.85em;

                            & > li {
                                overflow:hidden;

                                & > a {
                                    display:block;
                                    padding:0.4em 0;
                                }

                                &.current {

                                    & > a {
                                        cursor:default;
                                        pointer-events:none;
                                    }

                                    .name,
                                    .address,
                                    .num {
                                        color:$kastner-red;
                                    }
                                    
                                }

                                .cart-count-container {
                                    float:right;
                                    position:relative;
                                    padding-left:0.25em;
                                    width: 44px;
                                    height: 44px;

                                    &:before {
                                        @include sprite('../images/cart.svg');
                                        width: 26px;
                                        height: 26px;
                                        content: '';
                                        display: block;
                                        position: absolute;
                                        left: 0;
                                        right: 0;
                                        top: 0;
                                        bottom: 0;
                                        margin: auto;
                                    }

                                    &.count0 {
                                        display:none;
                                    }

                                    .cart-icon {
                                        font-size:2.0em;
                                        padding-top: 0.35em;
                                        padding-right: 0.3em;
                                    }
                                }
                            }


                            @include bp360()
                            {
                                top:auto;
                                width:auto;
                            }
                        }

                        &.show {

                            & > span > .bt-angle-down {
                                transform: rotateX(180deg);
                            }
                        }

                        .name {
                            color:$font-color;
                            //font-size:1.1em;
                            font-weight:700;
                        }

                        .name,
                        .address,
                        .num {
                            white-space:nowrap;
                            overflow:hidden;
                            text-overflow:ellipsis;
                        }

                        &.hover,
                        &:hover {

                            & > .btr {
                                background-color:rgba(0,0,0,0.5);
                            }

                            & > ul {
                                opacity:1;
                                visibility:visible;
                            }
                        }
                    }
                }
            }

            &:hover,
            &.show {

                & > ul {
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }
}

#header .top-section .user .customer-menu > ul > li.customer-incognito-mode {
    border-top: 1px solid #dadada;
    margin-top: 12px;
    padding-top: 12px;

    .widget {
        height: auto;
    }

    .widget--toggle {
        margin: 0;

        label {
            margin: 0;
            display: block;
            width: 100%;
            box-sizing: border-box;
            padding-left: 0;
            padding-right: 50px;

            &:before {
                left: auto;
                right: 6px;
            }

            &:after {
                left: auto;
                right: 18px;
            }
        }

        input:checked ~ label {
    
            &:after {
                background-color: #00afff;
                transform: translateX(18px);
            }
        }
    }
}

#header .top-section .user .customer-menu > ul > li.customer-incognito-mode + .customer-logout {
    margin-top: 6px;
}
