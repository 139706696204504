body.history-index-index h1.page-title {
    margin-bottom: 0;
}

.sales-history {
    @include loader();
    display: block;
    position: relative;
    transition: padding-top 0.2s;

    &:before,
    &:after {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: auto;
    }

    turbo-frame[busy] & {
        padding-top: calc(48px + 1em);
        pointer-events: none;

        &:before,
        &:after {
            opacity: 1;
            visibility: visible;
        }

        .orders {
            opacity: 0.33;
            pointer-events: none;
        }
    }

    ul.orders,
    ol.orders {
        @include listreset();

        & > .order {
            padding-bottom: 28px;
        }
    }

    ol.orders {
        transition: opacity 0.2s;
        margin-top: -6px;
    }

    .order {
        @include loader();

        &:before,
        &:after {
            width: 20px;
            height: 20px;
            left: -24px;
            top: 24px;
        }

        .order-year {
            font-size: 40px;
            font-weight: 500;
            color: #9f9f9f;
            text-align: right;
            margin-bottom: 24px;
            margin-right: 8px;
            margin-top: -8px;
        }

        .order-info {
            display: flex;
            justify-content: space-between;
            align-items: center;
            transition: 0.2s;
            height: 68px;
            background-color: white;
            box-shadow: $box-shadow;
            position: relative;
            z-index: 2;

            &:after {
                width: 4px;
                position: absolute;
                display: block;
                content: '';
                top: 0;
                right: 0;
                bottom: 0;
                //background-color: $bright-red;
            }

            @include breakpoint(360px)
            {
                & > div {
                    width:auto;
                    float:none;
                    text-align:left;
        
                    &.order-no {
                        padding-top:0;
                        padding-left:30px;
                    }
                }
            }
        }

        .order-reference {
            margin-right: auto;
            font-size: 25px;
            color: #585858;
            position: relative;
            border-left: 1px solid #a7a7a7;
            line-height: 45px;
            padding-left: 24px;
            font-weight: 500;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            padding-right: 10px;

            @include smartphone() {
                padding-left: 18px;
                margin-right: 0;
            }
        }

        &.status--verliefert .order-info:after {
            background-color: $orange;
        }

        &.status--fakturiert .order-info:after {
            background-color: var(--signal-green);
        }

        &.loading {

            &:before,
            &:after {
                opacity: 1;
                visibility: visible;
            }

            .order-info {
                opacity: 0.7;
            }
        }

        .order-date {
            font-size: 25px;
            color: $light-text;
            padding-left: 48px;
            position: relative;
            box-sizing: border-box;
            margin-left: 14px;
            padding-right: 24px;
            min-width: 220px;

            @include smartphone() {
                padding-left: 30px;
                padding-right: 18px;
                min-width: 180px;
            }

            .bt-times {
                cursor:pointer;
                position:absolute;
                font-size:18px;
                width:20px;
                height:20px;
                line-height:20px;
                text-align:left;
                position:absolute;
                margin:auto;
                left:0;
                bottom:-2px;
                top:2px;
                transition:0.2s;
                display:block;
            }
        }

        .order-no {
            text-align:right;
            vertical-align:bottom;
            color:$light-text;
            padding-top:0.75em;
        }

        .order-container {
            display: none;
            margin: 0 -30px;
            padding: 0 30px;
            background-color: #f5f5f5;
            box-shadow: 0 0 20px 0 rgba(0,0,0,0.11) inset;
            margin-top: -34px;
            padding-top: 34px;
            padding-bottom: 68px;
            transition: opacity 0.2s;

            &.show {
                display: block;
            }
        }

        .order-options {
            margin-top: 0;
            margin-bottom: 0;
            margin-left: auto;
            text-align: right;
            color: $bright-red;

            @include smartphone()
            {
                font-size: 0.9em;
                margin-top: 1em;
            }

            a {
                color: $bright-red;

                &:hover {
                    text-decoration: underline;
                }
            }

            ul {
                @include listreset();

                li {
                    display:inline-block;

                    & + li {
                        &:before {
                            content:" | ";
                        }
                    }
                }
            }
        }

        .order-deliverydate {
            font-size: rem(25px);
            line-height: (30/25);
            font-weight: 400;
        }

        .order-meta {
            display: flex;
            justify-content: space-between;
            align-items: center;
            padding: 30px 24px;
            padding-right: 0;
            flex-wrap: wrap;

            @include smartphone() {
                display: block;
            }
        }

        &.hide {
            
            .order-container {
                opacity: 0;
            }

            .order-date {

                .bt-times {
                    transform:rotate(45deg);
                }
            }
        }
    }
}



.order-items {
    @include listreset();
    margin-top: 0;

    & > li {
        margin-bottom:$list-margin;
    }

    figure.product-image {
        height:100%;
        position:relative;

        img {
            position:absolute;
            left:0; top:0; bottom:0;
            margin:auto;
            max-height: 72px;
        }
    }

    h2 {
        font-size:1em;
        font-weight:inherit;
        word-break:break-all;
        margin:0;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;

        a {
            text-decoration:none;
            color:inherit;
        }
    }
}
.sales-header,
.order-item .item-properties {
    @include listreset();
    @include clearfix();

    & > li {
        float:left;
        height:$list-height;
        line-height:$list-height;
        white-space:nowrap;
        overflow:hidden;
        text-overflow:ellipsis;
        box-sizing:border-box;
        padding-left:0.5em;
        padding-right:0.5em;

        &.thumbnail {
            width:9%;
            padding:0;
        }

        &.position {
            width:5%;
            overflow:visible;
        }

        &.title {
            width:35%;
        }

        &.sku {
            width:18%;
            text-align:center;
        }

        &.quantity {
            width:26%;
            text-align:center;
            overflow: visible;
        }

        &.reorder {
            width:7%;
            text-align:center;
        }
    }
}

.sales-header {
    background-color:white;
    margin-top:1.5em;
    margin-bottom:1.5em;

    & > li {
        height:auto;
        padding-top:4px;
        padding-bottom:4px;
        line-height:normal;

        & + li {
            border-left:5px solid $background-gray;
        }

        &.position {
            width:14%;
            text-align:right;
            border-left:0;
            overflow:hidden;
            text-overflow:ellipsis;
            white-space:nowrap;
        }

        &.title {
            text-align:center;
        }
    }
}

.order-item {
    background-color:white;
    box-shadow:$box-shadow;

    .item-properties {
        padding:4px;

        & > li {

            &.quantity {
                display: flex;
                justify-content: center;
/*
                & > div {
                    display:inline-block;
                    font-size:22px;
                    height:30px;
                    width:54px;
                    border:1px solid white;
                    text-align:center;
                    line-height:30px;
                    transition:0.2s;

                    &.selling-quantity {
                        margin-right:3px;
                    }

                    &.selling-unit {
                        background-color:#f4f4f4;
                        border-color:#f4f4f4;
                        color:#ababab;

                        &.no-detail {
                            width:109px;
                        }

                        &.checked {
                            background-color:#c8c8c8;
                            border-color:#c8c8c8;
                            color:white;
                        }
                    }
                }
*/
            }

            &.reorder {

                button {
                    background-color: transparent;
                    border: 0;
                    outline: 0;
                    padding: 0.5em;
                    color: $kastner-red;
                }
            }
        }
    }

    &.ordered {

        .item-properties {

            & > li {

                &.reorder {

                    button {
                        color:$gray-border;
                    }
                }

                &.quantity {

                    & > div {

                        &.selling-unit {

                            &.checked {
                                background-color:$light-red;
                                border-color:$light-red;
                            }
                        }
                    }
                }
            }
        }
    }
}


/**
 * Responsive
 */
@media only screen and (max-width:768px)
{
    .order-item {

        .item-properties {

            & > li {

                &.quantity {

                    .unitswitch .selling-unit {
                        display: none;
                    }

                    .unitswitch .selling-unit.no-detail,
                    .unitswitch .selling-unit.checked {
                        display: block;
                    }

                    .unitswitch .selling-unit.no-detail .selling-unit-placeholder, 
                    .unitswitch .selling-unit.no-detail > span, 
                    .unitswitch .selling-unit.no-detail label {
                        width: 34px;
                    }

                    .unitswitch .selling-unit .selling-unit-placeholder, 
                    .unitswitch .selling-unit > span, 
                    .unitswitch .selling-unit label {
                        width: 34px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width:640px)
{
    .sales-history {
        clear:both;
    }
}

@media only screen and (max-width:480px)
{
    .sales-header,
    .order-item .item-properties {

        & > li {

            &.thumbnail {
                width:11%;
            }

            &.position {
                display:none;
            }

            &.title {
                width:51%;
            }

            &.quantity {
                width:38%;

                .unitswitch--delivered-qty {
                    display: none;
                }
            }

            &.sku {
                display:none;
            }

            &.reorder {
                display:none;
            }
        }
    }

    .sales-header {

        & > li {

            &.position {
                width:11%;
                display:block;
                color:white;
            }
        }
    }
}


.sales-history__search-form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2em;
    align-items: center;
    gap: 8px;

    & > .widget--search {
        flex-grow: 1;
        max-width: 250px;
    }

    & > .widget--status {
        min-width: 200px;
    }

    & > .date-widget-container {
        display: flex;
        align-items: center;

        & > span {
            color: $medium-blue;
        }

        & > .widget--daterange,
        & > .widget--date {
            background-color: transparent;
            border-color: $medium-blue;
            color: $medium-blue;
    
            input {
                color: $medium-blue;
                font-weight: bold;
                font-size: 18px;
                background-color: transparent;
                width: 100px;
                text-align: center;
            }
        }
    }
}
