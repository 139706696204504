/**
 * resets
 */
.product-name {
	width: auto;
	position: static;
	overflow: visible;
	white-space: normal;

	//width: 300px;
	//position: relative;
	//text-overflow: ellipsis;
	//overflow: hidden;
	//white-space: nowrap;
}

ff-suggest .ffw-suggestContainerWrapper {
	position: static !important;
}

ff-suggest .ffw-suggestContainerWrapper.hideffSuggestContainerWrapper {
	position: static !important;
	background-color: transparent;
}

ff-suggest .ffw-blockLayout {
	flex-wrap: wrap !important;
}

ff-suggest {
	z-index: 9 !important;
}

ff-suggest {
	background-color: transparent;
	box-shadow: none;
}

.searchTermContainer,
.productsContainer {
	border: 0;
}

ff-suggest[layout="block"] section.searchTermContainer,
ff-suggest[layout="block"] section.productsContainer {
	width: auto;
	display: block !important;
}

.containerCaption {
	border: 0;
	background-color: transparent;
    font-weight: normal;
    font-size: inherit;
    color: inherit;
    padding: 0;
    margin-bottom: initial;
}

ff-suggest-item[type="productName"] {
    height: auto;
    padding: 0;
}

ff-suggest .ffw-suggestContainerWrapper {
	border-left:1px solid $border-color;
	border-right:1px solid $border-color;
	border-bottom:1px solid $border-color;
	background-color: white;

	&:after {
		display:block;
		content:"";
		position:absolute;
		left:0px;
		right:0px;
		top:-1px;
		height:1px;
		background-color:#e3e3e3;
	}

	@include smartphone()
	{
		border: 0;
	}
}

/**
 * custom
 */
ff-suggest {
	box-sizing: border-box;
	position: fixed !important;
	width: auto !important;
	left: 40px;
	top: 50px;
	right: 40px;
	max-width: 1200px;
	margin: auto;

	@include smartphone()
	{
		left: 0;
		right: 0;
		max-height: calc(100vh - 50px);
		overflow-y: auto;
	}

	.ffw-blockLayout {
		flex-wrap: wrap;
	}

	&[layout="block"] section.searchTermContainer {
		width:35%;
		background-color: $background-gray;

		@include smartphone()
		{
			width: 100%;
		}
	}

	&[layout="block"] section.productsContainer {
		width:65%;

		@include smartphone()
		{
			width: 100%;
		}
	}

	&[layout="block"] section {
		overflow: hidden;
	}

	.containerCaption {
		display:block;
		margin:0.5em;
		font-weight:300;
		font-size:1.3em;
	}

	ff-suggest-item {
		padding: 0;
		cursor:pointer;
		padding: 0.5em 1.1em;
		white-space:nowrap;
		overflow:hidden;
		text-overflow:ellipsis;
		position:relative;

		span.ffw-query {
			color: inherit;
			font-weight: 700;
		}

		&.ffw-highlight-suggest-item,
		&:hover {
			color: $kastner-red;
			text-decoration: none;
		}
	}

	[data-container="productName"] {

		.containerCaption {
			display: none;
		}

		ff-suggest-item[type="productName"] {
			.product-price,
			.product-availabilitytext {
				display: none;
			}

			a {
				color: inherit;
			}

			padding-top: 1px;
			padding-bottom: 1px;
			margin-top: 0.5em;
			margin-bottom: 0.5em;
			padding-left: 1.1em;
			padding-right: 1.1em;
			display: flex;
			align-items: center;

			figure.thumbnail {
				margin-right:0.5em;
				flex-shrink: 0;
				width: 50px;
				height: 50px;
				position: relative;

				img {
					position: absolute;
					max-width: 100%;
					max-height: 100%;
					width: auto;
					height: auto;
					left: 0; top: 0; bottom: 0; right: 0;
					margin: auto;
				}
			}

			.product-name {
				flex-grow: 2;
			}

			.addtocart,
			.addtolist {
				flex-shrink: 0;
				text-align:right;
				line-height:50px;
				color:#9A9A9A;
				width: 50px;
				transition: opacity 0.2s, color 0.2s;

				&:hover {
					opacity: 0.6;
				}

				&:hover,
				&.clicked {
					color: $kastner-red;
				}

				&.clicked:hover {
					opacity: 1;
				}

				.bt-angle-right {
					vertical-align:text-top;
					margin-right:10px;
				}

				.bt-shopping-cart,
				.bt-ulist {
					font-size:28px;
				}
			}

			.addtocart {
				body.add-mode--wishlist & {
					display: none;
				}
			}

			.addtolist {
				display: none;

				body.add-mode--wishlist & {
					display: block;
				}
			}
		}
	}
}


/**
 * old
 */
#search_autocomplete {

	ul {
		list-style-type:none;
		margin:0;
		padding:0;
	}

	ul.types {
		position:relative;
		overflow:hidden;
		@include clearfix();

		&:before {
			content:"";
			display:block;
			position:absolute;
			right:0; top:0;
			bottom:0;
			width:65%;
			background-color:white;
			background-color:rgba(255,255,255,0.92);
		}

		& > li {

			& > ul.entries {

				& > li {

					& > span.amount {
						color:$light-text;
						font-size:0.9em;

						&:before { content:"("; }
						&:after { content:")"; }
					}

					& > span.parent {
						color:$light-text;
						font-size:0.9em;

						&:before { content:"("; }
						&:after { content:")"; }
					}
				}
			}

			&.productName {
				clear:none;
				float:none;
				width:auto;
				border-top:0;
				margin-left:35%;

				figure.thumbnail {
					max-width:50px;
				}

				& > span.title {
					display:none;
				}

				& > ul.entries {
					margin:0.5em 0;
				}
			}
		}
	}
}

@media only screen and (max-width:1024px)
{
	#search_autocomplete {

		&:before {
			display:none;
		}

		ul.types {

			&:before {
				display:none;
			}

			& > li {
				float:none;
				width:auto;
				background-color:rgb(244,244,244);
				background-color:rgba(244,244,244,0.92);

				&.productName {
					margin-left:0;
					float:none;
					width:auto;
					background-color:white;
					background-color:rgba(255,255,255,0.92);
				}
			}
		}
	}	
}

@include bp870()
{
	#search_autocomplete {
		top:($mobile-header-height + 1px) !important;
	}
}
