.product-view {
	position: relative;

	.product-view-details {
		display: flex;
		flex-wrap: wrap-reverse;
		margin: 0 -1%;

		& > * {
			box-sizing: border-box;
			margin: 0 1%;
		}

		.detail-container {
			width: percentage(8/12) - 2%;
		}

		.image-container {
			width: percentage(4/12) - 2%;
		}

		@include breakpoint(768px)
		{
			margin: 0;

			.detail-container,
			.image-container {
				width: 100%;
				margin: 0;
			}
		}

		.unitswitch {
			padding-bottom: 7px;
			border-bottom:1px solid #e1e1e1;
			margin-bottom: 1em;
	
			form,
			&.unitswitch--preorder,
			&.unitswitch--display-only,
			&.unitswitch--add-mode  {
				justify-content: flex-end;
			}

			&__empty-button {
				opacity: 0;
				visibility: hidden;
				pointer-events: none;
			}
		}
	}

	figure.product-image {

		img {
			margin-left:auto;
			margin-right:auto;
		}
	}

	.image-container,
	.detail-container,
	.product-related,
	.product-upsell {
		background-color:white;
		box-shadow: $box-shadow;
	}

	.detail-container {
		box-sizing: border-box;
		padding: 1em 1.75em;
		position: relative;

		@include breakpoint(768px)
		{
			box-shadow: none;
			padding: 1em;
		}
	}

	.product-name {
		font-size: 2em;
		margin-bottom:0.35em;
		line-height:1;
		margin-top:0;
		font-weight:300;
		margin-bottom:0;
	}

	.product-sku {
		color:$light-text;
		font-size:0.8888em;
		margin-top:0.5em;
	}

	.image-container {
		position: relative;
	}

	.button-overlay {
		float:right;
		margin-left:1em;
	}

	.product-manufacturer {
		margin-top:1em;
		font-size:1.3em;
		font-weight: 500;
		line-height:1;
		color:$light-text;
		min-height: 1.15em;
	}

	p.back-link {

		a {
			color:$baby-blue;
			text-decoration:none;
			font-size:1em;
			font-weight:300;

			.btr {
				margin-right:0.4444em;
			}

			&:hover {
				color:$baby-blue;
				text-decoration:none;
			}	
		}
	}

	label[for="qty"] {
		min-width:0;
	}

	.add-to-box {
		margin:0.3em 0;
	}

	.add-to-wishlist {
		@include listreset();

		& > li {
			display:inline-block;

			& + li {
				&:before {
					content:" | ";
					color:$kastner-red;
				}
			}
		}
	}

	p.availability {
		margin:0;
		text-align:right;
		position:absolute;
		right: 0;
		top: 0;

		&.signal-1 {
			color: var(--signal-green);
		}

		&.signal-2 {
			color: var(--signal-orange);
		}

		&.signal-3 {
			color: var(--signal-red);
		}
	}

	.product-price {

		span.price {
			font-size:3em;
		}

		.regular-price {
			color: $red;

			.price {
				font-size:1em;
			}
		}
	}

	.product-img-box {
		position:relative;

		.prime-indicator {
			right: 0.5em;
			bottom: 0.5em;
		}
	}

	.product-view-details {

		.product-image-container {
			display: flex;
			justify-content: center;
			overflow: hidden;

			.product-image {
				overflow: visible !important;
				cursor: crosshair;
			}
		}
	}

	.zoomImg {
		pointer-events: none;
	}

	div.discount-percentage > span {
		top:0;
		left:0;
		margin-left:5px;
		margin-top:10px;
		font-size: 1.2em;
		z-index: 3;
	}

	.button-overlay {
		position:absolute;
		right:6px;
		top:6px;
		z-index:3;
		pointer-events: none;

		img {
			width: 100px;
		}
	}

	.tabs-menu {
		@include listreset();
		@include clearfix();
		border-bottom:1px solid #e1e1e1;

		li {
			float:left;
			margin-right: 2px;

			@include smartphone()
			{
				float: none;
			}

			a {
				display:block;
				color:inherit;
				font-weight: normal;
				font-size: 1.1461em;
				position:relative;
				padding:0 16px;
				line-height: 36px;
				height: 36px;

				@include smartphone()
				{
					padding: 0 8px;
					line-height: 34px;
					height: 34px;
				}
			}

			&.tab-current {

				a {
					color:red;

					&:after {
						content:"";
						position:absolute;
						bottom:0;
						left:0;
						right:0;
						height:2px;
						background-color:red;
					}
				}					
			}
		}
	}

	.description {
		margin: 1em 0;
	}

	.tab-content {
		position: relative;
	}

	span.product-property {
		color:$product-properties-blue;
		border:2px solid $product-properties-blue;
		border-radius:4px;
		height:24px;
		line-height:24px;
		padding:0 8px;
		display:inline-block;
	}

	ul.product-properties {
		@include listreset();
		display:flex;
		flex-wrap: wrap;
		margin: -0.25em;

		li {
			display:block;
			margin: 0.25em;
		}
	}

	.details__description {
		padding-top: rem(40px);
		margin-top: rem(42px);
		position: relative;

		h2 {
			margin-top: 0;
		}

		&:before {
			content: '';
			display: block;
			position: absolute;
			left: 0;
			top: -1px;
			height: 2px;
			width: rem(60px);
			background-color: #e6e6e6;
		}
	}

	.details__tables {
		display: flex;
		margin-top: rem(26px);
		position: relative;
		align-items: flex-start;

		@include breakpoint(1240px) {
			display: block;

			table.details__units {
				margin-bottom: 1em;
			}
		}

		table {
			border-spacing: 0;
			border-collapse: separate;
		}

		th {
			text-align: left;
			border-bottom: 2px solid #e6e6e6;
			white-space: nowrap;
		}

		td {
			border-bottom: 1px solid #e6e6e6;
		}

		tr:last-child {
			td {
				border-bottom: 0;
			}
		}

		tr:nth-child(2) {
			td {
				border-bottom: 1px solid #e6e6e6;
			}
		}

		table + table {
			tr:last-child td {
				border-bottom: 1px solid #e6e6e6;
			}
		}

		th, td {
			padding: 6px 30px 6px 6px;

			&:last-child {
				padding-right: 6px;
			}
		}

		table + table {
			th, td {
				&:first-child {
					padding-left: 64px;

					@include breakpoint(1240px) {
						padding-left: 6px;
					}
				}
			}
		}
	}

	table.details__stock {
		width: calc(100% - 2em);
		margin-top: rem(26px);
		margin-left: 1em;
		margin-right: 1em;
		border-spacing: 0;
		border-collapse: separate;

		th {
			text-align: left;
			border-bottom: 2px solid #e6e6e6;
			white-space: nowrap;

			[data-tippy-content] {
				pointer-events: none;
			}
		}

		td {
			border-bottom: 1px solid #e6e6e6;
		}

		.matrix-info {
			cursor: pointer;
		}

		tr:last-child {
			td {
				border-bottom: 0;
			}
		}

		th, td {
			padding: 6px 30px 6px 6px;

			&:last-child {
				padding-right: 12px;
			}
		}

		tbody {
			tr:first-child {
				td {
					padding-top: 12px;
				}
			}
		}

		@include tablet() {
			margin-left: 0;
			margin-right: 0;
			width: 100%;
			font-size: 0.8em;

			th {
				[data-tippy-content] {
					pointer-events: auto;
				}
			}

			th, td {
				padding-top: 4px;
				padding-bottom: 4px;
				padding-left: 4px;
				padding-right: 4px;

				&:last-child {
					padding-right: 4px;
				}
			}

			th span {

				&:after {
					content: '.';
				}

				span {
					display: none;
				}
			}

			tbody {
				tr:first-child {
					td {
						padding-top: 6px;
					}
				}
			}
		}
	}

	.comment-and-addto {
		margin-top: 1em;
		display: flex;
		justify-content: space-between;
		align-items: center;

		@include smartphone() {
			display: block;
		}
	}

	.item-comment {
		width: 370px;
		max-width: 37%;
		transition: 0.4s;

		&.hide {
			opacity:0;
			visibility:hidden;
		}

		@include smartphone()
		{
			position:static;
			max-width:100%;
			margin:0.75em 0;
		}
	}

	.product-related-header,
	.product-upsell-header {
		margin-bottom:0.2em;
	}
}


.block-related {
	position:relative;
	background-color:transparent;
	padding:0 20px;

	.swiper-button-prev,
	.swiper-button-next {
		background-image:none;
		position:absolute;
		width:20px;
		height:28px;
		position:absolute;
		top:0;
		bottom:0;
		margin:auto;
		display:block;
		line-height:28px;
		text-align:center;
		transition:0.2s;
		font-size:1.4em;
		cursor:pointer;
		color:$light-text;
	}

	.swiper-button-prev {
		left:0;
	}

	.swiper-button-next {
		right:0;
	}	
}


@include smartphone() {
	.product-view .product__tabcontent {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
		align-items: flex-end;

		& > * {
			width: 100%;
		}

		& > p.availability,
		& > .product-manufacturer {
			width: auto;
			margin-top: 24px;
			max-width: 50%;
		}

		& > p.availability {
			position: relative;
		}

		& > .product-manufacturer {
			order: -1;
		}
	}
}
