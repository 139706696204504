body.messe-products-iframe {
    overflow-y: visible;
    height: auto;

    #wrapper {
        min-height: 100% !important;
    }

    #main {
        max-width: 1920px;
        margin: 0 auto !important;
        padding: 2em !important;
    }

    &.page-layout-1column {
        #main,
        #container,
        #container .main-layout-center {
            margin: 0;
            padding: 0;
        }
    }

    .swiper-button-next {
        right: 20px;
    }

    .products[data-mode=grid] [data-product] {
        margin-bottom: 0;
    }

    .products-slider .products[data-mode="grid"] [data-product] {
        margin-left: 0;
        margin-right: 15px;
        flex-basis: auto;
    }

    .products-slider .products-slider .products [data-product], 
    .products-slider .products[data-mode="grid"] [data-product] {
        box-shadow: 0 1px 2px 0 rgba(0,0,0,.15);
        width: 205px;
    }

    .swiper-container {
        overflow: visible;
    }

    .product-image--link,
    .product-name a {
        pointer-events: none !important;
        display: block;
    }
}
