.unitswitch {
    $root: &;

    text-align:center;
    transition: opacity 0.2s;

    &[data-placeholder][data-invalid] {
        opacity: 0;
    }

    &[data-mage-init] {
        opacity: 0.18;
        pointer-events: none;
    }

    form,
    &.unitswitch--preorder,
    &.unitswitch--display-only,
    &.unitswitch--add-mode {
        display: flex;
        justify-content: center;
        align-items: center;

        @include print()
        {
            justify-content: flex-start;
        }
    }


    & > *,
    & > input.multi {
        font-size:22px;
    }

    label.multi,
    input.qty,
    button[type="submit"],
    input[type="radio"] {
        display:none;
    }

    input.multi,
    .selling-unit {
        display: block;
    }

    input.multi,
    .input-placeholder,
    .selling-quantity {
        height:30px;
        width:55px;
        padding:0;
        margin:0 3px 0 0;
        border: 1px solid #e0e0e0;
        border-radius: 0;

        @include print()
        {
            border: 0;
        }
    }

    .selling-quantity {
        border-color: white;
        line-height: 29px;
    }

    .selling-unit {
        position: relative;

        &:before {
            content: '';
            display: block;
            width: 64px;
            height: 64px;
            border-radius: 50%;
            overflow: hidden;
            background-color: rgba(255,88,88, 0.3);
            opacity: 0;
            transform: scale(0.5);
            transition: 0.9s;
            transition-delay: 0.1s;
            position: absolute;
            left: -64px; right: -64px; top: -64px; bottom: -64px;
            margin: auto;
        }

        &.mousedown {
            &:before {
                transform: scale(1);
                opacity: 1;
                background-color: rgba(255,88,88, 0.6);
            }
        }

        &.loading {
            &:before {
                transition: 0.6s;
                background-color: #01aeff;
                opacity: 0.7;
            }
        }

        &.mousedown-end {
            &:before {
                transition: 0.2s;
                transition-delay: 0s;
            }
        }

        label,
        .selling-unit-placeholder,
        & > span {
            display:block;
            min-width:0;
            width:54px;
            height:30px;
            line-height: 29px;
            text-align:center;
            border:0;
            padding:0;
            margin:0;
            color:#ababab;
            background-color:#f4f4f4;
            border:1px solid #f4f4f4;
            position: relative;
            user-select: none;

            @include print()
            {
                border: 0;
            }
        }

        label {
            cursor: pointer;

            @include print()
            {
                display: none;
            }
        }

        input[type="radio"]:checked {

            & ~ label {
                background-color:white;
                border-color:#e0e0e0;
                color:inherit;
            }
        }

        input[type="radio"][disabled] {

            & ~ label {
                cursor:default;
            }
        }

        &.no-detail {

            label,
            .selling-unit-placeholder,
            & > span {
                width:112px;
            }
        }

        &.checked > span {
            background-color:#c8c8c8;
            border-color:#c8c8c8;
            color:white;
        }

        label,
        input[type="radio"]:checked ~ label {
            position: relative;
            overflow: hidden;
            z-index: 2;
            transition: 0.3s;

            &:before {
                width: 122px;
                height: 122px;
                border-radius: 50%;
                transform: scale(0);
                visibility: hidden;
                background-color: $light-red;
                content: '';
                display: block;
                transition: 0.3s;
                top: -100%; left: -100%; right: -100%; bottom: -100%;
                margin: auto;
                box-sizing: border-box;
                position: absolute;
                z-index: -1;
                opacity: 0.5;

                [data-signal="1"] & {
                    background-color: var(--signal-green);
                }

                [data-signal="2"] & {
                    background-color: var(--signal-orange);
                }

                [data-signal="3"] & {
                    background-color: var(--signal-red);
                }

                body.add-mode--offer & {
                    background-color: $add-mode-green;
                }
            }

            &:hover {
                border-color: $light-red;
                color: white;

                [data-signal="1"] & {
                    border-color: var(--signal-green);
                }

                [data-signal="2"] & {
                    border-color: var(--signal-orange);
                }

                [data-signal="3"] & {
                    border-color: var(--signal-red);
                }

                body.add-mode--offer & {
                    border-color: $add-mode-green;
                }
            
                &:before {
                    transform: scale(1);
                    visibility: visible;
                    opacity: 1;
                }
            }
        }
    }

    &.active {

        .selling-unit {

            input[type="radio"]:checked {

                & ~ label {
                    background-color: $light-red;
                    border-color: $light-red;
                    color: white;

                    [data-signal="1"] & {
                        background-color: var(--signal-green);
                        border-color: var(--signal-green);
                    }
    
                    [data-signal="2"] & {
                        background-color: var(--signal-orange);
                        border-color: var(--signal-orange);
                    }
    
                    [data-signal="3"] & {
                        background-color: var(--signal-red);
                        border-color: var(--signal-red);
                    }

                    body.add-mode--offer & {
                        background-color: $add-mode-green;
                        border-color: $add-mode-green;
                    }

                    @include print() {
                        display: block;
                        background-color: transparent !important;
                        color: inherit;
                    }
                }
            }
        }
    }
/*
    .wishlist-add-button {
        overflow: hidden;
        box-sizing: border-box;
        border: 1px solid #E0E0E0;
        background-color: transparent;
        color: inherit;
        line-height: 25px;
        padding: 2px;
        text-align: center;
        display: block;
        cursor: pointer;
        width: 100%;
        transition: 0.1s;
        max-width: 170px;
        margin-left: auto;
        margin-right: auto;
        position: relative;
        z-index: 2;
    }
*/
    &.unitswitch--add-mode {
        & > form {
            display: none;
        }

        & + .unitswitch {
            display: none;
        }
    }

    &--delivered-qty {
        margin-left: 2%;

        .selling-quantity {
            text-align: right;
            margin-right: 8px;
        }

        .selling-unit,
        .selling-unit.checked,
        .selling-unit.no-detail {
            & > span,
            & > label {
                width: 56px;
                text-align: left;
                color: inherit;
                background-color: transparent;
                border: 0;
            }
        }
    }

    &--not-delivered-as-ordered {
        color: $kastner-red;
    }

    &--empty {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .wishlist-add-button,
    &__empty-button {
        overflow: hidden;
        display: block;
        width: 171px;
        height: 30px;
        line-height: 29px;
        text-align: center;
        border: 0;
        padding: 0;
        margin: 0;
        outline: 0;
        border-radius: 0;
        border: 1px solid #e0e0e0;
        background-color: transparent;
        position: relative;
        box-sizing: content-box;
        cursor: pointer;
        z-index: 2;
        color: inherit;
        transition: 0.2s;

        &:before {
            width: 180px;
            height: 180px;
            border-radius: 50%;
            transform: scale(0);
            visibility: hidden;
            background-color: transparent;
            content: '';
            display: block;
            transition: 0.3s;
            top: -100%; left: -100%; right: -100%; bottom: -100%;
            margin: auto;
            box-sizing: border-box;
            position: absolute;
            z-index: -1;
            opacity: 0.5;
        }

        &:hover {
            &:before {
                transform: scale(1);
                visibility: visible;
                opacity: 1;
            }
        }

        &--wishlist-replace {
            &:before {
                background-color: var(--purple);
            }

            &:hover {
                border-color: var(--purple);
                color: white;
            }
        }
    }

    &__empty-button {
        body.add-mode--replace & {
            opacity: 0;
            visibility: hidden;
        }
    }

    .wishlist-add-button {
        &:before {
            background-color: $add-mode-blue;

            body.add-mode--replace & {
                background-color: var(--purple);
            }
        }

        &:hover {
            border-color: $add-mode-blue;
            color: white;

            body.add-mode--replace & {
                border-color: var(--purple);
            }
        }

        &.button--added {
            background-color: $add-mode-blue;
            color: white;
            border-color: $add-mode-blue;

            body.add-mode--replace & {
                background-color: var(--purple);
                border-color: var(--purple);
            }
        }
    }
}
