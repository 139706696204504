.product-related .block-related .product-price {
	padding-top:0.7em;
	margin-top:0.7em;

	.unit-label {
		margin-top:0.7em;
	}
}


/**
 * Responsive
 */
@media only screen and (min-width:640px) and (max-width:1370px)
{
	body:not(.no-left):not(.no-right) {
		.products-list > li.item > ul.properties {
			> li.summary_and_price {
				width:auto;
				margin-right:20px;
				> div.discount-percentage > span {
					display:none;
				}
			}
			.product-link {
				width:60%;
			}
		}
	}
}

@media only screen and (max-width:1130px)
{
	body.has-left {

		.products-list > li.item > ul.properties > li.summary_and_price {
			> div.discount-percentage > span {
				display:none;
			}
		}
	}
}

@media only screen and (max-width:840px)
{
	body {

		.products-list > li.item > ul.properties > li.summary_and_price {
			> div.discount-percentage > span {
				display:none;
			}
		}
	}
}

@media only screen and (min-width:769px)  and (max-width:960px)
{
	body.has-left {

		.products-list > li.item > ul.properties > li {

			&.summary_and_price {
				width:auto;
				margin-right:15px;
			}
		}		
	}
}

@media only screen and (max-width:640px)
{
	.products-list > li.item > ul.properties {
		display:block;

		& > li {
			padding-top:0.95em;

			&.thumbnail {
				width:12%;

				figure {
					height:60px;
				}
			}

			&.name {
				width:58%;
			}

			&.summary_and_price {
				padding-top:0.95em;
				width:30%;

				.unit-label,
				.product-price .label {
					display:none;
				}
			}

			&.cart_buttons {
				width:100%;
				padding-top:0;
				padding-bottom:1em;

				&:before {
					display:none;
				}
			}
		}

		& > .product-link {
			width:auto;
		}
	}

	.products-list {

		.product-link {
			height:60px;
			bottom:auto;
			width:auto;
			right:0;
		}
	}
}
