button.lists__add-mode-button.button[data-add-mode="offer"] {
    background-color: $add-mode-green;
    border-color: $add-mode-green;

    &:hover {
        background-color: transparent;
        color: $add-mode-green;
    }
}

body.add-mode .lists__add-mode-button {
    display: none;
}
