#header {

	.ff-navigation, 
	.toolbar {
		position:relative;
		display: flex;
		justify-content:space-between;
		width: auto;

		ul.view-mode {
			@include listreset();
			@include clearfix();
			display: flex;

			li {
				& + li {

					&:before {
						color: $font-color;
						content:"\00a0|\00a0";
					}
				}
			}

			button {
				display: block;
				border: 0;
				background-color: transparent;
				padding: 0;
				color: inherit;
				position:relative;
				display:inline-block;
				height: 38px;

				&:before {
					content:"";
					display:block;
					position:absolute;
					left:0;
					top:-49px;
					bottom:-51px;
					margin:auto;
					background-repeat:no-repeat;
					background-size:100% auto;
				}

				&[data-value="grid"] {
					padding-left:20px;

					&:before {
						width:13px;
						height:13px;
						background-image:url('../images/grid-gray.svg');
					}
				}

				&[data-value="list"] {
					padding-left:24px;

					&:before {
						width:16px;
						height:13px;
						background-image:url('../images/list-gray.svg');
					}
				}
			}

			&[data-mode="grid"] button[data-value="grid"] {
				color: $kastner-red;
				&:before { background-image:url($active-grid-symbol); }
			}

			&[data-mode="list"] button[data-value="list"] {
				color: $kastner-red;
				&:before { background-image:url($active-list-symbol); }
			}
		}


		.sorter {
			display: flex;
			margin-left: auto;

			@include breakpoint(869px) {
				margin-left: 0;
			}

			select {
				text-align:right;
				width: auto;

				option {
					text-align:left;
				}
			}

			.styled-select {
				height: auto;
				width: auto;
			}

			.styled-select,
			ul.dir {
				display:block;
			}

			@include bp480()
			{
				.styled-select {
					width:1.4em;
				}

				ul.dir {
					margin-left:0;
				}
			}

			ul.dir {
				@include listreset();
				display: flex;
				margin-left: 0.9em;

				& > li {

					& > a,
					& > span,
					& > strong {
						display:block;
						position:relative;
						color: inherit;

						&:before {
							content:"";
							display:block;
							position:absolute;
							top: 0;
							bottom: 0;
							margin:auto;
							background-repeat:no-repeat;
							background-size:100% auto;
							width:8px;
							height:15px;
						}
					}

					&.active {

						& > a,
						& > span,
						& > strong {
							color: $kastner-red;
						}
					}

					&.asc {
						padding-right: 0.9em;
						position: relative;

						& > a,
						& > span,
						& > strong {
							padding-left:14px;

							&:before {
								left:0;
								background-image:url('../images/arrow-up-gray.svg');
							}
						}

						&:after {
							content: '|';
							position: absolute;
							right: 0;
							top: 0;
							bottom: 0;
							text-align: center;
							width: 0.9em;
							display: block;
						}

						&.active {

							& > a,
							& > span,
							& > strong {

								&:before {
									background-image:url($active-up-arrow);
								}
							}
						}
					}

					&.desc {

						& > a,
						& > span,
						& > strong {
							padding-right:14px;

							&:before {
								right:0;
								background-image:url('../images/arrow-down-gray.svg');
							}
						}

						&.active {

							& > a,
							& > span,
							& > strong {

								&:before {
									background-image:url($active-down-arrow);
								}
							}
						}
					}
				}
			}
		}
	}
}


ff-sortbox {
	color: inherit;
	padding-right: 28px;
	position: relative;

	&:after {
		@include btr();
		content: "\f091";
		position: absolute;
		right: 0;
		top: 0;
		bottom: 0;
		margin: auto;
		width: 24px;
		height: 24px;
		line-height: 26px;
		color: inherit;
		text-align: center;
		display: block;
		pointer-events: none;
		cursor: pointer;
	}

	.ffw-dropdown-container {
		padding: 0 1em;
		margin-left: -1em;
		box-shadow: 0 2px 15px 0 rgba(0,0,0,0.1);

		body.no-right {
			right: 0;
		}

		@include breakpoint(1171px)
		{
			right: 0;
		}
	}
}

.ff-sortbox {

	& > span {
		display: none;
	}

	@include breakpoint(560px)
	{
		margin: 0;
	}
}

.ff-navigation {

	@include breakpoint(560px)
	{
		flex-direction: row;
	}
}

.ff-sortbox {

	ff-sortbox-item {

		&, &.selected {
			padding: 0;
			border: 0;
			white-space: nowrap;

			&:hover {
				background-color: transparent;
				color: inherit;
			}
		}
	}

	ff-sortbox[opened] ff-sortbox-item.selected {
	    background-color: transparent;
		color: inherit;
	}
}
