#checkout-simple {
    .widget--toggle {
        margin-bottom: -1em;
        margin-top: 3em;
        height: auto;
        display: flex;
        justify-content: flex-end;
        z-index: 2;

        label {
            font-size: inherit;
            margin: 0;
            font-weight: inherit;
        }

        input {
            width: auto;
            height: auto;
        }
    }

    .order-summary {
        .shipping-costs-notice {
            margin-top: 0.1em;
            margin-bottom: 0;
            text-align: left;
            font-size: 0.666em;
            color: var(--light-text);
        }
    }
}
