body.enable-incognito {
    .product-price {
        visibility: hidden !important;
    }

    #header {
        .total-number,
        .shipping-costs-container {
            display: none !important;
        }
    }

    .cart {
        .properties > .price,
        .cart-price,
        .cart-table-footer {
            visibility: hidden !important;
        }
    }
}
