#header {

	.bottom-section {
		@include clearfix();
		height: 38px;
		line-height: 38px;

		.hide-column {
			text-align:center;
			cursor:pointer;
			color: #bfbfbf;

			.text--show {
				display: none;
			}

			body.no-left &[data-column="left"] {
				color: inherit;

				.text--hide {
					display: none;
				}

				.text--show {
					display: inline;
				}
			}

			body.no-right &[data-column="right"] {
				color: inherit;

				.text--hide {
					display: none;
				}

				.text--show {
					display: inline;
				}
			}
		}

		body.page-layout-1column & {
			display:none;
		}

		.main-layout-right {

			body.page-layout-2columns-left & {
				display:none;
			}
		}

		.main-layout-left {

			body.page-layout-2columns-right & {
				display:none;
			}
		}
	}
}
