/**
 * Fix for infinite scrolling but
 */
ff-record-list {
    position:relative;

    & .ffw-infinite-scrolling-border {
        bottom: 0
    }
}
