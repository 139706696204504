$background-gray:#f5f5f5;
$font-color:rgb(88,88,88);
$border-color:#d1d1d1;
$dark-gray:#707070;
$gray-border:#dadada;
$bright-red:#ff003c;
$light-green:#5bd721;
$middle-green:#9fdb23;
$flashy-green:#4de602;
$dark-green:#4fc517;
$add-mode-green: #43a047;
$orange:#ffc000;
$light-red:#ff5858;
$light-text:#a7a7a7;
$light-blue:#66d2ff;
$baby-blue:#2792f4;
$medium-blue:#01aeff;
$darker-blue:#2196f3;
$add-mode-blue: #1f96f2;
$medium-red:#fb0304;
$purple: #b400ff;

$signal-red: $bright-red;
$signal-orange: $orange;
$signal-green: #58d217;

$product-properties-blue:#3697f8;

$kastner-red:#d3021d;
$biogast-orange:#ee8300;//#de6e1d;
$nahfrisch-green: #98C000;
