
input::-ms-clear {
    display: none;
}

input[type="search"]::-webkit-search-decoration,
input[type="search"]::-webkit-search-cancel-button,
input[type="search"]::-webkit-search-results-button,
input[type="search"]::-webkit-search-results-decoration {
  -webkit-appearance:none;
}

/*
label {
	display:inline-block;
	width:22%;
	min-width:200px;
	overflow:hidden;
	text-overflow:ellipsis;
	white-space:nowrap;
	margin:0.25em 0;

	&.required {

		em {
			color:$bright-red;
			font-style:normal;
		}
	}
}
*/

[type="submit"],
form button {
	margin:0.25em 0;
}

label + input,
label + .input-box input {
	margin:0.25em 0;
	margin-left:3%;
}

label + input,
.input-box {
	display:inline-block;
}

.input-box {
	position:relative;
}

ul.form-list {
	@include listreset();
}

input {
	&[type="text"],
	&[type="email"],
	&[type="password"],
	&[type="search"] {
		border:1px solid #e0e0e0;
		padding:3px 5px;
	}
}

input[type="checkbox"],
input[type="radio"] {
	margin-left:0.2em;

	& + label {
		margin-left:0.3em;
	}
}

fieldset {
	background-color:white;
	border:0;
}

.validation-advice {
	position:absolute;
	margin-left:3%;
	font-size:0.7em;
	color:black;
}
