[type="button"].button, 
[type="reset"].button, 
[type="submit"].button, 
button.button,
a.button, 
.button {
	@include btn-base();
	@include btn-gray();

	&.small {
		@include btn-small();
	}

	&.green {
		@include btn-green();
	}

	&.blue {
		@include btn-blue();
	}

	&.red {
		@include btn-red();
	}


	&.gray-fill {
		@include btn-gray-fill();
	}

	&.green-fill {
		@include btn-green-fill();
	}

	&.blue-fill {
		@include btn-blue-fill();
	}

	&.red-fill {
		@include btn-red-fill();
	}


	&.left {
		@include btn-left();
	}

	&.right {
		@include btn-right();
	}

	&.down {
		@include btn-down();
	}

	&.plus,
	&.add {
		@include btn-plus();
	}

	&.check {
		@include btn-check();
	}

	&.remove,
	&.delete {
		@include btn-remove();
	}

	&.cart-plus {
		@include btn-cart-plus();
	}

	&.loading {
		@include btn-loading();
	}

	&.error {
		@include btn-error();
	}

	&[disabled] {
		opacity: 0.7;
		cursor: default;
	}

	&.hide {
		@include btn-hide();
	}
}
