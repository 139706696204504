.receipts-year {
    position: relative;
}

.receipts-year + .receipts-year {
    margin-top: 0;
}

.receipts-year ol.receipts-year__receipts {
    @include listreset();

    & + ol.receipts-year__receipts {
        margin-top: rem(66px);
    }

    & > li + li {
        margin-top: rem(26px);
    }
}

.receipts-year__year {
    color: #9f9f9f;
    font-size: 40px;
    text-align: right;
    margin-bottom: 20px;
    margin-top: 40px;
}

.receipts-year .receipts-year__year {
    font-size: 40px;
    font-weight: 500;
    color: #9f9f9f;
    text-align: right;
    margin-bottom: 24px;
    margin-right: 8px;
    margin-top: 24px;
}

.receipts-year:first-child .receipts-year__year {
    margin-top: -8px;
}

.sales-receipts {
    @include loader();
    display: block;
    position: relative;
    transition: padding-top 0.2s;

    &:before,
    &:after {
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: auto;
    }

    &__years {
        transition: opacity 0.2s;
    }

    turbo-frame[busy] & {
        padding-top: calc(48px + 1em);
        pointer-events: none;

        &:before,
        &:after {
            opacity: 1;
            visibility: visible;
        }

        &__years {
            opacity: 0.33;
            pointer-events: none;
        }
    }

    .load-more-container {
        margin: 2em 0;
    }
}

.sales-receipts__search-form {
    display: flex;
    flex-wrap: wrap;
    margin-top: 2em;
    align-items: center;
    gap: 8px;

    & > .widget--search {
        flex-grow: 1;
        max-width: 250px;
    }

    & > .widget--type {
        min-width: 200px;
    }

    & > .date-widget-container {
        display: flex;
        align-items: center;

        & > span {
            color: $medium-blue;
        }

        & > .widget--daterange,
        & > .widget--date {
            background-color: transparent;
            border-color: $medium-blue;
            color: $medium-blue;
    
            input {
                color: $medium-blue;
                font-weight: bold;
                font-size: 18px;
                background-color: transparent;
                width: 100px;
                text-align: center;
            }
        }
    }
}
