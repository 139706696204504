.swiper-container {
    width: 100%;
    box-sizing: border-box;
}

.swiper-button-prev,
.swiper-button-next,
.slick-prev,
.slick-next {
	background-image: none;
	background-color: transparent;
	position:absolute;
	width:20px;
	height:28px;
	position:absolute;
	top:0;
	bottom:0;
	margin:auto;
	display:block;
	line-height:28px;
	text-align:center;
	transition:0.2s;
	font-size:1.4em;
	cursor:pointer;
	color: $light-text;
	border: 0;
	outline: 0;
	padding: 0;
	white-space: nowrap;
	overflow: hidden;
	text-indent: 100px;

	&:before {
		@include btr();
		position: absolute;
		display: block;
		left: 0; top: 0; right: 0; bottom: 0;
		line-height: 28px;
		text-align: center;
		text-indent: 0;
		font-size: 28px;
	}
}

.swiper-button-prev,
.slick-prev {
	left: -20px;

	&:before {
		content: "\f092";
	}
}

.swiper-button-next,
.slick-next {
	right: -20px;

	&:before {
		content: "\f093";
	}
}

.swiper-button-disabled,
.slick-arrow.slick-disabled {
	opacity: 0.35;
}