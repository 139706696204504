#header {
	@include nomargincollapse();
	padding-top: $header-height + 5px;

	body.add-mode--wishlist &,
	body.add-mode--offer & {
		padding-top: $header-height + 5px + 31px;
	}
}

.header--top--content {
	display: flex;
	justify-content: space-between;
	min-height: $header-height + 1px;
}

.header--top--content__column {
	display: flex;
	position: relative;
}

.header--top--content__right {
	justify-content: flex-end;
	min-width: 488px;
	transition: opacity 0.2s, visibility 0.2s;
	align-items: center;

	@include breakpoint(1380px)
	{
		min-width: 280px;
	}

	@include tablet()
	{
		min-width: 0;

		body.show-search & {
			opacity: 0;
			visibility: hidden;
		}
	}
}

.header--top--content__left,
.header--top--content__right {
	flex-shrink: 0;
}

.header--top--content__left {

	@include tablet()
	{
		min-width: $header-height;
		position: static;
	}
}

.header--top--content__center {
	
	@include tablet()
	{
		flex-grow: 2;
		margin-left: -$header-height;
	}
}


#logo {
	margin-left: 18px;
	margin-top: 4px;
	transition: opacity 0.2s, visibility 0.2s;

	img {
		width:109px;
		height:auto;
	}

	@include breakpoint(1200px)
	{
		margin-left: 10px;
	}

	@include tablet()
	{
		margin-left: 0px;
		margin-top: 0;

		img {
			position: absolute;
			left: 0;
			right: 0;
			margin: auto;
			top: 0;
			bottom: 0;
			height: 28px;
			width: auto;
			z-index: 1;
		}

		body.show-search & {
			opacity: 0;
			visibility: hidden;
		}
	}
}

#topnav {
	margin-left: 36px;
	font-weight: 400;
	font-size: 15px;

	@include breakpoint(1200px)
	{
		margin-left: 0;
	}

	@include tablet()
	{
		display: none;
	}

	ul {
		@include listreset();
	}

	& > ul {
		display: flex;

		& > li {
			white-space: nowrap;

			& > a,
			& > span,
			& > strong {
				font-weight: inherit;
				color: inherit;
				display: block;
				padding: (22px - (38px/4)) (38px/4);
				line-height: (38px/2);
			}

			& > a:hover,
			& > strong,
			body.cms-index-index &:first-child > a,
			body.catalog-category-view &:first-child > a,
			body.factfinder-result-index &:first-child > a,
			body.catalog-product-view &:first-child > a {
				color: $kastner-red;
				position: relative;

				&:after {
					position: absolute;
					left: 0;
					right: 0;
					bottom: 0;
					height: 2px;
					background-color: $kastner-red;
					content: '';
					display: block;
				}
			}

			body.enable-incognito &:nth-child(2) {
				display: none;
			}
		}
	}
}

#mobilemainnavbutton {
	@include sprite('../images/burger.svg');
	display: none;
	width: 44px;
	height: $header-height;
	text-indent: 50px;
	white-space: nowrap;
	overflow: hidden;
	cursor: pointer;
	transition: opacity 0.2s, visibility 0.2s;
	position: relative;
	z-index: 2;

	@include tablet()
	{
		display: block;

		body.show-search & {
			opacity: 0;
			visibility: hidden;
		}
	}
}
