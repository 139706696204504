
ol#checkoutSteps {
	@include listreset();

	& > .section {
		display:none;

		&.allow,
		&.active {
			display:block;
		}
	}

	span.number {
		display:none;
	}

	label[for="billing-address-select"] + div.input-box {
		display:block;
	}

	.step-title > a {
		display:inline-block;
		background-color:$light-green;
		height:46px;
		line-height:46px;
		padding-left:16px;
		color:inherit;

		.fa, .btr {
			margin-left:16px;
			display:inline-block;
			width:46px;
			height:46px;
			line-height:46px;
			text-align:center;
			background-color:$dark-green;
			color:white;
		}
	}

	.step {
		margin:1em 0;
	}
}


a.print-order {
	display:inline-block;
	color:inherit;
	line-height:$button-height;
}


#checkout-step-shipping_method {

	dt {
		font-weight:700;
		font-family: 'Avenir Pro 85 Heavy';
	}

	.input-box {
		margin:1em 0;
	}
}

#payment-buttons-container {

	p.required {
		display:none;
	}
}


#checkout-simple {
	max-width:1250px;
	margin-left:auto;
	margin-right:auto;

	label, h1 {
		display:block;
		font-weight:300;
		font-size:1.8em;
		margin:0.35em 0;
		margin-top:2em;
		max-width:none;
		width:auto;
		min-height:0;
	}

	input[type="text"] {
		height:41px;
		border:0;
		padding:0 0.35em;
		margin:0;
		width:100%;
		box-sizing:border-box;
	}

	input[type="text"],
	select,
	textarea {
		font-size: 1.35em;
		font-weight: 300;
	}

	textarea {
		border:0;
		outline:0;
		width:100%;
		min-height:234px;
		padding:0.25em 0.35em;
		box-sizing:border-box;
	}

	.submit-container {
		text-align:right;

		button {
			display:inline-block;
		}
	}

	.button-container {
		margin-top:2.2em;
		margin-bottom:2.2em;
	}

	p.notice {
		color:$light-text;
		text-align:center;
		font-size:0.8em;
	}

	h1 {
		padding-bottom:0.2em;
		margin-bottom:0.8em;
		border-bottom:2px solid $gray-border;
	}

	.delivery-date {
		color:#02ADFF;
		font-weight:700;
		font-size:1.8em;
	}

	.order-summary {
		font-size:1.2em;

		.num {
			text-align:right;
		}

		.group {
			margin-top:0.5em;
			margin-bottom:0.5em;
		}

		.grand-total {
			margin-top:0;
			padding-top:0.5em;
			border-top:2px solid $gray-border;
			font-weight: 500;

			.notice {
				color:$light-text;
				font-size:0.8em;
				font-weight:300;
				text-align:right;
			}
		}
	}

	::-webkit-input-placeholder { font-size:0.55em; }
	::-moz-placeholder { font-size:0.55em; } /* firefox 19+ */
	:-ms-input-placeholder { font-size:0.55em; } /* ie */
	input:-moz-placeholder { font-size:0.55em; }
}

#checkout-offer-success, 
#checkout-success {
	max-width:1250px;
	margin-left:auto;
	margin-right:auto;
	font-size:1.1818em;
	padding-bottom:1em;

	.banner {
		margin:1em 0;
		text-align:center;
		background-color:white;
		border:2px solid $flashy-green;
		font-size:1.45em;
		color:$light-text;

		.check {
			margin:0.15em 0;
			margin-bottom:0em;
			font-size:3.6em;
			color:$flashy-green;
		}

		.thanks {
			margin-top:0;
			margin-bottom:0;
			font-size:1.25em;
			color:$flashy-green;
		}

		.info {
			margin-top:0.45em;
			margin-bottom:0.9em;
		}
	}

	.po-number {
		font-size:1.2em;
		margin-bottom:0.2em;
	}

	.email-notice {
		margin-top:0;
		color:$light-text;
	}


	.details {
		color:$light-text;

		h1 {
			font-size:1.45em;
			font-weight:300;
			color:$font-color;
			margin-bottom:0.05em;
			margin-top:1.5em;
		}

		p {
			line-height:1.5;
		}
	}

	.keep-shopping-container {
		text-align:right;
	}

	.button.keep-shopping {
		position:absolute;
		right:0;
		bottom:0;
		margin:0;
	}
}

#checkout-offer-success {
	.button.keep-shopping {
		position: inherit;
	}
}

@media only screen and (max-width:768px)
{
	#checkout-success {

		.button.keep-shopping {
			position:relative;
		}
	}
}