body.webshop-help-index {

	p {
		color:$light-text;
		text-align:center;
	}

	h1 {
		margin-bottom:0;
		text-align:center;

		& + p {
			margin-top:0.5em;
		}
	}

	.videos {
		margin-top:2em;
	}

	.video {
		font-size:0.8em;
		background-color:white;
		margin-bottom:1em;
		position:relative;
		padding-bottom:40px;
		box-sizing:border-box;

		h2 {
			color:$light-text;
			margin-top:0.6em;
			margin-bottom:0;

			& + p {
				margin-top:0.25em;
			}
		}

		p {
			text-align:left;
		}

		.text {
			padding:0 1.5em;
		}

		.play {
			display:block;
			border-top:1px solid #e0e0e0;
			padding:0.35em 0.5em;
			color:$light-text;
			font-size:1.35em;
			position:absolute;
			left:0; right:0;
			bottom:0;
			box-sizing:border-box;
			white-space:nowrap;
			overflow:hidden;
			text-overflow:ellipsis;

			.fa, .btr {
				display:inline-block;
				margin-right:0.15em;
			}
		}
	}

	.abbr-slider-container {
		position: relative;
	}

	.abbr {
		@include no-margin-collapse();
		margin-top:2em;
		margin-bottom:2em;
		margin-left:-200%;
		margin-right:-200%;
		background-color:white;

		& > .inside {
			margin-left:40%;
			margin-right:40%;
		}

		.slider {
			font-size:1.6em;
			color:$light-text;
			margin-left:1em;
			margin-right:1em;
			margin-top:1.7em;
			margin-bottom:1.6em;
			width: auto;
			position: static;

			& > ul {
				@include listreset();
			}

			.slick-prev,
			.swiper-button-prev {
				left: 0em;
			}

			.slick-next,
			.swiper-button-next {
				right: 0em;
			}

			@include tablet()
			{
				font-size: 1.2em;
			}

			@include smartphone()
			{
				font-size: 1em;
			}
		}

		dl {
			@include listreset();
			@include clearfix();
			margin-right:-5%;
			margin-top:2%;

			dt, dd {
				list-style:none;
				float:left;
				margin:0;
			}

			dt {
				width:6%;
				margin-bottom:2%;
				font-weight:700;
			}

			dd {
				width: 16%;
				margin-right: 3%;
				margin-bottom: 2%;
				white-space:nowrap;
				overflow:hidden;
				text-overflow:ellipsis;
			}

			@include smartphone()
			{
				dt {
					width: 13%;
				}

				dd {
					margin-right: 2%;
					width: 35%;
				}
			}
		}
	}
}
