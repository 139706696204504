$box-shadow:0 1px 2px 0 rgba(0,0,0,0.15);
$red:red;
$mobile-header-height:48px;
$list-height:72px;
$list-margin:0.65em;

$active-grid-symbol:'../images/grid-red.svg';
$active-list-symbol:'../images/list-red.svg';
$active-up-arrow:'../images/arrow-up-red.svg';
$active-down-arrow:'../images/arrow-down-red.svg';

//$active-grid-symbol:'../images/grid-green.svg';
//$active-list-symbol:'../images/list-green.svg';
//$active-up-arrow:'../images/arrow-up-green.svg';
//$active-down-arrow:'../images/arrow-down-green.svg';

/* holy grail */
$column-width:260px;
$outside-padding:7px;
$inside-padding:15px;

/* breakpoints */
$breakpoint-870: 870px;
$breakpoint-480: 480px;
$breakpoint-360: 360px;
