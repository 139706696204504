#container {
	overflow: hidden;
}

#wrapper {
	background-color:$background-gray;
	min-height:100%;
	overflow:hidden;
	min-width:320px;
	z-index: 2;

	@include print()
	{
		background-color: transparent;
	}
}


/* main layout definitions */
.main-layout-container {
	@include clearfix();
}

.main-layout-left {
	float:left;
	padding-left:$outside-padding;
	width:$column-width;
	transition: margin 0.4s;
}

body.no-left aside.main-layout-left {
	//@include transform( translateX(-($column-width + $outside-padding + $inside-padding)) );
	margin-left:-($column-width + $outside-padding + $inside-padding);
}

.main-layout-right {
	float:right;
	padding-right:$outside-padding;
	width:$column-width;
	transition: margin 0.4s;
}

body.no-right aside.main-layout-right {
	//@include transform( translateX($column-width + $outside-padding + $inside-padding) );
	margin-right:-($column-width + $outside-padding + $inside-padding);
}

.main-layout-center {
	margin:0 $outside-padding + $column-width + $inside-padding;
	transition: margin 0.4s;

	body.page-layout-1column #container & {
		margin-left:($inside-padding * 2);
		margin-right:($inside-padding * 2);
	}

	body.page-layout-2columns-left #container & {
		margin-right:($inside-padding * 2);
	}

	body.page-layout-2columns-right #container & {
		margin-left:($inside-padding * 2);
	}
}

body.no-left main.main-layout-center {
	margin-left:($inside-padding * 2);
}

body.no-right main.main-layout-center {
	margin-right:($inside-padding * 2);
}

#container.main-layout-center {
	@include no-margin-collapse();
}


/* left and right columns */
#left,
#right {

	& > .content {
		min-height: 336px;
		overflow: hidden;
		background-color: white;
		width: $column-width;
		box-sizing: border-box;
		box-shadow: $box-shadow;
		padding: 0 24px;
	}
}

#left {
	& > .content {
		position: relative;
	}
}

#right {
	& > .content {
		right: $outside-padding;
		position: fixed;
		top: 87px;
		z-index: 1;
		max-height: calc(100vh - 100px);
		overflow-y: auto;
		transition: right 0.4s;

		@include breakpoint(1040.8px, 'min') {
			max-height: calc(100vh - 150px);
		}
	}

	body.no-right & {
		& > .content {
			right: -($column-width + $outside-padding);
		}
	}
}

body.page-layout-1column #main {
	margin-top: 38px;
}



@include breakpoint(1171px)
{
	// remove right column
	#header .bottom-section .main-layout-right,
	#right {
	    display:none;
	}
	#main,
	#header .bottom-section .main-layout-center {
	    margin-right:($inside-padding * 2);
	}
}

@include breakpoint($breakpoint-870)
{
	// remove left column
	#header .bottom-section .main-layout-left,
	#left {
	    display:none;
	}
	#main,
	#header .bottom-section .main-layout-center {
	    margin-left:$inside-padding !important;
	    margin-right:$inside-padding !important;
	}
}
