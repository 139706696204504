@mixin clearfix()
{
	&:after {
		clear:both;
		content:"";
		display:block;
	}
}

@mixin transform($transform)
{
	-webkit-transform:$transform;
	        transform:$transform;
}

@mixin listreset() {
	list-style-type:none;
	margin:0;
	padding:0;

	& > li {
		margin:0;
		padding:0;
	}
}

@mixin font-awesome() {
	display: inline-block;
	font-family:'FontAwesome';
	font-style:normal;
	font-weight:normal;
	font-size:inherit;
	line-height:1;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin black-tie-regular() {
	display: inline-block;
	font: normal normal normal 14px/1 "Black Tie";
	font-size: inherit;
	vertical-align: -14.28571429%;
	text-rendering: auto;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
	transform: translate(0, 0);
}

@mixin btr()
{
	@include black-tie-regular();
}

@mixin no-margin-collapse()
{
	&:before,
	&:after {
		content:"";
		display:table;
	}
}

@mixin product-list-delete()
{
	position:relative;
	color:$bright-red;
	text-align:center;

	.button-delete {
		width:48px;
		height:48px;
		line-height:48px;
		text-align:center;
		display:inline-block;
		font-size:1.2em;
		cursor:pointer;
	}

	.confirm-remove {
		padding: 0;
		display:block;
		transition:0.4s;
		box-sizing:border-box;
		background-color:$bright-red;
		color:white;
		text-decoration:none;
		display:block;
		position:relative;
		position:absolute;
		width: 120px;
		right: -121px;
		top: 0;
		bottom: 0;
		display: flex;
		justify-content: center;
		align-items: center;
		text-align: center;
		border: 0;
		outline: 0;
		z-index: 2;

		&.show {
			right: 0;
		}

		span {
			display:block;
		}
	}
}

@mixin menublock()
{
	display: flex;
	justify-content: space-between;
	margin:8px 4px;

	& > *,
	& > li {
		width:100%;
		padding:0 4px;

		a {
			text-decoration:none;
			display:block;
			overflow:hidden;
			text-overflow:ellipsis;
			white-space:nowrap;
			border:1px solid transparent;
			font-size:0.75em;
			padding:1.1em;
			text-align:center;
			color:#464646;
			text-transform:uppercase;
			font-weight:normal;
			letter-spacing:0.04em;
			transition:0.2s;

			&:hover {
				border-color:#3e3e3e;
			}
		}
	}
}


/**
 * Loader GIF
 */
@mixin loader()
{
	position: relative;

	&:before,
	&:after {
		display:block;
		content:"";
		position:absolute;
		margin:auto;
		width:48px;
		height:48px;
		background-position:center;
		background-size:100% auto;
		background-size: contain;
		background-repeat:no-repeat;
		opacity:0;
		visibility:hidden;
		transition:0.4s;
		pointer-events: none;
	}

	&:before {
		background-image:url('../images/ring-alt-bg.png');
	}

	&:after {
		background-image:url('../images/ring-alt.gif');
	}
}


/**
 * Breakpoints
 */
 @mixin breakpoint($breakpoint, $mode: 'max')
 {
	@if $mode == 'max' {
		@media only screen and (max-width:$breakpoint) { @content; }
	} @else {
		@media only screen and (min-width:$breakpoint) { @content; }
	}
}

@mixin smartphone()
{
	@include breakpoint(768px)
	{
		@content;
	}
}

@mixin tablet()
{
	@include breakpoint(1040px)
	{
		@content;
	}
}

@mixin bp870()
{
	@include breakpoint($breakpoint-870)
	{
		@content;	
	}
}

@mixin bp480()
{
	@include breakpoint($breakpoint-480)
	{
		@content;	
	}
}

@mixin bp360()
{
	@include breakpoint($breakpoint-360)
	{
		@content;	
	}
}

@mixin nomargincollapse()
{
	&:before,
	&:after {
		content:"";
		display:table;
	}
}

@mixin sprite($image)
{
	@if $image
	{
		background-image: url($image);
	}

	background-size: 100% auto;
	background-size: contain;
	background-repeat: no-repeat;
	background-position: center;
}

@mixin multisprite($image, $width)
{
	@if $image
	{
		background-image: url($image);
	}

	background-size: $width auto;
	background-repeat: no-repeat;
	background-position: 0 0;
}

@mixin print()
{
	@media only print
	{
		@content;
	}
}

@mixin main-bleed()
{
	margin-left:-($inside-padding * 2);
	margin-right:-($inside-padding * 2);

	@include bp870()
	{
		margin-left: -15px;
		margin-right: -15px;
	}
}
