.block.filter {
	padding: 0;
	margin: 1em 0;
	background-color: transparent;

	ol {
		@include listreset();
		margin: 1em 0;

		a {
			color: inherit;
		}

		li {
			margin: 8px 0;
		}
	}

	span.count {
		display: none;
	}
}
