body.customer-account-login {
	.account-login,
	.page.messages .message {
		max-width: 890px;
		margin-left:auto;
		margin-right:auto;
	}

	.page.messages .message {
		box-sizing: border-box;
	}
}

.account-login {
	.login-container {
		text-align: center;
		max-width: 412px;
		margin-left: auto;
		margin-right: auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		gap: rem(24px);
		min-height: 85vh;

		@include smartphone() {
			min-height: 0;
		}
	}

	h2 {
		font-weight:300;
		font-size:1.8em;
	}

	.customer-login {
		padding: rem(48px) rem(36px);
		box-sizing: border-box;
		background-color:white;
		box-shadow: $box-shadow;

		h1 {
			font-weight: 300;
			font-size: 2em;
			margin-top: 0;
		}
	}

	.new-customers {
		padding: rem(32px) rem(36px);
		box-sizing: border-box;
		background-color:white;
		box-shadow: $box-shadow;

		h2 {
			margin: 0;
			font-size: inherit;
			font-weight: bold;
			font-size: 1.1em;
		}

		p {
			margin: 0.2em 0;
			font-size: 0.9em;
		}
	}

	#remember-me-box {
		.tooltip.wrapper {
			display: none;
		}
	}

	.forgot-container {
		text-align:right;

		a {
			display:inline-block;
			margin:0.25em 0;
		}
	}

	.new-customers {

		p {
			color:$light-text;
		}

		.new-customers-button-container {
			margin-top:2em;
		}
	}

	.input-box {
		display:block;
	}

	input[type="text"],
	input[type="password"],
	input[type="email"] {
		height:40px;
		border-radius:4px;
		border:2px solid $gray-border;
		padding:0 0.4em;
		width:100%;
		margin:0.5em 0;
		display:inline-block;
		font-size:1.1111em;
		box-sizing:border-box;
	}

	.customer-login {
		position:relative;

		.login-button-container {
			margin-top:2em;
			text-align: center;
		}
	}

	.group.buttons {
		margin-top:2em;
	}

	.login-button-container {
		text-align:right;

		[type="submit"] {
			margin:0;
		}
	}

	.remember-container {
		text-align: left;
	}

	.forgot-container {
		text-align:right;

		a {
			color: #0090FF;
		}
	}

	.login-links-wrapper {
		position: absolute;
		bottom: 20px;
		right: 20px;

		a {
			color: $font-color;
		}

		@include smartphone()
		{
			margin-top: 2em;
			position: static;
			text-align: center;
			margin-left: -2px;
			margin-right: -2px;
		}
	}
}
