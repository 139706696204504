.predictive-basket {
	position: fixed;
	left: 0;
	right: 0;
	bottom: -460px;
	height: 460px;
	transition: transform 0.4s, box-shadow 0.4s;
	background-color: white;
	z-index: 15;

	body.show-predictive-basket & {
		transform: translateY(-460px);
		box-shadow: 0 3px 25px 0 rgba(0,0,0,0.2);
	}

	ff-record-list {
		display: flex !important;
	}

	@include tablet()
	{
		bottom: -430px;
		height: 430px;

		body.show-predictive-basket & {
			transform: translateY(-430px);
		}
	}

	.products[data-mode="grid"] [data-product] {
		padding-top: 0;
		padding-bottom: 0;
	}

	.products-slider {

		&.swiper-container {
			padding-left: 5px + 45px;
			padding-right: 5px + 45px;
		}

		.swiper-button-next,
		.swiper-button-prev {
			width: 45px;
			height: 45px;
			background-color: white;
			box-shadow: 0 2px 7px 0 rgba(0,0,0,0.23);
			line-height: 45px;
			border-radius: 100%;
			overflow: hidden;
			font-size: 1.16em;
			color: #4b4b4b;

			&:before {
				font-size: inherit;
				line-height: inherit;
				position: relative;
				left: auto; right: auto; bottom: auto; top: auto;
				color: inherit;
			}
		}

		.swiper-button-next {
			right: 5px;

			&:before {
				left: 2px;
			}
		}

		.swiper-button-prev {
			left: 5px;

			&:before {
				left: -2px;
			}
		}

		@include breakpoint(350px) {
			.swiper-button-next,
			.swiper-button-prev {
				width: 30px;
				height: 30px;
				line-height: 30px;

				&:before {
					font-size: 18px;
				}
			}

			&.swiper-container {
				padding-left: 30px;
				padding-right: 30px;
			}
		}
	}
}

.predictive-basket__button {
	height: 52px;
	color: white;
	background-color: #00c4b4;
	position: absolute;
	left: 0;
	right: 0;
	margin: 0 auto;
	top: 25px;
	border-top-left-radius: 30px;
	border-top-right-radius: 30px;
	text-align: center;
	font-size: 25px;
	width: 321px;
	border: 0;
	outline: 0;
	padding: 0;
	cursor: pointer;
	z-index: 2;
	transition: box-shadow 0.4s, top 0.4s;
	outline: 0;

	&:focus {
		outline: 0;
	}

	.predictive-basket.show & {
		top: -52px;
		box-shadow: 0 -3px 25px 0 rgba(0,0,0,0.2);
	}

	&:after {
		@include btr();
		content: '\F090';
		display: inline-block;
		margin-left: 24px;
		transition: transform 0.2s;
		font-size: 22px;

		body.show-predictive-basket & {
			transform: rotateX(180deg);
		}
	}

	&:before {
		position: absolute;
		height: 22px;
		bottom: -22px;
		left: -22px; right: -22px;
		content: '';
		display: block;
		background-color: white; 
		z-index: 3;
	}

	.count {
		display: block;
		background-color:#ff003c;
		border-radius: 14px;
		height: 28px;
		min-width: 28px;
		line-height: 27px;
		color:#fff;
		text-align: center;
		font-size: 16px;
		box-sizing: border-box;
		position: absolute;
		top: -5px;
		right: 2px;
		margin: auto;
		width: 28px;
		z-index: 3;
	}

	@include tablet()
	{
		height: (52px * 0.8);
		padding-bottom: 6px;
		border-top-left-radius: (30px * (0.8 + 0.075));
		border-top-right-radius: (30px * (0.8 + 0.075));
		font-size: (25px * 0.8);
		width: 280px;

		&:after {
			margin-left: (24px * 0.8);
			font-size: (22px * 0.8);
		}

		.predictive-basket.show & {
			top: -(52px * 0.8);
		}
	}
}

.predictive-basket__content {
	z-index: 1;
	position: relative;
	overflow: hidden;

	& > h2 {
		text-align: center;
		font-size: 30px;
		color: #5f5f5f;
		margin: 20px;

		@include tablet()
		{
			font-size: 15px;
			white-space: nowrap;
			margin: 14px;
			margin-top: 22px;
			margin-bottom: 5px;
		}
	}
}
