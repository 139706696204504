#quickview {
	.product-image-container {
		position: relative;
	}

	.product-quickview {
		transition:0.2s;
	}

	&:before,
	&:after {
		display:block;
		content:"";
		position:absolute;
		left:0; right:0;
		top:144px;
		margin:auto;
		width:48px;
		height:48px;
		background-position:center;
		background-size:100% auto;
		background-repeat:no-repeat;
		opacity:0;
		visibility:hidden;
		transition:0.2s;
	}

	&:before {
		background-image:url('../images/ring-alt-bg.png');
	}

	&:after {
		background-image:url('../images/ring-alt.gif');
	}

	&.loading {

		.product-quickview {
			opacity:0.4;
		}

		&:before,
		&:after {
			opacity:1;
			visibility:visible;
		}
	}
}

#quickview .product-quickview {
	margin:1em 0;
	font-size: 0.75em;

	figure.product-image {

		img {
			margin-left:auto;
			margin-right:auto;
		}
	}

	figure.button-overlay {
		position:absolute;
		right:0.5em;
		top:0.5em;
		
		img {
			width: 100px;
			height: auto;
		}
	}

	.product-manufacturer {
		margin-bottom:0.1em;
		min-height: 1.15em;
	}

	.product-name {
		font-weight: 500;
		font-size:1.5em;
		color:#000;
		margin:0.5em 0;
		margin-top:0.25em;
		margin-bottom:0;
		line-height:1;
	}

	.product-sku {
		color:$light-text;
		font-size:1.33333em;
	}

	.product-availability {
		strong {
			font-weight: 500;
		}

		&.signal-1 {
			color: $middle-green;
		}

		&.signal-2 {
			color: $orange;
		}

		&.signal-3 {
			color: $bright-red;
		}
	}

	.slide--price {

		.product-price {
			color: $light-text;
			line-height: 1.333;
			font-size: 15px;

			.price-regular {

				span.price {
					font-size: 25px;
					line-height: 1.05;

					&:not(.discount-price) {
						color:#000;
					}
				}

				span.label {
					font-size: 15px;
				}
			}

			.unit-label {
				font-size: 15px;
			}

			.price-base {
				font-size: 15px;
				margin-top: 0;
			}

			.regular-price {
				color: $red;

				span.price {
					font-size: 15px;
				}
			}

			.price-info-label {
				display: none;
			}

			.price-tiers {
				font-size: 15px;
			}
		}

		.product-infos {
			text-align: center;
			margin-top: 1em;
			position: relative;
			overflow: hidden;
			font-size: 15px;

			& > * {
				margin: 1em 0;
			}

			&:before {
				position: absolute;
				top: 0;
				height: 1px;
				width: 50%;
				background-color: #b7b7b7;
				display: block;
				content: '';
				margin: auto;
				left: 0;
				right: 0;
			}

			.price-info {
				color: $bright-red;
			}

			.conventional-product {
				color: $bright-red;
			}
		}
	}

	.product-quickview__slider {
		margin-top:1em;

		h1, h2, h3, h4, h5, h6 {
			font-weight: 500;
			font-size:1em;
			display:none; 
		}
	}

	.swiper-container {
		overflow: hidden;
		position: relative;
	}

	.swiper-button-next {
		position: static;
		font-size: inherit;
		text-indent: 0;
		color: #0981f2;
		font-weight: 700;
		border: 0;
		background: none;
		padding: 0;
		width: auto;
		height: auto;
		line-height: normal;
		margin: 0;

		&:before {
			display: none;
		}

		&.slick-next {
			right: 0;
		}
	}

	.swiper-navigation {
		margin-top: 1.5em;
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.swiper-button-next {
		position: static;
	}

	.swiper-pagination {
		display: flex;
		position: static;
	}

	.swiper-pagination-bullet {
		display:block;
		margin: 0;
		margin-right: 8px;
		width:8px;
		height:8px;
		border-radius:5px;
		border:1px solid #b8b8b8;
		background-color:#cccccc;
		text-indent:100px;
		white-space:nowrap;
		overflow:hidden;
		cursor:pointer;
		transition:0.2s;
		padding:0;
	}

	.swiper-pagination-bullet-active {
		border-color:#636363;
		background-color:#636363;
	}

	.actions {
		@include listreset();
		margin-top:2em;
		color:$kastner-red;
		text-align:center;

		& > li {
			display:inline-block;
		}

		li + li {
			&:before {
				content:"|";
				display:inline-block;
				margin-left:0.2em;
				margin-right:0.1em;
			}
		}
	}

	.product-attribute-specs {
		overflow: hidden;

		span.product-property {
			color:$product-properties-blue;
			border:2px solid $product-properties-blue;
			border-radius:4px;
			height:24px;
			line-height:24px;
			padding:0 8px;
			display:inline-block;
		}

		ul.product-properties {
			@include listreset();

			li {
				display:inline-block;
				margin-bottom:0.5em;

				& + li {
					margin-right:0.5em;
				}
			}
		}

		table {

			th, td {
				padding:0.15em 0.25em;
				padding-left:0;
				text-align:left;
			}

			th {
				font-weight: 500;
				padding-right:1em;
			}

			td {
				font-weight: 300;
			}
		}
	}

	div.discount-percentage > span{
		top:0;
		left:0;
		margin-left:5px;
		margin-top:10px;
		font-size:1.35em;
	}
}


/**
 * Prevent initial click
 */
body.catalog-category-view #main > .category-products li[data-product][data-quickview],
#main > ff-record-list > ff-record {
	pointer-events: none;
}

body.catalog-category-view #main > .category-products li[data-product][data-quickview][data-quickview-init],
#main > ff-record-list > ff-record[data-quickview-init] {
	pointer-events: visible;
}
