.widget--toggle {
    margin: 6px 18px;
    position: relative;
    height: auto;

    input {
        opacity: 0;
        visibility: hidden;
        width: auto;
        height: auto;
        padding: 0;
        margin: auto;
        position: absolute;
        left: 0;
        top: 0;
        bottom: 0;
    }

    label {
        position: relative;
        padding-left: 50px;
        height: 20px;
        line-height: 19px;
        cursor: pointer;

        &:before,
        &:after {
            content: '';
            display: block;
            position: absolute;
            top: -10px;
            bottom: -10px;
            margin: auto;
            transition: 0.4s;
        }

        &:before {
            width: 26px;
            height: 14px;
            border-radius: 7px;
            overflow: hidden;
            background-color: #ededed;
            box-shadow: 0 0 4px 0 rgba(0,0,0,0.1) inset;
            left: 6px;
        }

        &:after {
            background-color: white;
            width: 20px;
            height: 20px;
            border-radius: 10px;
            overflow: hidden;
            box-shadow: 0 1px 4px 0 rgba(0,0,0,0.2);
            left: 0;
        }
    }

    input:checked ~ label {

        &:before {
            background-color: #cdecfb;
        }

        &:after {
            background-color: #00afff;
            transform: translateX(18px);
        }
    }
}
