.offer-options {
    max-width: 585px;

    .offer-options__widgets {
        display: flex;
        flex-wrap: wrap;
        margin: -6px -18px;
    }

    button {
        display: none;
    }

    .widget {
        min-width: 235px;
    }
}
