.page-top-menu {
    background-color: #fafafa;
    height: 38px;

    @include breakpoint(420px) {
        font-size: 16px;
    }

    ul {
        @include listreset();
        display: flex;
        justify-content: center;
    }

    li {
        margin: 0 18px;
    }

    li > a,
    li > strong {
        display: block;
        padding: 0 14px;
        height: 100%;
        line-height: 38px;
        font-weight: inherit;
        color: inherit;
        position: relative;

        @include breakpoint(420px) {
            padding: 0 5px;
        }

        & > span.count {
            display: block;
            background-color: #ff003c;
            border-radius: 10px;
            height: 20px;
            min-width: 20px;
            line-height: 20px;
            color: #fff;
            text-align: center;
            padding: 0 6px;
            font-size: 14px;
            font-size: .7777rem;
            position: absolute;
            top: 4px;
            right: -3px;
            box-sizing: border-box;
        }
    }

    li > a:hover,
    li > strong {
        position: relative;
        color: $kastner-red;

        &:after {
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 2px;
            background-color: $kastner-red;
            content: '';
            display: block;
        }
    }
}
