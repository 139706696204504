#left .category-nav {

	ul {
		@include listreset();

		li {
			padding-left: 0.5em;
			margin: 8px 0;
		}
	}

	& > ul {

		& > li {
			padding-left: 0;
		}
	}

	li.parent {

		& > ul {
			display: none;
		}

		&.has-active {

			& > ul > li {
				display: none;

				&.active,
				&.has-active {
					display: block;
				}
			}
		}

		&.active,
		&.has-active {

			& > ul {
				display: block;
			}
		}
	}

	a {
		color: inherit;
	}	
}
