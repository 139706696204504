.tingle-modal__close {
    width: 1rem;
    height: 1rem;
    top: 1rem;
    right: 1rem;
}

.tingle-modal {
    display: flex;
}

.tingle-modal-box {
    width: 650px;
    max-width: calc(100vw - 40px);
}

.tingle-modal-box__content {
    padding: 2rem;
}

.tingle-modal-box__footer {
    padding: 0.5rem 1.5rem;
    background-color: $background-gray;

    @include breakpoint(540px) {
        display: flex;
        flex-wrap: wrap;
        justify-content: center;
    }

    button {
        margin: 0.5rem;
    }
}

.tingle-btn {
    padding: 0.75rem 1rem;
}

.tingle-btn--primary {
    background-color: $medium-blue;
}

.tingle-btn--danger {
    background-color: $medium-red;
}
