.cart {
	margin-bottom: 60px;
}

#check-prices-form {
	display:inline;

	label {
		height:38px;
		display:inline-block;
		background-color:$medium-blue;
		padding:0 0.6em;
		width:auto;
		min-width:0;
		max-width:none;
		color:white;
		line-height:38px;
		margin:0 -0.25em 0 0.65em;
	}
}

.cart {
	font-weight:300;

	h1 {
		margin-bottom:0.15em;
	}

	.top-buttons {
		padding:2em 0 0.5em 0;
		display: flex;
		justify-content: flex-end;

		.widget--select {
			margin: 0 16px;
		}

		#check-prices-form {
			display: flex;

			button {
				margin: 0;
			}
		}

		@include breakpoint(640px)
		{
			display: block;

			.widget--select {
				margin-left: 0;
				margin-right: 0;
				margin-bottom: 0.5em;
			}

			#check-prices-form {
				display: block;
			}
		}
	}
}

.cart-table-container {
	margin:0;
	padding:0;
	border:0;
	outline:0;
}

.cart-table-header {
	@include listreset();
	@include clearfix();
	margin-bottom:1em;

	@include breakpoint(640px) {
		display: none;
	}
}

.cart-table-header > li,
.cart-table-quickadd > li,
.cart-table-items ul.properties > li {
	float:left;
	height:$list-height;
	line-height:$list-height;
	white-space:nowrap;
	overflow:hidden;
	text-overflow:ellipsis;
	box-sizing:border-box;
	padding-left:0.5em;
	padding-right:0.5em;

	&.thumbnail {
		width:7%;
		padding:0;
	}

	&.position {
		width:4%;
		overflow:visible;
	}

	&.title {
		width:25%;
	}

	&.sku {
		width:13%;
		text-align:center;
	}

	&.price {
		width:12%;
		text-align:right;
		overflow:visible;
		position:relative;
		padding-right:1em;

		.diff {
			line-height:normal;
			position:absolute;
			left:0; right:1.1em;
			bottom:0.25em;
			font-size:0.8888em;

			&.lower {
				color:$dark-green;
			}

			&.higher {
				color:$bright-red;
			}

			.btr {
				position:absolute;
				right:-16px;
				top:5px;
				font-size:12px;
			}
		}
	}

	&.quantity {
		width:19%;
		text-align:center;

		body.messe-cart-index & {
			height: auto;
		}

		@include print() {
			text-align: left;
		}
	}

	&.subtotal {
		width:15%;
		text-align:right;
		overflow:visible;
	}

	&.delete {
		width:5%;
		border-left:0;
		overflow:visible;
		line-height:$list-height;
		padding-right:0;
		text-align:center;

		@include print()
		{
			display: none;
		}
	}
}

.cart-table-header > li {
	height:auto;
	padding:0;
	text-align:center;
	line-height:normal;
	overflow:visible;

	& > span {
		padding:4px 0.25em;
		display:block;
		background-color:white;
		box-shadow:$box-shadow;
		overflow: hidden;
		text-overflow: ellipsis;	

		@include print()
		{
			box-shadow: none;
		}
	}

	&[data-sort] {
		cursor: pointer;

		& > span {
			user-select: none;
		}

		&.sort-ascending,
		&.sort-descending {
			& > span {
				position: relative;

				&:after {
					@include sprite('../images/chevron-up.svg');
					width: 24px;
					height: 24px;
					content: '';
					display: block;
					position: absolute;
					left: 3.5px;
					top: 0;
					bottom: 0;
					margin: auto;

					@include breakpoint(1280px) {
						width: 18px;
						height: 18px;
						left: 2px;
					}

					@include breakpoint(1140px) {
						display: none;
					}
				}

				@include breakpoint(1140px) {
					box-shadow: none;
					background-color: rgb(252,252,252);
				}
			}
		}

		&.sort-descending {
			& > span {
				&:after {
					background-image: url('../images/chevron-down.svg');
				}
			}
		}
	}

	&.price {
		padding-right:0;

		& > span {
			padding-right:1em;
		}
	}


	& + li {

		& > span {
			margin-left:5px;
		}
	}

	&.position {
		width:11%;
		text-align:right;
	}

	&.subtotal {
		width:20%;
		& > span { padding-right:26.5%; }
	}

	&.title,
	&.manufacturer {
		width: 12.5%;
	}
}

.cart-table-quickadd {
	@include listreset();
	@include clearfix();
	border:2px dashed $light-text;
	margin-top:6px;
	transition: opacity 0.2s;

	&.searching {
		opacity: 0.4;
	}

	.quickadd-title {
		display:inline-block;
		margin-left:0.5em;
		font-size:2em;
		font-weight:300;
		color:$light-text
	}

	.quickadd-sku {
		text-align:center;
		box-sizing:border-box;
		max-width:100%;
	}

	& > li {
		white-space:nowrap;
		min-height:1px;
		padding-top:0;
		padding-bottom:0;

		&.position {
			text-align:left;
			width:11%;
			padding-left:0;
		}

		&.title {
			text-align:left;
		}

		&.sku {
			padding:0;
		}

		&.price {
			padding-right:0.5em;
		}

		&.quantity {
			padding:0;
			overflow:visible;
		}

		&.delete {
			font-size:1.1111em;
		}
	}

	.button-add {
		border: 0;
		outline: 0;
		margin: 0;
		padding: 0;
		background-color: transparent;
		color:$flashy-green;
		width:48px;
		height:48px;
		line-height:48px;
		text-align:center;
		display:inline-block;
		font-size:1.2em;
		cursor:pointer;

		&[disabled] {
			opacity: 0.33;
			cursor: default;
		}
	}
}

.cart-table-items,
.cart-table-quickadd {

	.unitswitch {
		body.messe-cart-index & {
			& + .unitswitch {
				margin-top: 5px;
			}
		}

		@include print() {
			display: block !important;
		}

		&, form {
			height: 100%;

			body.messe-cart-index & {
				height: auto;
			}
		}
	}
}

.cart-table-items {
	@include listreset();

	ul.properties > li {
		&.price {
			line-height: normal;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: flex-end;	
		}
	}

	.bt-spinner {
		margin-left:0.5em;
	}

	.prime-indicator {
		transform-origin: 100% 100%;
		transform: scale(0.6);
		bottom: 0.1em;
	}

	& > li.item {
		margin-top:$list-margin;
		position:relative;
		
		& > .open-close {
			width:32px;
			height:32px;
			position:absolute;
			top:40px;
			margin-top: -16px;
			left:-31px;
			display:block;
			line-height:32px;
			text-align:center;
			transition:0.2s;
			font-size:1em;
			cursor:pointer;
			color:$light-text;

			@include print()
			{
				display: none;
			}
		}

		&.open {
			& > .open-close {
				@include transform( rotate(45deg) );
			}
		}

		.row-container {
			background-color: white;
			padding: 4px;
			box-shadow: $box-shadow;
			padding-left: 8px;
			position: relative;

			&:before {
				content: '';
				display: block;
				position: absolute;
				width: 4px;
				top: 0;
				left: 0;
				bottom: 0;
			}

			&.signal-2:before {
				background-color: $orange;
			}
	
			&.signal-3:before {
				background-color: $bright-red;
			}

			@include print()
			{
				box-shadow: none;
				padding: 0;
				padding-left: 6px;
			}
		}

		& > .additional {
			max-height:0;
			box-sizing:border-box;
			transition:0.2s;
			overflow:hidden;

			@include print()
			{
				display: none;
			}

			& > .inner {
				margin:1em;
			}

			textarea {
				height:3.9em;
				width:26em;
				border:0;
				outline:0;
				padding:0.22em 0.30em;
				font-size:1.15em;
			}

			input {
				width:385px;
				max-width:100%;
			}

			.add-container {
				line-height:1.725;
				text-align:right;
			}
		}	

		&.open {
			& > .additional {
				max-height:3.5em;
			}
		}
	}

	ul.properties {
		@include listreset();
		@include clearfix();
		overflow:hidden;
		background-color:white;

		& > li {

			&.thumbnail {
				position:relative;

				.product-image-container {
					position: relative;
					height: 100%;
					aspect-ratio: 1;
				}
			}

			&.title {
				display: flex;
				justify-content: center;
				flex-direction: column;

				& > * {
					line-height: normal;
					margin-top: 0;
					margin-bottom: 0;
				}

				p.manufacturer {
					color: $light-text;
					font-size: 0.8em;
					text-transform: uppercase;
					overflow: hidden;
					white-space: nowrap;
					text-overflow: ellipsis;
				}
			}

			&.quantity {
				overflow:visible;

				body.messe-cart-index & {
					display: flex;
					flex-direction: column;
					justify-content: center;
					align-items: center;
					min-height: 72px;
				}
			}

			&.delete {
				@include product-list-delete();
			}
		}
	}
}


.cart-table {
	margin-top: 1.4em;

	figure.product-image {
		height: 100%;
		position: relative;

		img {
			max-height: 100%;
			max-width: 100%;
		}
	}

	.button-overlay {
		position:absolute;
		left:2px;
		top:2px;
		width: 35px;
	}

	h2 {
		font-size:1em;
		font-weight:inherit;
		word-break:break-all;
		margin:0;
		overflow: hidden;
		text-overflow: ellipsis;
		white-space: nowrap;

		a {
			text-decoration:none;
			color:inherit;
		}
	}
}

.cart-table-footer {
	padding-top:1em;
	padding-right:5.75%;
	text-align:right;

	& > .sum-title {
		margin-right:0.3em;
	}

	& > .sum-title,
	& > .sum {
		font-size:1.6em;
		font-weight: 500;
		display:inline-block;
	}

	& > .note {
		margin-top:3em;
		font-size:0.7em;
		clear:both;
		position:relative;
		color:$light-text;
	}

	.empty-cart-button-container {
		display: none;
		justify-content: flex-end;
		margin-top: 0.6em;
		margin-bottom: 0.6em;
	}
}


.cart__top-container {
	margin-top: -30px;
}


/**
 * ADMA Preisübersteuerung
 */
.cart-table-items ul.properties > li.price {
	&.price-override {
		color: $kastner-red;
	}

	& > .price-wrapper {
		&.clickable {
			cursor: pointer;
		}

		&.customer-price {
			color: $purple;
		}

		&.discount-price {
			color: $red;
		}
	
		input {
			margin-left: auto;
			max-width: 100px;
			text-align: right;
			height: 32px;
			box-sizing: border-box;
			border: 1px solid #e0e0e0;	
		}

		::-webkit-input-placeholder { /* WebKit, Blink, Edge */
			color: #e1e1e1;
			opacity: 1;
		}
		:-moz-placeholder { /* Mozilla Firefox 4 to 18 */
		   color: #e1e1e1;
		   opacity: 1;
		}
		::-moz-placeholder { /* Mozilla Firefox 19+ */
		   color: #e1e1e1;
		   opacity: 1;
		}
		:-ms-input-placeholder { /* Internet Explorer 10-11 */
		   color: #e1e1e1;
		   opacity: 1;
		}
	}

	& > .price-message {
		position: absolute;
		height: 32px;
		line-height: 32px;
		top: 0;
		bottom: 0;
		margin-top: auto;
		margin-bottom: auto;
		color: $kastner-red;
		right: 130px;
		text-align: right;
		font-weight: 500;

		@include breakpoint(1440px) {
			bottom: auto;
			top: -1px;
			right: 1em;
			height: auto;
			line-height: 1;
		}

		@include breakpoint(1280px) {
			right: 0.5em;
		}
	}
}

/**
 * Responsive
 */
@media only screen and (max-width:1280px)
{
	.cart-table-header > li > span,
	.cart-table-quickadd > li,
	.cart-table-items > li.item ul.properties > li {
		padding-left:0.25em;
		padding-right:0.25em;

		&.price {
			padding-right:0.5em;

			.diff {
				right:0.6em;
			}
		}
	}
}

@media only screen and (max-width:1024px)
{
	.cart-table-items ul.properties > li.delete,
	.cart-table-quickadd > li.delete {
		padding-left:0;
	}
}

@media only screen and (max-width:880px)
{
	.cart-table-header > li, 
	.cart-table-quickadd > li,
	.cart-table-items ul.properties > li {

		&.sku {
			width:12%;
		}

		&.delete {
			width:6%;
		}
	}
}

@media only screen and (max-width:768px)
{
	.cart-table-items > li.item > .open-close {
		display:none;
	}
}

@media only screen and (max-width:767px)
{
	.cart-table,
	.sales-history {
		margin-left:-10px;
		margin-right:-10px;
	}

	.cart-table-header > li, 
	.cart-table-quickadd > li,
	.cart-table-items ul.properties > li {

		&.sku {
			display:none;
		}

		&.title {
			width:27%;
		}

		&.price {
			width:14%;
		}

		&.quantity {
			width:23%;
		}

		&.subtotal {
			width:25%;
		}
	}

	.cart-table-items ul.properties > li {

		&.subtotal {
			width:19%;
		}
	}

	.cart-table-quickadd {
		display:none;
	}

	.cart-table-header > li {
		&.title,
		&.manufacturer {
			width: 13.5%;
		}
	}
}

@media only screen and (max-width:640px)
{
	.cart-table-header > li,
	.cart-table-quickadd > li, 
	.cart-table-items ul.properties > li {

		&.position {
			display:none;
		}

		&.price {
			display:none;
		}

		&.subtotal {
			width:26%;
		}
	}

	.cart-table-header > li {

		&.title,
		&.manufacturer {
			width: 37%;
		}

		&.quantity {
			display:none;
		}
	}

	.cart-table-items ul.properties {
		height:108px;
		position:relative;

		body.messe-cart-index & {
			height: auto;
		}
	}

	.cart-table-items ul.properties > li {
		line-height:60px;
		height:60px;

		&.thumbnail {
			width:10%;

			figure {
				height:60px;
			}
		}

		&.title {
			width:64%;
		}

		&.subtotal {
			width:26%;
		}

		&.quantity {
			position:absolute;
			left:0; right:0; bottom:14px;
			width:auto;
			height:auto;
			line-height:normal;

			body.messe-cart-index & {
				position: relative;
			}
		}


		&.delete {
			position:absolute;
			right:0;
			bottom:7px;
			width:auto;
			height:auto;
			line-height:normal;
		}
	}

	.cart-table-header > li.title > span {
		margin-left:0;
	}

	.cart h1 {
		text-align:center;
	}

	.cart .top-buttons {
		text-align:center;
		padding:0 8%;
		margin-top:1em;
		margin-bottom:1.75em;
		box-sizing:border-box;

		.btn-empty-cart {
			display:none;
		}
	}

	#check-prices-form {
		@include clearfix();
		display:block;

		label, .styled-select, .btn-checkout {
			float:left;
			box-sizing:border-box;
		}

		label {
			width:40%;
			margin:0;
		}

		.styled-select {
			width:60%;
			margin:0;

			select {
				width:100%;
			}
		}

		.btn-checkout {
			width:100%;
			display:block;
			margin-left:auto;
			margin-right:auto;
			max-width:none;
			margin-top:0.5em;
		}
	}

	.cart-table-footer {

		.empty-cart-button-container {
			display: flex;
		}
	}
}

@media only screen and (max-width:480px)
{
	.cart-table-items ul.properties > li.delete .button-delete
	.cart-table-quickadd .button-add {
		width:32px;
		height:32px;
		line-height:32px;
		font-size:1.1em;
	}
}

@media only screen and (max-width:359px)
{
	.cart .top-buttons {
		padding:0;
	}	
}

.cart .cart-table .search-list {
	margin-top: 1em;
	margin-bottom: 1em;
}
