#topCategoryNav {
	position:absolute;
	top: 44px;
	left: 0;
	right: 0;
	background-color: white;
	transition: 0.2s;
	padding:40px 45px;
	z-index: 1;
	font-weight: 500;
	line-height: (24/15);
	opacity: 0;
	visibility: hidden;
	box-shadow: 0px 9px 18px 0 rgba(0,0,0,0.14);
	color: #646464;

	&.show {
		opacity: 1;
		visibility: visible;
	}

	ul {
		@include listreset();
	}

	li {

		& > a,
		& > span,
		& > strong {
			font-weight: inherit;
			color: inherit;
			display: block;
		}

		& > a:hover {
			color: $kastner-red;
		}
	}

	& > ul {
		columns: 140px 5;
		column-gap: 55px;

		& > li {
			//break-inside: avoid-column;
			//page-break-inside: avoid;
			//column-break-inside: avoid;

			& + li {
				margin-top: 56px * 0.5;
			}

			& > a,
			& > span,
			& > strong {
				font-weight: 500;
				color: #2c2c2c;
				text-transform: uppercase;
			}
		}
	}

	button {
		border: 0;
		outline: 0;
		padding: 0;
		background: none;
		font-size: 14px;
		width: 32px;
		height: 32px;
		line-height: 32px;
		text-align: center;
		position: absolute;
		right: 0;
		top: 0;
		opacity: 0;
		visibility: hidden;
		transition: 0.2s;
		transition-delay: 1s;
		cursor: pointer;
		color: $light-text;
	}

	&:hover {

		button {
			opacity: 1;
			visibility: visible;
		}
	}
}
