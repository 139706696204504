#back_to_top {
	background-color: white;
	opacity: 0;
	display: block;
	visibility: hidden;
	transition: 0.2s;
	width:48px;
	height:48px;
	line-height:48px;
	text-align:center;
	font-size:24px;
	color: black !important;
	position: fixed;
	bottom: 10px;
	right: 10px;
	cursor: pointer;
	z-index: 12;
	box-shadow: 0px 1px 12px 0 rgba(0,0,0,0.19);

	&:hover {
		background-color: $kastner-red;
		color: white !important;
	}

	.fa, .btr {
		color:inherit !important;
		position:static !important;
	}

	&.show {
		opacity: 1;
		visibility: visible;
	}
}
