.fixed-imprint {
    position: fixed;
    bottom: 10px;
    right: 10px;
    transition: right 0.2s;
    z-index: 13;
    background-color: white;
    box-shadow: 0px 1px 12px 0 rgba(0,0,0,0.19);
    height: 48px;
    font-size: 14px;
    font-weight: 700;

    ul {
        @include listreset();
        display: flex;

        & > li {
            text-align: center;
            position: relative;

            &:first-child:after {
                display: block;
                content: '';
                position: absolute;
                top: 9px;
                bottom: 9px;
                width: 1px;
                background-color: black;
                right: 0;
                margin: auto;
            }
        }
    }

    a {
        color: $font-color;
        display: block;
        line-height: 20px;
        padding: ((48px - 20px) * 0.5) 14px;
    }

    body.show-btt & {
        right: (10px + 48px + 6px);
    }

    @include tablet() {
        display: none;
    }
}

#container {
    padding-bottom: 42px;
    box-sizing: border-box;
}

body.customer-account-login #container {
    padding-bottom: 0;
}
