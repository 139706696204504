.print-pdf__container {
    position: relative;

    @include smartphone() {
        display: none;
    }

    button.print-pdf__button {
        width: 38px;
        min-width: 0;
        height: 38px;
        padding: 0;
        margin-left: 16px;
    }
}

.print-pdf__methods {
    display: none;
    list-style: none;
    margin: 0;
    padding: 0;
    position: absolute;
    right: 0;
    top: (38px + 11px);
    background-color: white;
    border-radius: 5px;
    box-shadow: 0 6px 27px 0 rgba(0,0,0,0.27);
    z-index: 2;

    .list-menu__function-container & {
        right: 50%;
        margin-right: -30px;
        top: 35px;
    }

    &:after {
        bottom: 100%;
        right: (38px / 2 - 8px);
        border: solid transparent;
        content: " ";
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(255, 255, 255, 0);
        border-bottom-color: #ffffff;
        border-width: 8px;
    }
}

.print-pdf__container.show,
.print-pdf__container.on-hover:hover {
    .print-pdf__methods {
        display: block;
    }
}

.print-pdf__method {
    margin: 0;
    padding: 0;
    white-space: nowrap;
    font-size: 18px;
    
    & + .print-pdf__method {
        border-top: 1px solid #888888;
    }
}

.print-pdf__method-link {
    color: $font-color;
    display: block;
    line-height: 24px;
    padding: 13px 22px;
}

