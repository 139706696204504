#search_mini_form {
    display: flex;
    flex-wrap: wrap;
    align-items: center;

    label,
    .nested {
        display: none;
    }

	input#search {
		padding:0;
		margin:0;
		box-sizing:border-box;
		height: 43px;
		border:0;
		padding-right: 36px;
		padding-left: 5px;
		width: 100%;
    }
    
	.control {
		display:block;
		position: relative;
		transition: opacity 0.2s, visibility 0.2s;

		&:after {
			position: absolute;
			content: '';
			display: block;
			left: 5px;
			right: 9px;
			bottom: 4px;
			height: 1px;
			background-color: #b4b4b4;
			pointer-events: none;
		}
	}

	button.search {
        @include btr();

		&:before {
			content: "\f002";
			pointer-events: none;
		}
	}

	button.search {
		min-width:0;
		background-color:transparent;
		border:0;
		position:absolute;
		top:0;
		width: 40px;
		height: 44px;
		line-height: 44px;
		font-size:20px;
		text-align:center;
		right: 0;
		bottom:0;
		margin:auto;
		padding:0;
		color:#a1a1a1;
		cursor: pointer;

		span {
			display:none;
		}
	}
}

.block.block-search .block.block-title {
    display: none;
}

.block.block-search .block.block-content,
#search_mini_form {
    height: 100%;
}
