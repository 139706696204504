.kastnerlists__listcontainer.kastnerlists--circly {
	ol.products > li.item {
        user-select: auto;
        padding-left: 4px;
        padding-right: 0;

        & > .product-name {
            width: 29%;
            flex-basis: 29%;
        }

        & > .product-price {
            width: 15%;
            flex-basis: 15%;
        }

        & > .circly {
            width: 11%;
            flex-basis: 11%;
        }

        & > .unitswitches {
            width: 22%;
            flex-basis: 22%;
        }

        & > .circly-action {
            width: 4%;
            flex-basis: 4%;
            display: flex;
            justify-content: flex-end;
            box-sizing: border-box;
            padding-right: 1em;
        }

        .circly {
            &__qty {
                font-weight: 500;
                font-size: 1.35em;
                line-height: 1;
            }

            &__desc {
                color: #a7a7a7;
                font-size: 0.8em;
            }
        }

        @include breakpoint(640px) {
            & > .product-name {
                width: auto;
            }

            & > .unitswitches {
                width: auto;
            }

            & > .circly {
                width: auto;
                grid-row: 5 / 6;
                grid-column: 1 / 3;
                display: flex;
                flex-direction: column;
                align-items: flex-end;
            }

            & > .circly-action {
                width: auto;
                grid-row: 5 / 6;
                grid-column: 3 / 5;
                justify-content: flex-start;
            }
        }
    }
}

.circly-list {
    &__top-actions {
        margin-top: -3.4em;
        display: flex;
        justify-content: flex-end;
        margin-bottom: 2em;
        gap: 1em;
        flex-wrap: wrap;

        @include tablet() {
            margin-top: 2em;
            justify-content: center;
        }
    }

    &__action-button-all {
        &[type="submit"] {
            margin: 0;
        }
    }

    &__action-button-add {
        border: 0;
        background: none;
        margin: 0;
        padding: 0;
        outline: 0;
        cursor: pointer;
        flex-shrink: 0;
        text-align: right;
        line-height: 50px;
        color:#9A9A9A;
        width: 50px;

        &:hover {
            color: $kastner-red;
        }

        .bt-angle-right {
            vertical-align: text-top;
            margin-right: 10px;
        }

        .bt-shopping-cart {
            font-size: 28px;
        }
    }

    &__turbo-frame {
        @include loader();
        display: block;
        position: relative;
        transition: padding-top 0.2s;

        &:before,
        &:after {
            position: absolute;
            left: 0;
            right: 0;
            top: 0;
            bottom: auto;
        }

        &[busy] {
            padding-top: calc(48px + 1em);

            &:before,
            &:after {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}
