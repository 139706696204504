@keyframes placeholdergradient {
    0% {
        background-position: 100% 50%
    }
    100% {
        background-position: 0% 50%
    }
}


/**
 * override styles from ff
 */
.product-name {
    width: auto;
    overflow: visible;
    white-space: normal;
}


/**
 * General product lists container
 */
.products {
    @include listreset();
    position: relative;
    padding-bottom: 0;
    margin-bottom: 1em;

    .old-price {
        display:none;
    }
    .special-price {
        .price-label {
            display:none;
        }
    }
    .price-tiers,
    .price-base {
        display:none;
    }
    .regular-price {
        display:none;
    }
    .price-info-label {
        display: none;
    }

    @include loader();
    &:before,
    &:after {
        position:absolute;
        left:0; right:0;
        bottom: 0;
    }

    &.infinitescroll--loading {
        padding-bottom: calc(48px + 1em);

        &:before,
        &:after {
            opacity:1;
            visibility:visible;
        }
    }

    &.infinitescroll--final {
        padding-bottom: 0;
    }
}
body.factfinder-result-index #main {
    @include loader();
    &:before,
    &:after {
        position:absolute;
        left:0; right:0;
        bottom: 0;
    }
}
body.factfinder-result-index.ff-dispatching-search #main {
    padding-bottom: calc(48px + 1em);

    &:before,
    &:after {
        opacity:1;
        visibility:visible;
    }
}


/**
 * Styles for both modes
 */
 .products[data-mode="list"],
 .products[data-mode="grid"] {

    [data-product] {
        border: 1px solid white;

        .product-image-container {
            position: relative;
        }

        .product-image--link {
            display: block;
        }

        &.active-quickview {
            border-color: $red;

            body.no-right & {
                border-color: white;
            }

            @include print()
            {
                border-color: white;
            }

            @include breakpoint(1171px)
            {
                border-color: white;
            }
        }

        &.similar-products-active {
            border-color: $red;

            @include print()
            {
                border-color: white;
            }
        }

        .product-price[data-placeholder] {
            .price-regular {

                span.price {
                    display:inline-block;
                    width:72px;
                    background-color:rgb(244,244,244);
                    

                    &:before { content:'\00a0'; }
                }

                span.label {
                    display:inline-block;
                    width:40px;
                    background-color:rgb(244,244,244);

                    &:before { content:'\00a0'; }
                }
            }

            .unit-label {
                width:60px;
                background-color:rgb(244,244,244);

                &:before { content:'\00a0'; }
            }

            .price-regular span.price,
            .price-regular span.label,
            .unit-label {
                background-image: linear-gradient(-60deg, rgb(244,244,244) 0%, rgb(244,244,244) 40%, rgb(253,253,253) 50%, rgb(244,244,244) 60%, rgb(244,244,244) 100%);
                background-size: 350% 250%;
                animation: placeholdergradient 1.5s linear infinite;
            }

            .price-regular span.label {
                animation-delay: -0.2s;
                animation-duration: 1.4s;
            }

            .unit-label {
                animation-delay: -0.4s;
                animation-duration: 1.3s;
            }
        }

        & > .unitswitches {
            display: flex;
            flex-direction: column;
            align-items: center;
            gap: 5px;
            padding-top: 5px;
            padding-bottom: 5px;
        }

        /**
         * Signals
         */
        &.signal-2:after,
        &[data-signal="2"]:after {
             background-color:$orange;
             display:block;
        }
 
        &.signal-3:after,
        &[data-signal="3"]:after {
             background-color:#fe0000;
             display:block;
        }
    }
}


/**
 * Grid mode
 */
.products[data-mode="grid"] {
    margin-left:-($inside-padding*0.5);
    margin-right:(-$inside-padding*0.5);
    display: flex;
    flex-wrap: wrap;

    @supports (display: grid) {
        margin-left: 0;
        margin-right: 0;
        display: grid;
        grid-template-columns: repeat(auto-fill, minmax(205px, 1fr));
        gap: 15px;
    }

    [data-product] {
        display: flex;
        flex-direction: column;
        min-width: 205px;
        max-width: 305px;
        flex-grow: 1;
        flex-basis: 0;
        box-shadow: $box-shadow;
        position: relative;
        box-sizing: border-box;
        background-color: white;
        padding: 1em;
        margin: 0 $inside-padding*0.5 $inside-padding $inside-padding*0.5;
        transition: border-color 0.2s;

        @supports (display: grid) {
            min-width: 0;
            margin: 0;
        }

        @include print() {
            box-shadow: none;
            padding: 0.2em;
        }

        @include breakpoint(480px) {
            max-width: none;
        }

        figure.product-image {
            height: 0;
            padding-top: 100%;
            position: relative;

            img {
                position: absolute;
                display: block;
                left: 0; top: 0; bottom: 0; right: 0;
                margin: auto;
                max-width: 100%;
                max-height: 100%;
            }
        }

        .product-manufacturer {
            margin-top: 0.5em;
            margin-bottom: 0;
            color:$light-text;
            font-size:0.80em;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
            min-height: 1.15em;

            &__sku {
                display: none;
            }
        }

        .product-name {
            width: auto;
            white-space: normal;
            font-size:1em;
            font-weight: 500;
            margin-top: 0.1em;
            margin-bottom: 0;
            height:40px;
            line-height:20px;
            overflow:hidden;

            a {
                color:inherit;
                text-decoration:none;
            }
        }

        .product-price {
            margin-left:-0.5em;
            margin-right:-0.5em;
            padding-left:0.5em;
            padding-right:0.5em;
            border-top:1px solid $border-color;
            margin-top: 0.2em;
            padding-top:0.15em;
            margin-bottom: 1.8em;
            position:relative;

            span.price {
                font-size:1.4em;
            }

            span.label {
                font-size:0.80em;
                color:$light-text;
            }

            .unit-label {
                position:absolute;
                top:2.15em;
                left:0.625em;
            }

            .regular-price {

                span.price {
                    font-size:1.2em;
                }

                span.label {
                    font-size: 0.6em;
                    color: $kastner-red;
                }
            }
        }

        & > .unitswitch,
        & > .unitswitches {
            margin-top: auto;
            white-space:nowrap;
        }

        &:after {
            content:"";
            display:none;
            height:4px;
            position:absolute;
            left:-1px; right:-1px; top:-1px;
            z-index:10;
        }

        .button-overlay {
            position:absolute;
            width:100px;
            right: 0;
            top: 0;
            z-index: 11;
            pointer-events: none;
        }

        div.discount-percentage > span {
            top:0;
            left:0;
            margin-left:5px;
            margin-top:10px;
        }

        .product-link {
            display:none;
        }
    }
}


/**
 * List mode
 */
.products[data-mode="list"] {

    [data-product] {
        display:flex;
        justify-content: space-between;
        box-shadow:$box-shadow;
        background-color:white;
        margin:0;
        box-sizing:border-box;
        transition: border 0.2s;
        position:relative;
        min-height: $list-height;
        align-items: center;
        margin-bottom: $list-margin;

        @include print()
        {
            box-shadow: none;
        }

        &:after {
            content:"";
            display:none;
            width:4px;
            position:absolute;
            left:-1px; bottom:-1px; top:-1px;
            z-index:10;
        }

        .button-overlay {
            position:absolute;
            left: 0;
            top: 0;
            width: 35px;
        }


        /**
         * product elements
         */
        .product-image-container {
            display: flex;
            width: 8%;
            min-width: 32px;
            flex-basis: 8%;
            box-sizing: border-box;
            padding-right: 10px;
            max-width: max-content;
            aspect-ratio: 1;
            flex-shrink: 0;
            justify-content: center;
            align-items: center;

            .prime-indicator {
                right: 10px;
            }

            figure.product-image {
                width: 100%;
                height: $list-height;
                position: relative;

                img {
                    max-width: 100%;
                    max-height: 100%;
                    width: auto;
                    height: auto;
                }
            }
        }

        .product-manufacturer {
            width: 11%;
            flex-basis: 11%;
            margin: 0;
            font-size: 13px;
            color: $light-text;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;

            span {
                display: block;
            }

            &__sku {
                margin-top: 0.55em;
            }
        }

        .product-name {
            width: 33%;
            flex-basis: 33%;
            display:block;
            font-size:1em;
            font-weight: 500;
            margin:0;
            white-space:nowrap;
            overflow:hidden;
            text-overflow:ellipsis;
            margin-top:0.075em;

            a {
                color:inherit;
                text-decoration:inherit;
            }
        }

        .product-price {
            width:21%;
            flex-basis: 21%;
            position: relative;

            span.price {
                font-weight: 500;
                font-size:1.35em;
                line-height:1;
            }

            span.label,
            .unit-label {
                display:block !important;
                font-size: 13px;
                color:$light-text;
            }

            div.discount-percentage > span {
                top:50%;
                margin-left:6em;
            }
        }

        .discount-percentage {
            position: absolute;
            font-size: 14px;
            right: 33%;
            padding-right: 5px;
            top: 0;
            bottom: 0;
            line-height: 22px;
            height: 22px;
            white-space: nowrap;
            text-align: right;
            display: block;
            margin: auto;

            & > span {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                line-height: 22px;
                height: 22px;
                display: block;
            }

            @include breakpoint(640px)
            {
                right: 0;
                top: auto;
            }
        }

        & > .unitswitch,
        & > .unitswitches {
            width: 26%;
            flex-basis: 26%;
            position: relative;
            min-width: 196px;

            &:before {
                left:0.1em;
                width:1px;
                position:absolute;
                height: 34px;
                margin: auto;
                top:0;
                bottom:0;
                background-color: $gray-border;
                content:"";
                display: block;
            }
        }

        .product-link {
            position:absolute;
            left:0; top:0;
            bottom:0; width:74%;
            display:block;
            z-index:20;
        }

        .prime-indicator {
            transform-origin: 100% 100%;
            transform: scale(0.6);
            bottom: 0.1em;
        }

        @include breakpoint(1024px)
        {
            .product-manufacturer {
                display:none;
            }

            .product-name {
                width: 39%;
                flex-basis: 39%;
            }

            & > .unitswitch,
            & > .unitswitches {
                width: 30%;
                flex-basis: 30%;
            }
        }

        @include breakpoint(768px)
        {
            .product-image-container {
                width: 10%;
                flex-basis: 10%;
            }

            .product-name {
                width: 35%;
                flex-basis: 35%;
            }

            & > .unitswitch,
            & > .unitswitches {
                width: 32%;
                flex-basis: 32%;
            }
        }

        @include breakpoint(640px)
        {
            display: grid;
            grid-template-columns: 0px 1fr 1fr 0px;
            grid-template-rows: 1fr 1fr auto 32px;
            gap: 10px;
            padding: 10px;

            body[class*="messe"] & {
                grid-template-rows: 1em auto auto;
            }

            .product-name {
                grid-column: 1 / -1;
                grid-row: 1 / 2;
                width: auto;
                margin: 0;
                text-align: left;
                margin-bottom: -10px;
            }

            .product-manufacturer {
                display: flex;
                flex-wrap: wrap;
                grid-column: 1 / -1;
                grid-row: 2 / 3;
                width: auto;
                align-items: center;

                &__sku {
                    margin-top: 0;

                    &:before {
                        content: '\00a0|\00a0';
                    }
                }
            }

            .product-image-container {
                grid-column: 2 / 3;
                grid-row: 3 / 4;
                width: auto;
                min-width: 0;
                padding: 0;

                img {
                    margin: auto;
                }

                .prime-indicator {
                    right: 0;
                }
            }

            .product-price {
                grid-column: 3 / 4;
                grid-row: 3 / 4;
                width: auto;

                .unit-label,
                .product-price .label {
                    display:none;
                }
            }

            & > .unitswitch,
            & > .unitswitches {
                grid-column: 1 / -1;
                grid-row: 4 / 5;
                width: auto;

                &:before {
                    display: none;
                }
            }

            .discount-percentage {
                top: 50px;
                right: 10px;
                bottom: auto;
            }

            .product-link {
                display: none;
            }
        }
    }
}

/**
 * FACT-Finder weirdness
 */
ff-record-list.products[data-mode="list"] {
    display: flex;
    flex-wrap: wrap;

    [data-product] {
        width: 100%;
    }
}
