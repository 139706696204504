body.customer-account-index {

	h1 {
		text-align:center;
	}

	.block {
		background-color:white;
		box-shadow:$box-shadow;
	}

	.container {
		max-width:1280px;
		margin:auto;
	}

	h2 {
		line-height:48px;
		margin-top:0;
		margin-bottom:1em;
		color:white;
		font-weight:500;
		text-align:center;
		padding:0 0.5em;
		font-size:1.2em;
		white-space:nowrap;
		overflow:hidden;
		text-overflow:ellipsis;
	}

	[class*="span"] {
		padding-bottom:4.5em;
		position:relative;
		margin-bottom:2em;
		box-sizing:border-box;
	}

	[class*="span"] .content {
		padding:0 1.25em;
		max-height: 26em;
		overflow-y: auto;
	}

	.account-info {

		h2 {
			background-color:#2196f3;
		}
	}

	.shipping-info {

		h2 {
			background-color:#fdb000;
		}

		figure {
			margin:1.25em 0;
		}
	}

	.account-info,
	.shipping-info {

		td span {
			border: 1px solid white;
			padding: 0.1em;
		}

		td, th {
			padding:0.2em 0.25em;
		}

		th {
			padding-left:0;
			text-align:left;
		}

		input {
			border:1px solid $gray-border;
			padding:0.1em;
			width:100%;

			&[disabled] {
				color:inherit;
				border-color:white;
				background-color:white;
			}
		}

		label {
			width:auto;
			display:inline;
			margin:0;
		}

		[type="submit"] {
			display:none;
			margin:1.25em auto 1em auto;
		}
	}

	.outstanding-items {

		h2 {
			background-color:#72c946;
		}

		table {

			tr.overdue {
				color: $signal-red;
			}
			
			tr.refund {
				color: var(--signal-green);
			}

			th {
				font-weight:500;
			}

			th, td {
				padding:0.25em 0;
			}

			th + th,
			td + td {
				padding-left:0.25em;
			}

			.amount {
				text-align:right;
			}

			.invnum {
				text-align:left;
			}

			.duedate {
				text-align:center;
			}

			th.amount {
				padding-right:0.825em;
			}
			
			.sum {
				border-top:1px solid;
				border-bottom:2px solid;
			}
		}
	}

	table {
		width:100%;
	}

	.bottom-link {
		color:#2196f3;
	}

	.bottom-container {
		position:absolute;
		left:0; right:0; bottom:1.5em;
		text-align:center;

		.button {
			margin-top:0;
			margin-bottom:0;
		}
	}

	form {

		.errors,
		.messages {

			ul {
				@include listreset();
				margin:1.25em 0;

				li {
					text-align:center;
					background-color:$kastner-red;
					color:white;
					padding:0.2em 0.4em;
					display:block;

					& + li {
						margin-top:0.5em;
					}
				}
			}
		}

		.messages ul li {
			background-color:#72C946;
		}
	}
}
