.prime-filter {
    margin-top: 1em;

    &__title {
        display: block;
		color: inherit;
		user-select: none;
		font-weight: 500;
		border: 0;
		padding: 0;
		margin-bottom: 0.75em; 
    }

    &__checkbox {
        opacity: 0;
        visibility: hidden;
        position: absolute;
    }

    &__label {
        display: flex;
        align-items: center;

        &:before {
            @include btr();
            content: '\F08B';
            display: block;
            width: 1.25em;
            height: 1.25em;
            line-height: 1.25em;
            text-align: center;
            margin-right: 7px;
            flex-shrink: 0;
            font-size: 0.8em;
        }
    }

    input[type="checkbox"] + &__label {
        margin-left: 0;
    }

    &__checkbox:checked + &__label {
        &:before {
            content: '\F014';
        }
    }
}
