/* clearfix */
.cf:after,
.group:after {
	content:"";
	display:block;
	clear:both;
}

/* default negative margin on .group */
.group { 
	margin-left:-1%;
	margin-right:-1%;
}

ul.group,
ol.group {
	list-style-type:none;
	padding-left:0;
	padding-right:0;
}

/* default column */
.group > * {
	position:relative;
	float:left;
	margin-left:1%;
	margin-right:1%;
	width:98%;
	min-height:0.01em;
}


/* grid widths */
.group > .span1  { width: 6.3333%; }
.group > .span2  { width:14.6666%; }
.group > .span3  { width:23.0000%; }
.group > .span4  { width:31.3333%; }
.group > .span5  { width:39.6666%; }
.group > .span6  { width:48.0000%; }
.group > .span7  { width:56.3333%; }
.group > .span8  { width:64.6666%; }
.group > .span9  { width:73.0000%; }
.group > .span10 { width:81.3333%; }
.group > .span11 { width:89.6666%; }
.group > .span12 { width:98.0000%; }

/* column sets */
.group.col2 > * { width:48.0000%; }
.group.col3 > * { width:31.3333%; }
.group.col4 > * { width:23.0000%; }
.group.col6 > * { width:14.6666%; }

/* pushing */
.group > .push1  { left: 8.3333%; }
.group > .push2  { left:16.6666%; }
.group > .push3  { left:25.0000%; }
.group > .push4  { left:33.3333%; }
.group > .push5  { left:41.6666%; }
.group > .push6  { left:50.0000%; }
.group > .push7  { left:58.3333%; }
.group > .push8  { left:66.6666%; }
.group > .push9  { left:75.0000%; }
.group > .push10 { left:83.3333%; }
.group > .push11 { left:91.6666%; }

/* pulling */
.group > .pull1  { left: -8.3333%; }
.group > .pull2  { left:-16.6666%; }
.group > .pull3  { left:-25.0000%; }
.group > .pull4  { left:-33.3333%; }
.group > .pull5  { left:-41.6666%; }
.group > .pull6  { left:-50.0000%; }
.group > .pull7  { left:-58.3333%; }
.group > .pull8  { left:-66.6666%; }
.group > .pull9  { left:-75.0000%; }
.group > .pull10 { left:-83.3333%; }
.group > .pull11 { left:-91.6666%; }


/* zero gutter */
.group.g0,
.group.g0 > * {
	margin-left:0;
	margin-right:0;
}

.group.g0 > * {
	width:auto;
}

.group.g0 > .span1  { width:  8.3333%; }
.group.g0 > .span2  { width: 16.6666%; }
.group.g0 > .span3  { width: 25.0000%; }
.group.g0 > .span4  { width: 33.3333%; }
.group.g0 > .span5  { width: 41.6666%; }
.group.g0 > .span6  { width: 50.0000%; }
.group.g0 > .span7  { width: 58.3333%; }
.group.g0 > .span8  { width: 66.6666%; }
.group.g0 > .span9  { width: 75.0000%; }
.group.g0 > .span10 { width: 83.3333%; }
.group.g0 > .span11 { width: 91.6666%; }
.group.g0 > .span12 { width:100.0000%; }

.group.col2.g0 > * { width:50.0000%; }
.group.col3.g0 > * { width:33.3333%; }
.group.col4.g0 > * { width:25.0000%; }
.group.col6.g0 > * { width:16.6666%; }


/* 1% gutter */
.group.g1 {
	margin-left:-0.5%;
	margin-right:-0.5%;
}

.group.g1 > * {
	margin-left:0.5%;
	margin-right:0.5%;
	width:96%;
}

.group.g1 > .span1  { width:  7.3333%; }
.group.g1 > .span2  { width: 15.6666%; }
.group.g1 > .span3  { width: 24.0000%; }
.group.g1 > .span4  { width: 32.3333%; }
.group.g1 > .span5  { width: 40.6666%; }
.group.g1 > .span6  { width: 49.0000%; }
.group.g1 > .span7  { width: 57.3333%; }
.group.g1 > .span8  { width: 65.6666%; }
.group.g1 > .span9  { width: 74.0000%; }
.group.g1 > .span10 { width: 82.3333%; }
.group.g1 > .span11 { width: 85.6666%; }
.group.g1 > .span12 { width: 99.0000%; }

.group.col2.g1 > * { width:49.0000%; }
.group.col3.g1 > * { width:32.3333%; }
.group.col4.g1 > * { width:24.0000%; }
.group.col6.g1 > * { width:15.6666%; }


/* 4% gutter */
.group.g4 {
	margin-left:-2%;
	margin-right:-2%;
}

.group.g4 > * {
	margin-left:2%;
	margin-right:2%;
	width:96%;
}

.group.g4 > .span1  { width:  4.3333%; }
.group.g4 > .span2  { width: 12.6666%; }
.group.g4 > .span3  { width: 21.0000%; }
.group.g4 > .span4  { width: 29.3333%; }
.group.g4 > .span5  { width: 37.6666%; }
.group.g4 > .span6  { width: 46.0000%; }
.group.g4 > .span7  { width: 54.3333%; }
.group.g4 > .span8  { width: 62.6666%; }
.group.g4 > .span9  { width: 71.0000%; }
.group.g4 > .span10 { width: 79.3333%; }
.group.g4 > .span11 { width: 87.6666%; }
.group.g4 > .span12 { width: 96.0000%; }

.group.col2.g4 > * { width:46.0000%; }
.group.col3.g4 > * { width:29.3333%; }
.group.col4.g4 > * { width:21.0000%; }
.group.col6.g4 > * { width:12.6666%; }


/* clearing */
.group.col2 > *:nth-child(2n+1) { clear:left; }
.group.col3 > *:nth-child(3n+1) { clear:left; }
.group.col4 > *:nth-child(4n+1) { clear:left; }
.group.col6 > *:nth-child(6n+1) { clear:left; }

/* manual clearing */
.cl { clear:left; }
.cr { clear:right; }
.cb { clear:both; }

/* centered column */
*[class*='span'].center {
	margin-left:auto !important;
	margin-right:auto !important;
	clear:both;
	float:none;
}

/* ie8 does not support media queries */
body.ie8 .hide-desktop {
	display:none;
}


/* responsive */
@media only screen and (min-width:769px)
{
	/* hide elements for desktop */
	.hide-desktop {
		display:none;
	}
}

@media only screen and (max-width:768px)
{
	/* expand columns to default width */
	.linearize.group,
	.linearize.group.g0,
	.linearize.group.g1,
	.linearize.group.g2,
	.linearize.group.g4 {

		&, & > * {
			margin-left:0;
			margin-right:0;			
		}

		& > * {
			float:none;
			width:100%;
			left:0;
		}
	}

	/* hide elements for mobile */
	.hide-mobile {
		display:none;
	}
}
