html, body {
	height: 100%;
	font-size: $font-size;

	@include print()
	{
		font-size: 14px;
	}
}

body {
	background-color:rgb(227,227,227);
	color:$font-color;
	//font-family: 'Calibri', 'Open Sans', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
	//font-family:'Quicksand';
	//font-family: 'avenir', 'Calibri', 'Open Sans', sans-serif;
	//font-family: 'Montserrat', sans-serif;
	font-family: calibri, 'Calibri', -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif;
	font-weight:300;

	@include print()
	{
		background-color: transparent;
	}
}

a {
	color:$kastner-red;
	text-decoration:none;
}

.block-title {
	margin:0.5em 0;
}

label[for="qty"] {
	display:none;
}

button {
	cursor:pointer;
}

.load-more-pages-container {
	text-align:center;
	margin:1em 0;
}

#iwd_load_more_pages {
	margin-left:auto;
	margin-right:auto;
}

.load-more-container {
	text-align:center;
	margin:1em 0;
}

input.qty,
input.multi {
	border:1px solid #e0e0e0;
	text-align:center;
	width:64px;
	height:32px;
	line-height:32px;
	padding:0;
	font-size:1em;
	font-weight: 500;
	margin-left:0;

	& + button {
		position:relative;
		top:-2px;
	}

	&[disabled] {
		border-color:white;
		background-color:white;
	}
}

ul.add-to-wishlist {
	@include listreset();
	margin:1em 0;
}

p.item-msg.error {
	color:$bright-red;
}

p.required {
	font-size:0.7em;
	color:$light-text;
}

pre {
	font-size:12px;
	padding:0.8em 1em;
	border-radius:3px;
	background-color:$background-gray;
	border:1px solid $dark-gray;
}

.no-display {
	display:none;
}




[data-quickview] {
	cursor:pointer;

	body.no-right & {
		cursor:default;
	}
}

#right {
	position:relative;

	& > .content {
		overflow-y:auto;
	}
}

#main {
	padding-bottom:1em;
	min-height: 1px;

	.page-title {

		h1, h2, h3, h4, h5, h6 {

			body:not(.page-layout-1column) & {
				margin-top:-0.19em;
			}
		}
	}

	h1:first-of-type {

		body:not(.page-layout-1column) & {
			margin-top:-0.19em;
		}
	}
}

#mobilefilternavbutton {
	display:none;
	width: 48px;
	height: 48px;
	line-height: 48px;
	text-align: center;
	font-size: 30px;
}

#mobilefilternavbutton {
	color:$font-color;
	position:absolute;
	left:0;
	top:0;
}




#leftnav {
	font-weight:300;

	ul {
		margin:0;
		padding:0;
		font-size:0;
		line-height:0;
		text-align:center;
	}

	a {
		position:relative;
		display:block;
		color:inherit;
		text-decoration:none;
		padding:0 10px;
	}

	li {
		display:inline-block;
		height:40px;
		font-size:16px;
		font-size:0.8888rem;
		line-height:40px;

		&.active {
			a {
				color:$kastner-red;
			}
		}

		& + li {

			a {
				padding-left:11px;

				&:before {
					content:"";
					display:block;
					position:absolute;
					left:0;
					top:0;
					bottom:0;
					margin:auto;
					height:20px;
					width:1px;
					background-color:$font-color;
				}
			}
		}
	}
}


/* common nav */
/*
#left nav {

	li {
		margin: 0.15em 0;

		&.current,
		&.active {
			color:$kastner-red;
		}
	}

	a {
		display:block;
		position:relative;
		color:inherit;
		transition:0.2s;
		padding-right:20px;

		&:hover {
			color:$kastner-red
		}

		.fa, .btr {
			position:absolute;
			width:20px;
			height:18px;
			line-height:18px;
			right:0;
			text-align:right;
			top:0;
			bottom:0;
			margin:auto;
		}
	}
}

#left nav.filter-nav {
	margin-bottom:1em;
}
*/

/* category-nav */
/*
#left nav.category-nav {

	ul {
		@include listreset();
	}

	li.active,
	li.current {
		color:inherit;
	}

	a {
		display:block;
		color:inherit;
		text-decoration:none;
		padding-right:4px;
		padding-left:26px;
		position:relative;
		line-height:20px;
		margin: 6px 0;

		&:hover {
			color:inherit;
			text-decoration:none;
		}

		&:before {
			content:"";
			display:block;
			position:absolute;
			text-align:center;
			left:0;
			top:0;
			width:26px;
			height:20px;
			line-height:20px;
			margin:auto;
			transition:0.2s;
		}
	}

	li.parent > a:before {
		content:"+";
	}

	li.parent.active > a:before {
		-webkit-transform:rotate(45deg);
		        transform:rotate(45deg);
	}

	li.active > a {
		font-weight:700;
	}

	& > ul {
		background-color:white;
		padding:4px 0;

		& > li {

			ul {
				margin-left:22px;

				a {
					padding-left:18px;
					margin: 6px 0;

					&:before {
						width:18px;
					}
				}
			}

			& > a {
				font-size: inherit;
				font-weight: inherit;
			}
		}


		li > ul {
			display:none;
			padding-bottom:1em;
		}

		li.active > ul {
			display:block;
		}
	}
}
*/


.button {

	&.btn-cart {
		display:inline-block;
		background-color:#ff5858;
		border:1px solid #f17b7b;
		color:white;
		padding:5px 10px;
		min-width:80px;
		font-size:22px;
		font-weight:300;
		transition:0.2s;

		&:hover {
			background-color:$kastner-red;
		}
	}
}

.pager,
.sorter {
	label {
		min-width:0;
	}
}

.a-right {
	text-align:right;
}

.page.messages .messages {
	color:black;

	.message {
		margin:2em;
		padding:6px 8px;

		& + .message {
			margin-top:0.25em;
		}

		& > ul {
			@include listreset();
		}

		&.message-error {
			border:1px solid $bright-red;
			background-color:#fef2f2;
		}

		&.message-success {
			border:1px solid #C3E11B;
			background-color:#FAFFDF;
		}
	}
}

.col2-set {
	@include clearfix();
	margin-left:-2%;
	margin-right:-2%;

	& > * {
		margin-left:2%;
		margin-right:2%;
	}

	& > [class*="col"] {
		float:left;
		width:46%;
	}
}





#footer {
	min-height:24px;
	background-color:$dark-gray;
	padding:10px 16px;
	color:#cacaca;
	position:relative;
	font-size:0.9em;
	overflow:hidden;

	a { 
		color:inherit; 
		text-decoration:none;
		&:hover { text-decoration:underline; }
	}

	&:after {
		display:block;
		content:"";
		background-color:#bfbfbf;
		height:2px;
		position:absolute;
		left:0px;
		right:0px;
		top:44px;
	}

	.social-links {
		@include clearfix();
		position:absolute;
		right:0px;
		top:-2px;
		font-size:18px;

		& > * {
			float:left;
			display:block;
			margin-left:4px;

			& > a { 
				display:block; 
				width:100%; 
				height:100%;
				width:28px;
				height:28px;
				text-align:center;
				line-height:28px;
				background-color:#cacaca;
				color:$dark-gray;
				border-radius:3px;
				transition:0.4s;

				&:hover {
					background-color:#CB1616;
					color:white;
				}
			}
		}
	}

	ul {
		list-style-type:none;
		margin:0; padding:0;
	}

	& > .inside > ul {
		@include clearfix();

		margin-left:-1%;
		margin-right:-1%;

		& > li {
			float:left;
			width:18%;
			margin-left:1%;
			margin-right:1%;

			&.menu-double-width {
				width:28%;

				& > ul {
					@include clearfix();

					margin-left:-2%;
					margin-right:-2%;

					& > li {
						float:left;
						width:46%;
						margin-left:2%;
						margin-right:2%;
					}
				}
			}

			& > span,
			& > a {
				display:block;
				font-weight:700;
				margin-bottom:22px;
			}

			& > ul {
				font-size:0.9em;

				& > li {
					position:relative;

					&:before {
						content:"\00BB";
						position:absolute;
						left:0; top:0;
						height:80%;
						line-height:1.4;
					}
				}

				span,
				a {
					margin-left:14px;
					display:block;
					padding:1px 0;
				}
			}
		}
	}
}


#left {
/*
	nav {

		& > h1 {
			box-shadow:$box-shadow;
			user-select: none;
			padding-left:0.6em;
			padding-right:0.6em;
			font-size:1em;
			font-weight:300;
			color:#858585;
			line-height:1.9444em;
			background-color:white;
			margin:0;
			position:relative;
			cursor:pointer;

			-webkit-touch-callout: none; // iOS Safari
			-webkit-user-select: none;   // Chrome/Safari/Opera
			-khtml-user-select: none;    // Konqueror
			-moz-user-select: none;      // Firefox
			-ms-user-select: none;       // Internet Explorer/Edge
			user-select: none;           // Non-prefixed version, currently not supported by any browser

			&:before,
			&:after {
				@include font-awesome();
				color:#e3e3e3;
				position:absolute;
				height:32px;
				line-height:32px;
				margin:auto;
				top:0;
				bottom:0;
				cursor:pointer;
				display:block;

				-webkit-touch-callout: none; // iOS Safari
				-webkit-user-select: none;   // Chrome/Safari/Opera
				-khtml-user-select: none;    // Konqueror
				-moz-user-select: none;      // Firefox
				-ms-user-select: none;       // Internet Explorer/Edge
				user-select: none;           // Non-prefixed version, currently not supported by any browser
			}

			&:after {
				content:"\f054";
				right:0.6em;
			}
		}

		&.no-hide > h1 {
			cursor:default;
		}

		& > ul,
		& > dl,
		& > ol,
		& > .container {
			@include listreset();
			position:absolute;
			left:100%;
			width:100%;
			top:50px;
			bottom:0;
			transition:left 0.2s;
			overflow-x:hidden;
			overflow-y:auto;
			background-color:#f7f7f7;
			z-index:98;
			margin:0;
			padding:0 0.6em;
			box-sizing:border-box;

			& > li {

				& > ol,
				& > ul {
					@include listreset();
				}
			}

			h2 {
				font-weight: 500;
				font-size:1em;
			}			
		}

		& > .container {

			ul, ol {
				@include listreset();
			}
		}

		&.show {

			& > h1 {

				&:after {
					content:"\f053";
				}
			}

			& > ul,
			& > dl,
			& > ol,
			& > .container {
				left:0;
			}
		}
	}
*/
}


@mixin filteritem()
{
	font-weight:inherit;
	height:auto;
	color:inherit;
	display:block;
	margin:8px 0.8em;
	border-radius:8px;
	background-image:none;
	background-color:white;
	border:1px solid $border-color;
	position:relative;
	padding-top:2px;
	padding-bottom:2px;
	padding-left:0.5em;
	padding-right:1em;
	font-size:0.88888em;
	word-wrap:break-word;

	& > .fa, .btr {
		position:absolute;
		width:28px;
		height:28px;
		line-height:28px;
		font-size:18px;
		text-align:center;
		right:0; top:0; bottom:0;
		margin:auto;
		padding:0;
		font-size:0.7em;
	}

	& > a {
		color:inherit;
	}

	&:hover {
		color:$kastner-red;
	}

	&.clear {
		color:$red;
		border-color:white;
		text-align:center;
	}

	& > .count {
		font-size:12px;
		color:$light-text;
		position:absolute;
		right:26px;
		top:0; bottom:0;
		margin:auto;
		height:14px;
		line-height:14px;
	}
}


#left {

	nav.filter-nav {

		& > h1 {
			overflow:hidden;
			// border-bottom:1px solid #d3d3d3;

			&:before {
				content:"\f053";
				left:0.6em;
				display:none;
			}
		}

		&.show {

			h1 {
				text-indent:1000px;

				&:before {
					text-indent:0;
					display:block;
				}

				&:after {
					text-indent:0;
					content:"gesamter Shop";
					font:inherit;
					color:inherit;
					line-height:32px;
				}
			}
		}

		ul.currently {
			@include listreset();
			position:relative;
			padding:4px 0 5px 0;
			background-color:#f7f7f7;
			//border-top:1px solid $border-color;
			//border-bottom:1px solid $border-color;
			min-height:128px;
			left:auto;
			width:auto;

			&:after {
				display:block;
				position:absolute;
				left:-0.6em;
				right:-0.6em;
				bottom:0;
				height:1px;
				background-color:$border-color;
				content:"";
			}

			& > li {

				& > ol {
					@include listreset();

					& > li {

						& > .item {
							@include filteritem();

							&.selected {
								font-weight:normal;
							}
						}
					}
				}

				& > .item {
					@include filteritem();
					& > .label {
						display:none;
					}
				}
			}
		}

		ul.filter-list {

			& > li {

				& > h2 {
					text-align:center;
					position:relative;
					cursor:pointer;
					padding-left:15px;

					&:after {
						content:"";
						display:block;
						width:20%;
						height:1px;
						background-color:$border-color;
						position:absolute;
						bottom:-8px;
						left:0;
						right:0;
						margin:auto;
					}

					.btr {
						position:absolute;
						color:$light-text;
						left:0;
						top: 0.55em;
						font-size: 0.6666em;
					}
				}

				& > ol,
				& > ul {
					@include listreset();
					transition:0.2s;
					overflow:hidden;
					max-height:none;

					& > li {
						& > .item {
							@include filteritem();
							padding-right:46px;

							&.selected {
								font-weight:700;
								color:$kastner-red;
							}
						}
					}
				}

				&.hide {

					& > h2 {

						.btr {
							transform:rotate(45deg);
						}
					}

					& > ol,
					& > ul {
						max-height:0;
					}
				}
			}
		}
	}

	.block-account {
		padding:0 0.6em;
	}
}


.std {
	img {
		max-width:100%;
		height:auto;
	}
}


#cart-popup {
	background-color:rgba(0,0,0,0.3);
	position: fixed;
	left:0; right:0; top:0; bottom:0;
	display:none;
	z-index:101;

	.inside {
		display: flex;
		justify-content: center;
		align-items: center;
		position: absolute;
		left: 0; top: 0;
		right: 0; bottom: 0;
	}

	.content {
		display: block;
		padding: 1em;
		font-size: 18px;
		margin-left: 20px;
		margin-right: 20px;
		max-width: 380px;
		background-color: white;
		box-shadow:0px 4px 24px 0 rgba(0,0,0,0.2);
	}

	button {
		margin: auto;
		margin-top: 1em;
		display: block;
	}

	&.error .content {
		border: 2px solid red;
	}
}


#cart-progress {
	position:absolute;
	left:0; right:0;
	bottom:-4px;
	z-index:101;

	.bar {
		width:0;
		height:3px;
		min-width:20px;
		background-color:#d0ef16;
		box-shadow:0px 1px 10px 0px rgba(208,239,22,0.25);
		transition:0.15s;
		display:none;
		//border-right:5px solid #e3f573;
		box-sizing:border-box;

		&.finished {
			box-shadow:0px 1px 8px 0px rgba(208,239,22,0.8);
		}

		&.error {
			background-color:$bright-red;
			box-shadow:0px 1px 8px 0px rgba(255,0,0,0.3);
		}
	}

	body.sticky-header & {
		position:fixed;
		bottom:auto;
		top:41px;
		z-index:100;

		.bar {
			height:4px;
		}
	}
}

.styled-select {
	width:8em;
	overflow:hidden;
	position:relative;
	height:1.4em;
	cursor:pointer;
	vertical-align:top;

	select {
		cursor:pointer;
		width:8em;
	    background: transparent;
		padding:0;
		font-size: 1em;
		line-height: 1;
		border: 0;
		border-radius: 0;
		-webkit-appearance: none;
		outline:0;
		height:1.4em;
		color: inherit;
		font-weight: inherit;
		padding-right: 32px;
	}

	select {
		appearance: none;
	}

	select::-ms-expand {
	 	display: none;
	}

	.fa, .btr {
		position:absolute;
		right:0;
		top: -1px;
		bottom: -1px;
		margin:auto;
		width: 24px;
		height: 24px;
		background-color:$background-gray;
		color:inherit;
		text-align:center;
		line-height: 24px;
		pointer-events:none;
	}

	&.big {
		background-color:white;
		width: 10.5em;
		height:38px;
		line-height:38px;

		select {
			font-size: 1.35em;
			font-weight: 300;
			width:7.7777em;
			height: 38px;
			padding:0 0.2592em;
			border:0;
			outline:0;
			box-sizing:border-box;

			option {
				padding:0 0.2592em;
				//padding:0 0.35rem;
				border:0;
				outline:0;
			}
		}

		.fa, .btr {
			width:38px;
			height:38px;
			text-align:center;
			line-height:38px;
			color:white;
			background-color:$medium-blue;
		}

		select[disabled] {
			cursor:default;

			& + .fa,
			& + .btr {
				background-color:white;
			}
		}
	}
}


.pager {

	.limiter {
		@include clearfix();

		& > * {
			//float:left;
			display:inline-block;
		}

		label {
			margin-top:0;
			margin-bottom:0;
		}

		.styled-select {
			width:60px;
			margin-top:-1px;
			vertical-align:top;

			select {
				width:88px;
			}
		}
	}

	.pages {
		margin:0.2em 0;

		.label, ol {
			display:inline-block;
			vertical-align:middle;
		}

		ol {
			@include listreset();
			@include clearfix();

			& > li {
				float:left;
				text-align:center;

				&.current, & > a {
					display:block;
					padding:0.1em 0.25em;
					background-color:$medium-blue;
					color:white;
					min-width:0.8em;
				}

				& > a {
					background-color:$gray-border;
					transition:0.2s;

					&:hover {
						background-color:$kastner-red
					}
				}

				& + li {
					margin-left:0.1em;
				}
			}
		}
	}
}

.support .contacts {
	margin-top:2em;
	margin-bottom:2em;
	list-style-type:none;
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 1em;

	.contact {
		@include no-margin-collapse();
		margin-top:88px;
		max-width:384px;
		box-sizing:border-box;
		text-align:center;
		background-color:white;
		padding-left:1em;
		padding-right:1em;
		padding-top:88px;
		position:relative;
		box-shadow:$box-shadow;
		min-height:313px;
		flex-grow: 1;

		.image {
			position:absolute;
			top:-88px; left:0; right:0;
			width:176px;
			height:176px;
			border-radius:88px;
			overflow:hidden;
			margin-left:auto;
			margin-right:auto;
		}

		.name {
			color:black;
			font-size:1.4em;
			font-weight:300;
			margin-bottom:0;
		}

		.dept {
			color:#757575;
			margin-bottom:1.6em;
		}

		.fa, .btr {
			display:inline-block;
			vertical-align:middle;
		}

		.email {
			margin-bottom:1.6em;
			white-space:nowrap;
			overflow:hidden;
			text-overflow:ellipsis;
		}

		.email-link {
			margin:1em auto;
			display:block;
			border:1px solid #bebebe;
			padding:8px;
			color:inherit;
			text-decoration:none;
			transition:0.2s;
			border-radius:10px;
			max-width:100%;
			width:8em;
			box-sizing:border-box;

			&:hover {
				color:$kastner-red;
				border-color:$kastner-red;
			}
		}
	}
}

.account-forgotpassword,
.account-forgotpassword-success,
.account-resetpassword {

	.back-link a {
		color:$baby-blue;
		font-size: 1em;
		font-weight: 300;
		text-transform: lowercase;
	}

	.wrapping-container {
		position: relative;
		max-width:1200px;
		margin: 75px auto 0;
	
		.forgotpassword-container,
		.resetpassword-container,
		.success-container {
			width: 100%;
			background-color: white;
			box-shadow: $box-shadow;
			overflow: auto;
			box-sizing: border-box;
			padding: 100px;

			.lock-icon {
				max-width: 30%;
				max-height: 100%;
				float: left;
			}

			.fieldset {
				height: 100%;
				max-width: 60%;
				float:right;

				h2 {
					font-size:3em;
					font-weight:700;
					color: #A7A7A5;
					margin:0px;
				}

				p {
					font-size: 1.2em;
					color: #A6A6A4;
				}

				p.check {
					font-size: 7em;
					color: $flashy-green;
					text-align: center;
					margin:0;
				}

				label {
					color: #A6A6A4;
				}

				.input-box {
					width: 100%;
				}

				input[type="text"],
				input[type="email"],
				input[type="password"] {
					height:40px;
					border-radius:4px;
					border:2px solid $gray-border;
					padding:0 0.4em;
					width:100%;
					margin:0.5em 0;
					display:inline-block;
					font-size:1.1111em;
					box-sizing:border-box;
				}

				.mage-error {
					color: $medium-red;
					font-weight: 500;
					margin-bottom: 20px;
				}

				button[type="submit"],
				a.button {
					float:right;
					margin-top:2em;
				}
			}

			.technical-support-link-container {
				position: absolute;
				bottom: -25px;
				right: 0px;

				a {
					color:#0090ff;
				}
			}
		}
	}
}

.offene-posten {
	text-align:center;

	ul {
		@include listreset();
		@include clearfix();
	}

	ul.header {
		font-weight:700;
		text-align:center;

		& > li {
			padding-top:4px;
			padding-bottom:4px;
		}
	}

	ul.header,
	ul.properties {

		& > li {
			float:left;
			box-sizing:border-box;
			padding-left:0.5em;
			padding-right:0.5em;
			background-color:white;
			white-space:nowrap;
			overflow:hidden;
			text-overflow:ellipsis;
		}
	}

	ul.items {

		& > li {
			margin-top:0.5em;
		}
	}

	ul.properties {

		& > li {
			height:$list-height;
			line-height:$list-height;
		}
	}

	ul.header {

		& > li {
			& + li {
				border-left:5px solid $background-gray;
			}
		}
	}

	li.fibuid     { width:9%; }
	li.sku        { width:9%; }
	li.invnum     { width:9%; }
	li.invdate    { width:11%; }
	li.duedate    { width:11%; }
	li.warncode   { width:6%; }
	li.active     { width:5%; }
	li.amount     { width:12%; }
	li.text       { width:18%; text-align:left; }
	li.lastchange { width:10%; }
}


#error404 {
	color:$light-text;
	margin:5em 0;
	max-width:890px;
	background-color:white;
	box-shadow:$box-shadow;
	margin-left:auto;
	margin-right:auto;
	padding:3em 2em 2em 2em;

	h1 {
		font-weight:700;
		font-size:2.6em;
	}

	p {
		font-size:1.2222em;

		a {
			font-weight:700;
			color:inherit;
			text-decoration:none;
		}
	}
}


body.cms-einkaufen {

	#main {

		h1 {
			margin-top:0 !important;

			img {
				max-width:100%;
				height:auto;
			}
		}
	}
}

#wishlist-popup {
	font-size:1.1111em;
	box-shadow:0 10px 12px 0 rgba(0,0,0,0.5);
	background-color:white;
	border:0;
	width:400px;
	margin-left:-200px;
	margin-top:10%;
	top:auto;
	max-height:80%;
	overflow: auto;
	left: 50%;
	margin-left: -200px;
	z-index: 15001;
	position: fixed;

	h4 {
		font-size:1.25em;
		font-weight: 500;
		margin: 0.65em 0;
	}
}
#wishlist-popup-loading {
	position: absolute;
	z-index: 15002;
	width: 100%;
	height: 100%;
	opacity: 0.9;
	filter: alpha(opacity=90);
	background: url(../images/loader.gif) center center #000000 no-repeat;
	top:-1px;
	left:-1px;
}
#wishlist-popup-header {
	@include no-margin-collapse();
	background-color:$medium-blue;
	text-align:center;
	padding-left:0.5em;
	padding-right:0.5em;
	color:white;
}
#wishlist-popup-content {
	padding:0 1em;
	min-height:8em;
	$wishlist-element-line-height:2.7em;

	ul.lists {
		@include listreset();
		margin-right:-1em;

		&.offers li:last-child {
			border-bottom:3px solid #e0e0e0;
		}

		li {
			cursor:pointer;
			line-height:$wishlist-element-line-height;
			border-bottom:1px solid #e0e0e0;
			padding-right:2em;
			position:relative;

			.count {
				position:absolute;
				top:0;
				bottom:0;
				right:1em;
				color:#dcdcdc;
			}
		}
	}

	.create-new-wishlist {
		color:#13d914;
		line-height:$wishlist-element-line-height;
		padding-right:$wishlist-element-line-height;
		cursor:pointer;
		position:relative;

		.btr {
			position:absolute;
			right:0;
			top:0;
			bottom:0;
			line-height:inherit;
		}
	}

	input.new-wishlist-name {
		width:100%;
		box-sizing:border-box;
	}

	.input-container {
		display:none;
		line-height:$wishlist-element-line-height;
	}
}

#wishlist-popup-box {
	background-color:rgba(255,255,255,0.5);
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 15000;
}
#wishlist-popup-mask {
	opacity:0.06;
	filter:alpha(opacity=6);
	background-color:black;
}
#wishlist-radiostack {
	font:inherit;
	font:inherit;
	margin:0 !important;

	label {
		color:$light-text;
	}

	& > div {
		margin:0.5em 0;
	}
}
#wishlist-popup-loading {
	background-color:white;
}


.form-search .search-autocomplete.advanced-suggest li {
    position: relative;
    z-index: 999;
}

.hide-show {
	opacity:0;
	visibility:hidden;
	transition:0.4s;

	&.show {
		opacity:1;
		visibility:visible;
	}
}

[data-show] {
	cursor:pointer;
}


.upsell ~ .note-msg {
	display:none;
}

div.ce_text.block.slider {
	background-color:white;
	padding:0 10px;
	box-shadow:$box-shadow;

	p {
		display:none;
	}
}


/**
 * Responsive
 */
@media only screen and (max-width:1024px)
{
	#container > #main { 
		.account-forgotpassword > .wrapping-container > .forgotpassword-container,
		.account-forgotpassword-success > .wrapping-container > .success-container {
			padding: 50px 20px;
		}
	}	
}


@include bp870()
{
	body:not(.page-layout-1column) #main h1:first-of-type {
		margin-top:0.67em;
	}

	#leftnav,
	.hide-column {
		display:none;
	}

	#mobilefilternavbutton {
		display:block;
	}

	ul.contacts {

		.contact {
			margin-top:108px;
		}
	}
}

@media only screen and (max-width:600px) {
	#container > #main { 
		.account-forgotpassword > .wrapping-container > .forgotpassword-container,
		.account-forgotpassword-success > .wrapping-container > .success-container {
			.lock-icon {
				display: none;
			}
			.fieldset {
				max-width: 100%;
			}
		}
	}
}
