#header {

	.shipping-costs-container {

		@include tablet()
		{
			display: none;
		}
	}

	.shipping-costs {
		color:$bright-red;
		width:30px;
		height:30px;
		position: relative;
		font-size:0.8888em;
		overflow:visible;
		transition: opacity 0.4s, visibility 0.4s, color 0.4s;
		position: relative;
		display: flex;
		align-items: center;
		justify-content: center;

		&.hide {
			opacity:0;
			visibility:hidden;
		}

		.circle-background {
			position:absolute;
			left:0; top:0;
			width:26px;
			height:26px;
			border-radius:26px;
			border:2px solid #EAEAEA;
		}

		.circle-canvas {
			position:absolute;
			left:0; top:0;
			transition:0.4s;
			width: 30px;
			height: auto;
		}

		.costs {
			text-align:center;
			position: relative;
			top: -1px;
			user-select: none;
		}

		&.zero {
			color:$light-green;

			.circle {
				border-color:$light-green;
			}

			.circle-canvas {
				//opacity:0;
				//visibility:hidden;
			}
		}


		@include bp870()
		{
			display: none !important;
		}
	}
}



#shipping-cost-overlay {
	font-size:1.05em;
	padding:0.6em 0.8em;
	padding-bottom:0.5em;
	overflow:hidden;
	position:absolute;
	margin:0;
	right:0;
	top:44px;
	background-color:white;
	box-shadow:$box-shadow;
	z-index:33;
	height:auto;
	border:1px solid #e8e8e8;
	box-sizing:border-box;
	min-width:283px;

	.title {
		text-align:center;
		margin-bottom:0.6em;
	}

	ul {
		@include listreset();
		text-align:justify;
		line-height:0;

		&:after {
			display:inline-block;
			height:1px;
			width:100%;
			content:"";
		}
	}

	canvas {
		width: 66px;
		height: auto;
	}

	li {
		position:relative;
		color:$bright-red;
		font-size:1.2em;
		font-weight:500;
		display:inline-block;
		width:66px;
		height:66px;
		line-height:66px;
		text-align:center;
		background-size:66px auto;
		background-position:center;
		background-repeat:no-repeat;
		background-image:url('../images/wheel03@2x.png');

		span.cost {
			position:absolute;
			left:0; right:0;
			top:0; bottom:0;
			margin:auto;
			text-align:center;
			width:58px;
			height:58px;
			line-height:58px;
			z-index:20;
		}

		&.cost0 {
			color:$light-green;
		}
	}

	.info {
		font-size: 0.8em;
		color: var(--light-text);

		p {
			margin: 0.5em 0;
		}
	}
}