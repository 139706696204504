@mixin btn-base() {
	position: relative;
	display: inline-block;
	height: $button-height;
	line-height: $button-height - 4px;
	min-width: 194px;
	padding: 0 16px;
	text-align: center;
	outline: 0;
	border: 0;
	box-sizing: border-box;
	cursor: pointer;
	transition: 0.1s;
	overflow: hidden;
	max-width: 432px;
	border-radius: 5px;
	border-width: 2px;
	border-style: solid;
	background-color: transparent;
	background-image: none;
	appearance: none;
}

@mixin btn-hide()
{
	padding: 0;
	max-width: $button-height;
	min-width: 0;
	white-space: nowrap;

	&:after {
		background-color: $background-gray;
	}
}

@mixin btn-small()
{
	font-size: 0.85em;
	padding: 0 12px;
	height: $button-height * 0.85;
	line-height: $button-height * 0.8;
	min-width: 0;
}

@mixin btn-after()
{
	position: absolute;
	width: $button-height - 4px;
	height: $button-height - 4px;
	display: block;
	top: 0;
	text-align: center;
	color: inherit;
	line-height: $button-height - 4px;	
	right: 0;
	content: "";
	font-size: 18px;
}

@mixin btn-fa()
{
	&:before,
	&:after {
		@include font-awesome();
		@include btn-after();
	}
}

@mixin btn-btr()
{
	&:before,
	&:after {
		@include black-tie-regular();
		@include btn-after();
	}
}


@mixin btn-right() {
	@include btn-btr();
	padding-right: $button-height + 16px;

	&:after {
		right:0;
		content: "\F093";
	}
}

@mixin btn-left() {
	@include btn-btr();
	padding-left: $button-height + 16px;

	&:after {
		left:0;
		right:auto;
		content: "\F092";
	}
	&:before {
		left:0;
		right:auto;
	}
}

@mixin btn-down() {
	@include btn-btr();
	padding-right: $button-height + 16px;

	&:after {
		right:0;
		content: "\f091";
	}
}


@mixin btn-check {
	@include btn-btr();
	padding-right: $button-height + 16px;

	&:after {
		right:0;
		content: "\f012";
	}
}

@mixin btn-plus {
	@include btn-btr();
	padding-right: $button-height + 16px;

	&:after {
		right:0;
		content: "\f01b";
	}
}

@mixin btn-remove {
	@include btn-btr();
	padding-right: $button-height + 16px;

	&:after {
		right:0;
		content: "\f00c";
	}
}

@mixin btn-cart-plus {
	@include btn-btr();
	padding-right: $button-height + 16px;

	&:after {
		right:0;
		content: "\f01c";
	}
}

@mixin btn-loading() {
	@include btn-btr();
	padding-right: $button-height + 16px;
	
	&:after {
		right:0;
		content: "\f03d";
		-webkit-animation: bt-spin 1s infinite steps(8);
  	    animation: bt-spin 1s infinite steps(8);
	}
}

@mixin btn-error() {
	@include btn-btr();
	padding-right: $button-height + 16px;

	&:after {
		right:0;
		content: "\f063";
	}
}


@mixin btn-green() {
	color: $middle-green;
	border-color: $middle-green;
}

@mixin btn-blue() {
	color: $medium-blue;
	border-color: $medium-blue;

	&:hover {
		background-color: $medium-blue;
		color: white;
	}
}

@mixin btn-gray() {
	color: $gray-border;
	border-color: $gray-border;
}

@mixin btn-red() {
	color: $medium-red;
	border-color: $medium-red;
}


@mixin btn-green-fill() {
	color: white;
	background-color: $middle-green;
	border-color: $middle-green;
}

@mixin btn-blue-fill() {
	color: white;
	background-color: $medium-blue;
	border-color: $medium-blue;

	&:hover {
		background-color: transparent;
		color: $medium-blue;
	}

	&:disabled {
		color: white !important;
		background-color: lightgray !important;
		border-color: darkgray !important;
	}
}

@mixin btn-gray-fill() {
	color: white;
	background-color: $gray-border;
	border-color: $gray-border;
}

@mixin btn-red-fill() {
	color: white;
	background-color: $medium-red;
	border-color: $medium-red;
}
