
@font-face {
  font-family: "avenir";
  src: url("../fonts/avenir-roman-webfont.eot");
  src: url("../fonts/avenir-roman-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir-roman-webfont.woff2") format("woff2"), url("../fonts/avenir-roman-webfont.woff") format("woff"), url("../fonts/avenir-roman-webfont.ttf") format("truetype"), url("../fonts/avenir-roman-webfont.svg#avenir_55roman") format("svg");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "avenir";
  src: url("../fonts/avenir-light-webfont.eot");
  src: url("../fonts/avenir-light-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir-light-webfont.woff2") format("woff2"), url("../fonts/avenir-light-webfont.woff") format("woff"), url("../fonts/avenir-light-webfont.ttf") format("truetype"), url("../fonts/avenir-light-webfont.svg#avenir_35_lightregular") format("svg");
  font-weight: 300;
  font-style: normal;
}

@font-face {
  font-family: "avenir";
  src: url("../fonts/avenir-medium-webfont.eot");
  src: url("../fonts/avenir-medium-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/avenir-medium-webfont.woff2") format("woff2"), url("../fonts/avenir-medium-webfont.woff") format("woff"), url("../fonts/avenir-medium-webfont.ttf") format("truetype"), url("../fonts/avenir-medium-webfont.svg#avenirregular") format("svg");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "avenir";
  src: url("../fonts/avenirltstd-black-webfont.eot");
  src: url("../fonts/avenirltstd-black-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/avenirltstd-black-webfont.woff") format("woff"), url("../fonts/avenirltstd-black-webfont.ttf") format("truetype"), url("../fonts/avenirltstd-black-webfont.svg#avenirregular") format("svg");
  font-weight: 900;
  font-style: normal;
}

@font-face {
  font-family: "avenir";
  src: url("../fonts/avenirltstd-heavy-webfont.eot");
  src: url("../fonts/avenirltstd-heavy-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/avenirltstd-heavy-webfont.woff") format("woff"), url("../fonts/avenirltstd-heavy-webfont.ttf") format("truetype"), url("../fonts/avenirltstd-heavy-webfont.svg#avenirregular") format("svg");
  font-weight: 800;
  font-style: normal;
}
/*
@font-face {
  font-family: "avenir";
  src: url("../fonts/avenirltstd-book-webfont.eot");
  src: url("../fonts/avenirltstd-book-webfont.eot?#iefix") format("embedded-opentype"), url("../fonts/avenirltstd-book-webfont.woff") format("woff"), url("../fonts/avenirltstd-book-webfont.ttf") format("truetype"), url("../fonts/avenirltstd-book-webfont.svg#avenir_55roman") format("svg");
  font-weight: 400;
  font-style: normal;
}
*/


/* montserrat-300 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 300;
  src: local('Montserrat Light'), local('Montserrat-Light'),
       url('../fonts/montserrat-v12-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/montserrat-v12-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-regular - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 400;
  src: local('Montserrat Regular'), local('Montserrat-Regular'),
       url('../fonts/montserrat-v12-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/montserrat-v12-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-500 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  src: local('Montserrat Medium'), local('Montserrat-Medium'),
       url('../fonts/montserrat-v12-latin-500.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/montserrat-v12-latin-500.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* montserrat-700 - latin */
@font-face {
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  src: local('Montserrat Bold'), local('Montserrat-Bold'),
       url('../fonts/montserrat-v12-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/montserrat-v12-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-300 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 300;
  src: local('Open Sans Light'), local('OpenSans-Light'),
       url('../fonts/open-sans-v15-latin-300.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/open-sans-v15-latin-300.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-regular - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 400;
  src: local('Open Sans Regular'), local('OpenSans-Regular'),
       url('../fonts/open-sans-v15-latin-regular.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/open-sans-v15-latin-regular.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-600 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 600;
  src: local('Open Sans SemiBold'), local('OpenSans-SemiBold'),
       url('../fonts/open-sans-v15-latin-600.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/open-sans-v15-latin-600.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}

/* open-sans-700 - latin */
@font-face {
  font-family: 'Open Sans';
  font-style: normal;
  font-weight: 700;
  src: local('Open Sans Bold'), local('OpenSans-Bold'),
       url('../fonts/open-sans-v15-latin-700.woff2') format('woff2'), /* Chrome 26+, Opera 23+, Firefox 39+ */
       url('../fonts/open-sans-v15-latin-700.woff') format('woff'); /* Chrome 6+, Firefox 3.6+, IE 9+, Safari 5.1+ */
}
