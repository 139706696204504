#navoffcanvas,
#navoffcanvasbottom {
	font-size: 18px;
	line-height: 24px;

	a {
		color: inherit;

		&:hover {
			color: $kastner-red;
		}
	}

	ul {
		@include listreset();
	}

	li {
		& > a,
		& > strong,
		& > span {
			display: block;
			padding: 10px 18px;
		}

		& > strong {
			font-weight: inherit;
			color: black;
		}

		& > ul {
			padding-left: 0.7em;
			display: none;
		}
	}

	& > ul > li {
		border-top: 1px solid #efefef;
	}

	li.submenu {
		position: relative;
		cursor: pointer;

		& > a,
		& > strong,
		& > span {
			margin-right: 38px;
		}

		&:after {
			@include btr();
			content: "\f01b";
			font-size: 12px;
			position: absolute;
			right: 18px;
			top: 0;
			transition: transform 0.2s;
			height: 44px;
			line-height: 44px;
			width: 20px;
			display: block;
			text-align: center;
		}

		&.open {
			&:after {
				transform: rotate(45deg);
			}

			& > ul {
				display: block;
			}

			& > a,
			& > strong,
			& > span {
				font-weight: bold;
			}
		}
	}

	button {
		display: none;
	}

	li.customer-multilogin {

		& > span {
			display: block;
			padding-bottom: 0.425em;
			cursor: pointer;
			user-select: none;

			& > .bt-angle-down {
				transition: transform 0.2s;
				margin-left: 5px;
			}
		}

		& > ul {
			@include listreset();
			padding: 10px 18px;
			line-height:normal;
			font-size:0.85em;
			display: none;

			& > li {
				overflow:hidden;

				& > a {
					display:block;
					padding:0.4em 0;
				}

				&.current {

					& > a {
						cursor:default;
						pointer-events:none;
					}

					.name,
					.address,
					.num {
						color:$kastner-red;
					}
					
				}

				.cart-count-container {
					float:right;
					position:relative;
					padding-left:0.25em;

					&.count0 {
						display:none;
					}

					.cart-icon {
						font-size:2.0em;
						padding-top: 0.35em;
						padding-right: 0.3em;
					}

					.cart-count {
						top:2px;
					}
				}
			}
		}

		.name {
			color:$font-color;
			//font-size:1.1em;
			font-weight:700;
		}

		.name,
		.address,
		.num {
			white-space:nowrap;
			overflow:hidden;
			text-overflow:ellipsis;
		}

		&.hover,
		&:hover {

			& > .btr {
				background-color:rgba(0,0,0,0.5);
			}

			& > ul {
				opacity:1;
				visibility:visible;
			}
		}
	}
}

#navoffcanvasbottom {
	font-size: 16px;
	line-height: 18px;
	margin-bottom: 17px;
	margin-top: 17px;

	li {

		& > a,
		& > strong,
		& > span {
			display: block;
			padding: 6px 18px;
		}
	}

	& > ul > li {
		border-top: 0;
	}
}
