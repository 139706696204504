h1 {
	font-weight:300;
	font-size:2.4em;

	@include smartphone() {
		font-size: 2.2em;
		text-align: center;
	}
}

h2 {
	font-weight: normal;
	font-size: rem(20px);
	margin: 1em 0;
}
