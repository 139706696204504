.products-slider {

    .products[data-mode="grid"] {
        display: flex;
        flex-wrap: nowrap;
		gap: 0;

        [data-product] {
            flex-basis: auto;
            flex-grow: 0;
        }
    }
}

.product-view,
.predictive-basket,
.similar-products-overlay {

	.products-slider {
		padding:0 20px;
		background-color:white;
		box-shadow: $box-shadow;
		display: block;
		position: relative;

		.products {

			&:before,
			&:after {
				display: none;
			}
		}

		.products,
		.products[data-mode="grid"] {
			margin: 0 !important;

			[data-product] {
				box-shadow: none;
				margin-top: 0;
				margin-bottom: 0;
				box-sizing: border-box;
				min-width: 0;
				max-width: none;
				width: 240px;
			}
		}

		.swiper-button-prev {
			left: 0;
		}

		.swiper-button-next {
			right: 0;
		}
	}
}
