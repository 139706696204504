ff-campaign-feedbacktext,
.factfinder-feedbackCampaign {
	margin: 0 0 0.83em 0;

	img, video {
		max-width: 100%;
		height: auto;
		display: block;
		margin: auto;
	}

	h2 {
		margin-top: 0;
	}

	hr {
		display: none;
	}
}


ff-campaign-advisor-question {
	background-color: white;
	overflow: hidden;
	box-shadow: $box-shadow;
	margin-bottom:0.83em;
	position:relative;
	padding: 0 20px;

	.swiper-container {
		width: 100%;
		position: static;
	}

	.swiper-slide {
		width: auto;
	}

	.swiper-button-prev {
		left: 0;
	}

	.swiper-button-next {
		right: 0;
	}

	p[data-question] {
		font-size:1.3333em;
		text-align:center;
		margin:0.75em 0;
	}

	ff-campaign-advisor-answer {
		padding:0 4px;
		cursor: pointer;
		text-decoration:none;
		display:block;
		overflow:hidden;
		text-overflow:ellipsis;
		white-space:nowrap;
		border:1px solid transparent;
		font-size:0.75em;
		padding:1.1em;
		text-align:center;
		color:#464646;
		text-transform:uppercase;
		font-weight:normal;
		letter-spacing:0.04em;
		transition:0.2s;

		&:hover {
			border-color:#3e3e3e;
		}

		img {
			max-height: 150px;
			width: auto;
			display: block;
		}
	}
}
