.tingle-modal.privacy-confirm,
.tingle-modal.price-transparency-confirm {
    h1 {
        margin-top: 0;
    }

    .tingle-modal-box__footer {
        display: flex;
        justify-content: center;

        button.button {
            white-space: normal;
            height: auto;
            line-height: 1.15;
            padding-top: 5px;
            padding-bottom: 8px;
            max-width: none;
            text-align: left;
    
            &:before,
            &:after {
                bottom: 0;
                margin: auto;
            }
        }
    }
}
