html { 
	font-size:100%; 
}

body { 
	overflow-y: scroll; 
}

figure {
	display:block;
	max-width:100%;
	margin:0;
	-webkit-box-sizing:border-box;
	   -moz-box-sizing:border-box;
	        box-sizing:border-box;
}

img {
	border:0;
}

figure img {
	width:auto;
	height:auto;
	max-width:100%;
	display:block;
}

figure figcaption {
	max-width:100%;
	margin:0 auto;
	text-align:center;
}

.invisible {
	display:none !important;
}

.block:after {
	content:"";
	clear:both;
	display:block;
}

.video-wrapper {
	position: relative;
	padding-bottom:56.25%;
	padding-top: 25px;
	height: 0;
	margin:0 auto;
}

.video-wrapper iframe {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}

*:focus {
    outline: none;
}


/* responsive */
@media only print {

	body,
	#wrapper {
		overflow:visible !important;
	}
}
