.list-menu {
	position: relative;

	h1 {
		.list-name {
			display: none;

			.colon {
				display: none;
			}
		}

		@include smartphone() {
			position: relative;
			padding: 0 22px;

			a {
				display: block;
				width: 22px;
				height: 22px;
				background-image: url('../images/arrow-left.svg');
				background-size: auto 100%;
				background-position: left center;
				background-repeat: no-repeat;
				position: absolute;
				left: 0;
				top: -6px;
				bottom: -14px;
				margin: auto;
			}

			a + .name {
				display: none;
			}

			.list-name {
				display: block;
			}
		}
	}
}

.list-menu__tab-container {
	margin-left: -30px;
	margin-right: -30px;
	margin-top: -38px;
}

.list-menu__function-container {
	@include listreset();
	@include clearfix();
	position: absolute;
	right: 0;
	top: -2.75em;
	color: $medium-blue;

	& > li > a {
		color: $medium-blue;
	}

	& > li {
		float: left;

		& + li {

			&:before {
				content: '\00a0\00a0|\00a0\00a0';
			}
		}

		span {
			cursor: pointer;
		}

		.function--delete {
			color: $kastner-red;
		}
	}

	@include smartphone()
	{
		display: none;
	}
}

.list-menu__list-tabs {
	position: relative;

	.widget--wishlistmenu {
		display: none;

		@include smartphone()
		{
			display: block;
			margin-bottom: 24px;
		}
	}
}





.kastnerlists__listcontainer {
	@include clearfix();

	#delbutton {
		margin:0;
		padding:0;
	}

	ol.products > li.item {
		padding-left: $list-height * 0.6666;
		padding-right: 4.5%;
		-webkit-touch-callout: none; /* iOS Safari */
		-webkit-user-select: none;   /* Chrome/Safari/Opera */
		-khtml-user-select: none;    /* Konqueror */
		-moz-user-select: none;      /* Firefox */
		-ms-user-select: none;       /* Internet Explorer/Edge */
		user-select: none;           /* Non-prefixed version, currently
									  not supported by any browser */
		transition: margin-bottom 0.2s;

        @include print()
        {
        	padding-left: 0;
        	padding-right: 0;
		}

		& > .open-close {
			width:32px;
			height:32px;
			position:absolute;
			top: 0;
			bottom: 0;
			margin: auto;
			left:-31px;
			display:block;
			line-height:32px;
			text-align:center;
			transition:0.2s;
			font-size:1em;
			cursor:pointer;
			color:$light-text;

			@include print() {
				display: none;
			}
		}

		& > .item-comment-container {
			position: absolute;
			height: 0;
			overflow: hidden;
			position: absolute;
			bottom: 0;
			transition: 0.2s;
			left: 0;
			right: 0;
			box-sizing: border-box;

			input {
				margin: 1em;
				width: 385px;
				max-width: 100%;
			}
		}
		
		&.open {
			margin-bottom: 4em;

			& > .open-close {
				@include transform( rotate(45deg) );
			}

			& > .item-comment-container {
				height: 63px;
				bottom: -63px;
			}
		}

		& > .button-overlay {
			left: $list-height * 0.6666;
		}

		& > .move-handle {
			position: absolute;
			width: $list-height * 0.6666;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			cursor: move;
			background-image: url('../images/bars.png');
			background-size: 14px 14px;
			background-repeat: no-repeat;
			background-position: center;
			z-index: 21;
		}

		& > .product-price {
			width: 17%;
			flex-basis: 17%;
			
			.price-wrapper {
				text-align: right;
				margin-right: 80px;

				&.clickable {
					cursor: pointer;
				}
			}

			&.price-override .price-wrapper {
				color: $kastner-red;
			}

			.regular-price {
				display: block;
				text-align: right;
				margin-right: 80px;
				font-size: 14px;
				color: #a7a7a7;

				span.price {
					font-size: inherit;
					font-weight: inherit;
					line-height: inherit;
				}
			}

			input {
				margin-left: auto;
				max-width: 95px;
				text-align: right;
				height: 32px;
				box-sizing: border-box;
				border: 1px solid #e0e0e0;
				padding: 3px 5px;
			}

			& > .price-message {
				position: absolute;
				height: 32px;
				line-height: 32px;
				top: 0;
				bottom: 0;
				margin-top: auto;
				margin-bottom: auto;
				color: $kastner-red;
				right: 185px;
				text-align: right;
				font-weight: 500;
				white-space: nowrap;

				@include breakpoint(1440px) {
					bottom: auto;
					top: -1em;
					right: 80px;
					height: auto;
					line-height: 1;
				}
			}
		}

		& > .remove {
			@include product-list-delete();
			padding: 0;
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			width: 6%;
			flex-basis: 6%;
			overflow: hidden;
			min-width: 120px;
			justify-content: flex-end;
			display: flex;
			margin: auto;

			.button-delete {
				padding: 0;
				border: 0;
				outline: 0;
				position: absolute;
				top: 0;
				bottom: 0;
				margin:auto 0;
				display: block;
				right: 0;
			}
		}

		.discount-percentage {
			display: none;
		}

		@include breakpoint(640px) {
			grid-template-columns: 32px 1fr 1fr 48px;
			padding-left: 0;
			padding-right: 0;

			& > .product-price {
				width: auto;
			}

			& > .move-handle {
				position: relative;
				grid-column: 1 / 2;
				grid-row: 1 / 4;
				width: 32px;
				height: 100%;
			}

			& > .remove {
				width: 48px;
				height: 48px;
			}
		}
	}

	&.kastnerlists--offer {

		ol.products > li.item {

			& > .product-name {
				width: 27%;
				flex-basis: 27%;
			}

			& > .row-total {
				box-sizing: border-box;
				padding-right: 4px;
				width: 7%;
				flex-basis: 7%;
				text-align: right;

				@include smartphone() {
					display: none;
				}
			}

			& > .remove {
				width: 5%;
				flex-basis: 5%;
			}
		}

		.total {
			padding-top: 1em;
			padding-right: 5.75%;
			text-align: right;
		}

		.total__text {
			font-size: 1.6em;
			font-weight: 500;
		}

		.total__message {
			margin-top: 14px;
			font-size: 14px;
			padding-right: 24px;
		}
	}

	&.kastnerlists--weekly-offers {
		ol.products > li.item {
			.discount-percentage {
				display: block;
			}
		}
	}

	.add-all-container {
		margin:0;
		float:right;
	} 

	.new-wishlist {
		button {
			min-width:0;
			width:auto;
			padding-left:14px;
			padding-right:48px;

			&:before,
			&:after {
				width:36px;
				height:36px;
				line-height:36px;
				font-size:0.85em;
			}
		}
	}
}


.list-menu__list-container {
	@include clearfix();

	ul {
		@include listreset();
		@include clearfix();
		margin-left: -16px;

		@include smartphone() {
			margin-left: 0;
		}

		& > li {
			float: left;
			font-size: 20px;
			margin-left: 16px;
			margin-bottom: 16px;
			font-weight: 400;

			@include print() {
				display: none;
				margin-bottom: 0;

				&.current {
					display: block;
				}
			}

			@include smartphone() {
				float: none;
				font-size: 20px;
				margin-left: 0;
				margin-bottom: 0;

				& + li {
					margin-top: 10px;
				}

				&.new-list-button {
					margin-top: 38px;
				}
			}

			& > form > input,
			& > input,
			& > span,
			& > a {
				display: block;
				background-color: white;
				color: inherit;
				overflow: hidden;
				text-overflow: ellipsis;
				white-space: nowrap;
				cursor: pointer;
			}

			a {
				transition: color 0.2s, background-color 0.2s;
			}

			& > form > input,
			& > input,
			& > button,
			& > span,
			& > a {
				width: 165px;
				height: 36px;
				line-height: 36px;
				border:0;
				outline:0;
				box-sizing:border-box;
				text-align:center;
				padding:0 4px;

				.count {
					font-weight: 300;
				}

				&.new-list {
					background-color: $light-green;
					color: white;
					min-width: 0;
					padding-right: 36px;

					&:before,
					&:after {
						width: 36px;
						height: 36px;
						line-height: 36px;
					}
				}

				.list--ogoffers & {
					width: auto;
				}

				@include smartphone() {
					width: auto;
					height: auto;
					border-radius: 3px;
					box-shadow: 0 1px 1px 0 rgba(0,0,0,0.05);
					line-height: 24px;
					padding: 12px 10px;
					text-align: left;
					display: block;
					padding-right: 15px + 22px + 15px;
					position: relative;

					&:not(.new-list):after {
						width: 22px;
						height: 22px;
						background-image: url('../images/arrow-right.svg');
						background-size: auto 100%;
						background-position: right center;
						background-repeat: no-repeat;
						content: '';
						display: block;
						position: absolute;
						right: 15px;
						top: 0;
						bottom: 0;
						margin: auto;
					}

					&.new-list {
						&:before,
						&:after {
							margin: auto;
							bottom: 0;
						}
					}
				}
			}

			a:hover {
				color: white;
				background-color: $darker-blue;
			}

			&.current {

				& > form > input, 
				& > input,
				& > span, 
				& > a {
					color: white;
					background-color: $darker-blue;

					@include print()
					{
						color: inherit;
						background-color: transparent;
						padding: 0;
						font-weight: normal;
						text-align: left;
						height: auto;
						width: auto;
						display: block;
					}
				}
			}
		}
	}

	.rename-wishlist {
		float:right;
		display:block;
		margin-left:1em;
		color:$darker-blue;
		line-height:36px;
		cursor:pointer;
		height:52px;
	}
}


.kastnerlists .search-list,
.cart-table .search-list {
	margin: 1em 0;
	display: flex;
	margin-bottom: 1.5em;
	flex-wrap: wrap;

	.widget--explanation {
		display: flex;
		align-items: center;

		span {
			display: block;
		}
	}

	.widget--text,
	.widget--select {
		width: 230px;
	}

	.widget + .widget {
		margin-left: 0.5em;
	}

	@include smartphone()
	{
		.widget--select {
			width: 100%;
		}

		.widget--button {
			width: 100%;

			.lists__add-mode-button {
				width: 100%;
				max-width: none;
				text-align: left;
			}
		}

		.widget + .widget--search {
			width: 100%;
			margin-top: 0.5em;
		}

		.widget--search {
			position: relative;

			&:after {
				@include btr();
				content: "\f002";
				width: 26px;
				height: 26px;
				position: absolute;
				display: block;
				right: 12px;
				top: 0;
				bottom: 0;
				margin: auto;
				line-height: 26px;
				text-align: center;
				color: #a0a0a0;
			}

			input {
				padding-right: 26px + 12px + 12px;
			}
		}

		.widget + .widget--search {
			margin-left: 0;
		}

		.widget + .widget {
			margin-left: 0;
			margin-top: 0.5em;
		}

		.widgetunitswitch--add-mode {
			order: -1;
			margin-bottom: 26px;
		}
	}
}

#wishlist-popup-footer {
	border:0;
	width:auto;
	text-align:right;
	padding:0.5em;
	margin-top:1em;

	button {
		cursor:pointer;
		padding:0.5em;
		border:0;
		background:0;
		display:inline-block;
		margin:0;
		width:auto;
		color:inherit;
		font-weight: 500;

		span {
			color:inherit;
			padding:0;
			cursor:default;
			border:0;
			background:none;
			padding:0;
			font:inherit;
			display:inline;
			height:auto;
		}

		&.wishlist-button-cancel {
			color:$bright-red;
		}

		&.wishlist-button-select {
			color:#dcdcdc;
			cursor: default;

			&.available {
				color: $medium-blue;
				cursor: pointer;
			}
		}
	}
}

.list-menu__container {
	@include loader();
	position: relative;
	margin-bottom: 1.5em;
	transition: padding-bottom 0.4s;

	&:before,
	&:after {
		position: absolute;
		opacity: 0;
		left: 0;
		right: 0;
		bottom: 0;
		transition: opacity 0.4s;
		margin: auto;
	}

	&.loading {
		padding-bottom: 48px;

		&:before,
		&:after {
			opacity: 1;
			visibility: visible;
		}
	}
}


.list-menu.list--offer {
	.list-menu__container {
		display: flex;
		flex-wrap: wrap;
		justify-content: space-between;
	}
}

.kastnerlists .products[data-mode="list"] [data-product] .product-link {
	width: 50%;

	@include breakpoint(768px) {
		width: 40%;
	}

	@include breakpoint(640px) {
		width: 71%;
	}
}


.kastnerlists .cart-table-quickadd {
	margin-bottom: .65em;

	.product-image {
		height: 100%;
		position: relative;

		img {
			position: absolute;
			left: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			max-height: 100%;
		}
	}
}


/**
 * Responsive
 */
@media only screen and (max-width:960px)
{
	#mwishlists {

		.products-list > li.item {

			ul.properties {

				& > li {

					&.remove {
						width:40px;
					}
				}
			}
		}
	}
}
