$details-width:30%;
$details-width2:50%;

body.webshop-service-index {

	div.std {
		display:none;
	}

	h1 {
		text-align:center;
	}

	.our-service {
		margin:2em 0;
		text-align:center;

		p {
			color:$light-text;
			max-width:1024px;
			margin-left:auto;
			margin-right:auto;
		}
	}

	.location {
		margin-top:3em;
		margin-bottom:3em;
		margin-left:-$inside-padding * 2;
		margin-right:-$inside-padding * 2;

		.container {
			@include no-margin-collapse();
			@include clearfix();
			position:relative;
		}

		.details {
			float:left;
			background-color:white;
			width:$details-width;
			padding:0.5em 2em;
			text-align:center;
			box-sizing:border-box;
		}

		.image {
			position:absolute;
			left:$details-width;
			right:0;
			top:0;
			bottom:0;
			background-image:url('../images/locations/Zwettl.jpg');
			background-size:auto 100%;
			background-size:cover;
			background-repeat:no-repeat;
			background-position:left center;
		}

		&.amstetten .image {
			background-image:url('../images/locations/Amstetten.jpg');
		}

		&.eisenstadt .image {
			background-image:url('../images/locations/Eisenstadt.jpg');
		}

		&.jennersdorf .image {
			background-image:url('../images/locations/Jennersdorf.jpg');
		}

		&.krems .image {
			background-image:url('../images/locations/Krems.jpg');
		}

		&.wien .image {
			background-image:url('../images/locations/Standort_Wien_Nord.jpg');
		}

		&.wien_sud .image {
			background-image:url('../images/locations/Standort_Wien_Sued.jpg');
		}

		&.wolfsberg .image {
			background-image:url('../images/locations/Wolfsberg.jpg');
		}

		h1 {
			margin-left:$inside-padding * 2;
			margin-right:$inside-padding * 2;
		}

		h2, h3 {
			font-size:1.17em;
			font-weight: 500;
		}

		h2 {
			margin-bottom:0;
		}

		h2 + h3 {
			margin-top:0;
		}

		strong {
			font-weight: 500;
		}

		a {
			color:inherit;
		}
	}
}


/**
 * Responsive
 */
@media only screen and (max-width:800px)
{
	body.webshop-service-index {

		.location {

			.details {
				width:$details-width2;
			}

			.image {
				left:$details-width2;
			}
		}
	}
}

@media only screen and (max-width:480px)
{
	body.webshop-service-index {

		.location {

			.details {
				@include no-margin-collapse();
				float:none;
				width:auto;
			}

			.image {
				position:static;
				height:200px;
			}
		}
	}
}
