#offcanvas {
	position: fixed;
	z-index: 23;
	top: 0;
	left: -80vw;
	bottom: 0;
	width: 80vw;
	background-color: white;
	box-sizing:border-box;
	color: #747474;

	&:after {
		position: absolute;
		top: 0;
		bottom: 0;
		right: 0vw;
		width: 20vw;
		content: '';
		display: block;
		transition: right 0.2s;

		body.show-offcanvas & {
			right: -20vw;
		}
	}
}

.offcanvas__header {
	border-top: 5px solid $kastner-red;
	height: $header-height;
	font-size: 20px;
	text-align: center;
	line-height: $header-height;
	white-space: nowrap;
	font-weight: normal;
}

.offcanvas__content {
	overflow-y: auto;
	position: relative;
	height: calc(100% - #{$header-height});
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

#wrapper {
	position: relative;
	left: 0;

	&:after {
		position: absolute;
		left: 0; top: 0; bottom: 0; right: 0;
		background-color: rgba(0,0,0,0.3);
		content: '';
		display: block;
		opacity: 0;
		visibility: hidden;
		transition: 0.2s;
	}
}


@include tablet() {

	#offcanvas {
		transition: left 0.2s;
	}

	#wrapper {
		transition: left 0.2s;
	}

	#header .top-section {
		transition: left 0.2s, right 0.2s;
	}

	body.show-offcanvas {
		overflow: hidden;

		#offcanvas {
			left: 0;
		}

		#header .top-section {
			left: 80vw;
			right: -80vw;
		}

		#wrapper {
			left: 80vw;
			overflow: hidden;

			&:after {
				opacity: 1;
				visibility: visible;
			}
		}
	}
}
