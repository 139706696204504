.add-mode-toolbar {
    display: none;
    background-color: transparent;
    justify-content: space-between;
    padding: 0 ($inside-padding * 2);
    height: 31px;
    align-items: center;
    font-size: 18px;
    font-weight: 400;
    transition: 0.1s;
    position: fixed;
    top: $header-height + 5px;
    left: 0;
    right: 0;
    z-index: 12;

    @include smartphone() {
        height: 66px;
        padding: 0 14px;
    }

    .add-mode-toolbar__texts {
        display: flex;
        justify-content: flex-start;
        align-items: center;  
    }

    .list-text {
        display: none;

        span {
            font-weight: 700;
        }
    }

    .add-mode-toolbar__confirm {
        display: flex;
        justify-content: flex-end;
        align-items: center;
    }

    .add-mode-toolbar__confirm-button {
        border-radius: 5px;
        border: 1px solid white;
        color: inherit;
        padding: 2px 10px;
        box-sizing: border-box;
        line-height: 20px;
        outline: 0;
        background-color: transparent;
        transition: 0.1s;

        &:hover {
            background-color: white;
            color: $add-mode-blue;

            body.add-mode--offer & {
                color: $add-mode-green;
            }
        }
    }

    .text--products {
        &:before {
            content: '\00a0\00a0|\00a0\00a0';
        }

        @include smartphone() {
            display: none;
        }
    }

    .text--replace {
        display: none;
        font-weight: 300;

        span {
            font-weight: 400;
        }
    }

    body.add-mode--wishlist &,
    body.add-mode--offer &,
    body.add-mode--replace & {
        display: flex;
    }

    &.toolbar--init {
        body.add-mode--wishlist & {
            background-color: $add-mode-blue;
            color: white;

            .list-text.text--wishlist {
                display: block;

                &.text--mobile {
                    display: none;
                }

                @include smartphone() {
                    &.text--desktop {
                        display: none;
                    }

                    &.text--mobile {
                        display: block;
                    }
                }
            }
        }

        body.add-mode--offer & {
            background-color: $add-mode-green;
            color: white;

            .list-text.text--offer {
                display: block;

                &.text--mobile {
                    display: none;
                }

                @include smartphone() {
                    &.text--desktop {
                        display: none;
                    }

                    &.text--mobile {
                        display: block;
                    }
                }
            }
        }

        body.add-mode--replace & {
            background-color: var(--purple);
            color: white;

            .list-text {
                display: none;
            }

            .text--products {
                display: none;
            }

            .text--replace {
                display: block;
            }
        }
    }
}
