:root {
    --primary-color: #{$kastner-red};
    --prime-green: #1ca638;
    --light-text: #{$light-text};
    --signal-green: var(--prime-green);
    --signal-orange: #{$signal-orange};
    --signal-red: #{$signal-red};
    --purple: #aa4bb3;
}

button, input, optgroup, select, textarea {
	font-family: inherit;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    appearance: textfield;
}

input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]) {
    border-radius: 0;
}

input:not([type="radio"]):not([type="checkbox"]):not([type="submit"]):not([type="number"]) {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
}
