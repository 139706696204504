.receipt--element {
    display: flex;
    align-items: center;
    justify-content: space-between;
    transition: 0.2s;
    height: 68px;
    background-color: white;
    box-shadow: $box-shadow;
    position: relative;
    font-size: 25px;
    color: #585858;

    @include breakpoint(1024px) {
        font-size: 1rem;
    }
}

.receipt--element > * {
    white-space: nowrap;
    box-sizing: border-box;
}

.receipt__date {
    text-align: left;
    position: relative;
    padding-right: 2.2%;
    box-sizing: border-box;
    padding-left: 5%;
    min-width: 25%;

    &:after {
        width: 1px;
        height: 45px;
        position: absolute;
        top: -45px;
        bottom: -45px;
        right: 0;
        background-color: #a7a7a7;
        content: '';
        display: block;
        margin: auto;
    }
}

.receipt__type {
    min-width: 30%;
    padding-left: 2.2%;
    position: relative;
    text-overflow: ellipsis;
    box-sizing: border-box;
    overflow: hidden;

    @include breakpoint(480px) {
        display: none;
    }
}

.receipt__date,
.receipt__number {
    color: #b3b3b3;
}

.receipt__number {
    min-width: 15%;
    text-align: left;
    padding-right: 10px;

    @include breakpoint(768px) {
        display: none;
    }
}

.receipt__value {
    min-width: 15%;
    text-align: right;
    padding-right: 10px;
}

.receipt__value--positive {
    color: #ff0a0a;
}

.receipt__value--negative {
    color: #22b159;
}

.receipt__pdf {
    min-width: 15%;
    text-align: right;
    padding-right: 22px;
    font-size: 1rem;

    .button {
        width: auto;
        min-width: 0;
    }
}
