.preorder-products {
	padding-bottom: 2.5em;

	.preorder-submit-button {
		position:fixed;
		right: 200px;
		bottom: 12px;
		z-index: 2;

		@include breakpoint(480px) {
			left: 20px;
			right: auto;

			body.show-btt & {
				right: auto;
			}

			body.show-similar-products-button & {
				bottom: 42px;
			}
		}

		@include breakpoint(480px) {
			left: 20px;
			right: auto;

			body.show-btt & {
				right: auto;
			}
		}
	}

	.products[data-mode="list"] {

		[data-product] {

			.product-image-container {
				width:8%;
				flex-basis: 8%;
			}

			.product-manufacturer {
				width: 9%;
				flex-basis: 9%;
			}

			.product-name {
				width: 31%;
				flex-basis: 31%;
			}

			.product-price {
				width:16%;
				flex-basis: 16%;
			}

			.unitswitches {
				@include listreset();
				position: relative;
				box-sizing:border-box;
				padding-left: 3%;
				margin-top: 0.6em;
				margin-bottom: 0.6em;

				&:before {
					left: 0;
					width: 1px;
					position: absolute;
					margin: auto;
					top: 0;
					bottom: 0;
					background-color: $gray-border;
					content: '';
					display: block;
				}

				& > li {
					display: flex;
					align-items: center;
					justify-content: flex-end;

					& + li {
						margin-top: 12px;
					}
				}

				.date {
					white-space: nowrap;
				}
			}

			.unitswitch {
				margin-left: 40px;
				width: auto !important;
				flex-basis: auto !important;
				padding-bottom: 0 !important;

				&:before {
					display: none;
				}
			}

			@include breakpoint(1024px)
			{
				.product-name {
					width: 34%;
					flex-basis: 34%;
				}

				.product-price {
					width: 14%;
					flex-basis: 14%;
				}
			}

			@include breakpoint(768px)
			{
				.product-image-container {
					width: 10%;
					flex-basis: 10%;
				}

				.product-name {
					width: 30%;
					flex-basis: 30%;
				}

				.product-price {
					width: 12%;
					flex-basis: 12%;
				}
			}

			@include breakpoint(640px)
			{
				.product-image-container {
					width: auto;
					flex-basis: auto;
				}

				.product-manufacturer {
					width: auto;
				}

				.product-name {
					width: auto;
					flex-basis: auto;
				}

				.unitswitch {
					margin-left: 0;
				}

				.unitswitches {
					padding-left: 0;

					& > li {
						gap: 10px;
					}

					&:before {
						display:none;
					}
				}
			}
		}
	}
}
