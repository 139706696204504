.prime-indicator {
    align-items: center;
    justify-content: center;
    height: 18px;
    border-radius: 9px;
    overflow: hidden;
    color: white;
    padding: 0 6px;
    background-color: var(--prime-green);
    position: absolute;
    right: 0;
    bottom: 0;
    font-weight: 400;
    font-size: 15px;
    display: flex;

    &:before {
        mask-image: url('../images/check.svg');
        mask-repeat: no-repeat;
        mask-size: contain;
        background-color: white;
        width: 16px;
        height: 16px;
        margin-right: 3px;
        content: '';
        display: block;
    }
}

// Never show prime indicator for these products
[data-category-path^="|CategoryPathROOT=Obst|"] .prime-indicator,
[data-category-path^="|CategoryPathROOT=Gemüse|"] .prime-indicator,
[data-category-path^="|CategoryPathROOT=Frischfleisch|"] .prime-indicator {
    display: none !important;
}
