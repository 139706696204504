.price-surcharge {
	display:none;
}

.product-price {
	transition: opacity 0.2s;

	&:after {
		content: '';
		display: block;
		clear: both;
	}

	&[data-placeholder][data-invalid] {
		opacity: 0;
	}

	.label,
	.unit-label,
	.tier-label,
	.title {
		color:$light-text;
	}

	.unit-label {
		font-size: 0.80em;
	}

	.price-tiers ul {
		@include listreset();
	}

	.price-base {
		margin-top:0.83em;
	}
	.discount-price {
		color:$red;
	}
	.customer-price {
		color: $purple;
	}
	.price-info-label {
		color:$red;
	}
}

.product-price--customer {

	span.price {
		color: $purple;
	}
}

[data-show-price="detail"] {
	.price-surcharge {
		display:block;
	}
	.price-regular {
		display:none;
	}
}

div.discount-percentage > span {
	position:absolute;
	color:$red;
	padding:0 7px;
	line-height:1em;
}
