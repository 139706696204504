// https://gitlab.com/Kastner_Webshop/Webshop_2.0/issues/495
// https://github.com/FACT-Finder-Web-Components/ff-web-components/issues/26
ff-searchbutton button::before,
ff-searchbutton button * {
  pointer-events: none;
}

#header .search-box {
	max-width: 100%;
	margin: auto;
	height: 44px;
	position: relative;
	font-size: 15px;
	display: flex;

	@include tablet()
	{
		width: 100%;
	}

	label {
		display:none;
	}

	.input-box {
		display: block;
		position: relative;
		transition: opacity 0.2s, visibility 0.2s;
		margin-left: 13px;
		width: 338px;

		&:after {
			position: absolute;
			content: '';
			display: block;
			left: 0;
			right: 0;
			bottom: 5px;
			height: 0;
			border-bottom: 1px solid black;
			pointer-events: none;
		}

		@include breakpoint(1140px) {
			width: 300px;
		}

		@include tablet() {
			opacity: 0;
			width: auto;
			margin-left: 0;

			&:after {
				display: none;
			}

			body.show-search & {
				opacity: 1;
				visibility: visible;
			}
		}
	}

	input[type="search"] {
		padding: 0;
		margin: 0;
		box-sizing: border-box;
		height: 43px;
		border: 0;
		padding-right: 71px;
		width: 100%;
		padding-left: 5px;
	}

	.search-button,
	.search-button-mobile {
		@include btr();

		&:before {
			content: "\f002";
			pointer-events: none;
		}
	}

	.search-button, #speech_button, .search-button-mobile, .search-button-mobile-close {
		min-width: 0;
		background-color: transparent;
		border: 0;
		width: 25px;
		height: 44px;
		line-height: 44px;
		font-size: 14px;
		text-align: center;
		padding: 0;
		color: #505050;
		cursor: pointer;
		position: relative;
		margin: 0;

		span {
			display:none;
		}

		&:after {
			position: absolute;
			right: 0;
			top: 0;
			bottom: 0;
			margin: auto;
			height: 15px;
			border-right: 1px solid #505050;
			content: '';
			display: block;
		}

		@include tablet() {
			width: 44px;
			font-size: 25px;

			&:after {
				height: 20px;
			}
		}
	}

	.search-button {
		&:after {
			display: none;
		}

		@include tablet() {
			display: none;
			width: 44px;

			&:after {
				display: block;
			}
		}
	}

	.search-button-mobile {
		display: none;
		width: 44px;

		&:after {
			display: none;
		}

		@include tablet() {
			display: block;
		}
	}

	.search-button-mobile-close {
		@include sprite('../images/cancel.svg');
		background-size: 10px auto;
		cursor: pointer;
		z-index: 1;

		@include tablet() {
			display: none;
			background-size: 20px auto;
			order: 10;
			width: 44px;

			&:after {
				display: none;
			}

			body.show-search & {
				display: block;
			}
		}
	}

	@include tablet()
	{
		body.show-search & {

			.search-button {
				display: block;
			}

			.search-button-mobile {
				display: none;
			}
		}
	}

	#speech_button {
		@include sprite('../images/microphone.svg');
		background-size: 9px auto;
		display: none;

		&.recording {
			background-image: url('../images/microphone-red.svg');
		}

		&.show {
			display: block;
		}

		@include tablet() {
			display: none;
			background-size: auto 20px;
			width: 44px;

			&.show {
				display: none;
			}

			body.show-search &.show {
				display: block;
			}
		}
	}

	nav.master-filter {
		height: 44px;
		position: relative;
		box-sizing: border-box;
		transition: opacity 0.2s, visibility 0.2s;

		@include tablet()
		{
			opacity: 0;
			visibility: hidden;

			body.show-search & {
				opacity: 1;
				visibility: visible;
			}
		}

		.select-filter {
			height: 100%;
			line-height: 44px;
			display: block;
			cursor: pointer;
			text-align: left;
			position: relative;
			z-index: 2;
			padding-left: 8px;
			padding-right: 8px + 12px + 8px;
			white-space: nowrap;

			&:after {
				@include sprite('../images/dropdown.svg');
				width: 12px;
				height: 12px;
				position: absolute;
				right: 8px;
				top: 0;
				bottom: 0;
				margin: auto;
				content: '';
				display: block;
			}

			&:before {
				position: absolute;
				content: '';
				display: block;
				left: 0;
				right: 0;
				bottom: 5px;
				height: 0;
				border-bottom: 1px solid black;
				pointer-events: none;

				@include tablet() {
					display: none;
				}
			}

			&.clicked {
				& + ul {
					opacity:1;
					visibility:visible;
				}
			}
		}

		ul {
			@include listreset();
			z-index:51;
			position:absolute;
			top: 44px;
			left: -1px;
			background-color:white;
			opacity:0;
			visibility:hidden;
			transition:0.2s;
			border:1px solid $gray-border;
			padding:0.4em 0;

			li {
				cursor:pointer;
				color:$light-text;
				padding:0.25em 0.8em;
				white-space:nowrap;

				&.active {
					color:$bright-red;
				}

				&:hover {
					color:$bright-red;
				}
			}
		}
	}
}

.search-box__buttons {
	display: flex;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;

	@include tablet() {
		body.show-search & {
			right: -44px;
		}
	}
}
